import { TFunction } from 'i18next';
import CollectionBootleButton from '../components/CollectionBootleButton';
import { HiddenDayType } from '@consts/enums';
import enLocales from '@locales/en.json';
import zhLocales from '@locales/zh.json';
import dayjs from 'dayjs';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website.json';

// import zhLocales from '@locales/zh.json';

const newLocationList0111 = [
  {
    id: 8,
    value: enLocales.collectionBootle.locations8,
    t1: 'collectionBootle.locations8',
    t2: 'collectionBootle.locations8Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 9,
    value: enLocales.collectionBootle.locations9,
    t1: 'collectionBootle.locations9',
    t2: 'collectionBootle.locations9Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 3,
    value: enLocales.collectionBootle.locations3,
    t1: 'collectionBootle.locations3',
    t2: 'collectionBootle.locations3Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 12,
    value: enLocales.collectionBootle.locations12,
    t1: 'collectionBootle.locations12',
    t2: 'collectionBootle.locations12Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 13,
    value: enLocales.collectionBootle.locations13,
    t1: 'collectionBootle.locations13',
    t2: 'collectionBootle.locations13Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Saturday, HiddenDayType.Sunday],
  },
  {
    id: 14,
    value: enLocales.collectionBootle.locations14,
    t1: 'collectionBootle.locations14',
    t2: 'collectionBootle.locations14Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 10,
    value: enLocales.collectionBootle.locations10,
    t1: 'collectionBootle.locations10',
    t2: 'collectionBootle.locations10Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 11,
    value: enLocales.collectionBootle.locations11,
    t1: 'collectionBootle.locations11',
    t2: 'collectionBootle.locations11Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
];

const newLocationList0205 = [
  {
    id: 8,
    value: enLocales.collectionBootle.locations8,
    t1: 'collectionBootle.locations8',
    t2: 'collectionBootle.locations8Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 9,
    value: enLocales.collectionBootle.locations9,
    t1: 'collectionBootle.locations9',
    t2: 'collectionBootle.locations9Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 15,
    value: enLocales.collectionBootle.locations15,
    t1: 'collectionBootle.locations15',
    t2: 'collectionBootle.locations15Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 16,
    value: enLocales.collectionBootle.locations16,
    t1: 'collectionBootle.locations16',
    t2: 'collectionBootle.locations16Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 3,
    value: enLocales.collectionBootle.locations3,
    t1: 'collectionBootle.locations3',
    t2: 'collectionBootle.locations3Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 12,
    value: enLocales.collectionBootle.locations12,
    t1: 'collectionBootle.locations12',
    t2: 'collectionBootle.locations12Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 13,
    value: enLocales.collectionBootle.locations13,
    t1: 'collectionBootle.locations13',
    t2: 'collectionBootle.locations13Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Saturday, HiddenDayType.Sunday],
  },
  {
    id: 14,
    value: enLocales.collectionBootle.locations14,
    t1: 'collectionBootle.locations14',
    t2: 'collectionBootle.locations14Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 10,
    value: enLocales.collectionBootle.locations10,
    t1: 'collectionBootle.locations10',
    t2: 'collectionBootle.locations10Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 11,
    value: enLocales.collectionBootle.locations11,
    t1: 'collectionBootle.locations11',
    t2: 'collectionBootle.locations11Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 17,
    value: enLocales.collectionBootle.locations17,
    t1: 'collectionBootle.locations17',
    t2: 'collectionBootle.locations17Time',
    hiddenDays: [HiddenDayType.PublicHoliday],
  },
];

const newLocationList0227 = [
  {
    id: 8,
    value: enLocales.collectionBootle.locations8,
    t1: 'collectionBootle.locations8',
    t2: 'collectionBootle.locations8Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 15,
    value: enLocales.collectionBootle.locations15,
    t1: 'collectionBootle.locations15',
    t2: 'collectionBootle.locations15Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 16,
    value: enLocales.collectionBootle.locations16,
    t1: 'collectionBootle.locations16',
    t2: 'collectionBootle.locations16Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 3,
    value: enLocales.collectionBootle.locations3,
    t1: 'collectionBootle.locations3',
    t2: 'collectionBootle.locations3Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 12,
    value: enLocales.collectionBootle.locations12,
    t1: 'collectionBootle.locations12',
    t2: 'collectionBootle.locations12Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 13,
    value: enLocales.collectionBootle.locations13,
    t1: 'collectionBootle.locations13',
    t2: 'collectionBootle.locations13Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Saturday, HiddenDayType.Sunday],
  },
  {
    id: 14,
    value: enLocales.collectionBootle.locations14,
    t1: 'collectionBootle.locations14',
    t2: 'collectionBootle.locations14Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 10,
    value: enLocales.collectionBootle.locations10,
    t1: 'collectionBootle.locations10',
    t2: 'collectionBootle.locations10Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 17,
    value: enLocales.collectionBootle.locations17,
    t1: 'collectionBootle.locations17',
    t2: 'collectionBootle.locations17Time',
    hiddenDays: [HiddenDayType.PublicHoliday],
  },
  {
    id: 18,
    value: enLocales.collectionBootle.locations18,
    t1: 'collectionBootle.locations18',
    t2: 'collectionBootle.locations18Time',
    hiddenDays: [],
  },
];

const hpvStudyLocationList = [
  {
    id: 99999,
    value: enLocales.collectionBootle.locations99999,
    t1: 'collectionBootle.locations99999',
    t2: 'collectionBootle.locations99999Time',
    hiddenDays: [],
  },
];

export function renderDropOffList(options: {
  dropOffDate: string;
  dropLocation: string;
  isLg: boolean;
  setFormValue: (val: any) => void;
  formValue: any;
  t: TFunction<'translation', undefined, 'translation'>;
  setDropLocation: (val: string) => void;
  setDropLocationCn: (val: string) => void;
  forceThirdSubmitShowError: (dropLocation: string) => boolean;
  hiddenLocationTime: (dropOffDate: string, hiddenDayType: HiddenDayType[]) => boolean;
}) {
  const isHpvStudy = (handleLocalStorage.get(website.collection_bootle_id) || '').startsWith('0113');

  const {
    dropOffDate,
    dropLocation,
    t,
    setDropLocation,
    setDropLocationCn,
    setFormValue,
    isLg,
    forceThirdSubmitShowError,
    hiddenLocationTime,
    formValue,
  } = options;

  const getStatus = (val: string) => {
    return dropLocation === val ? 'check' : 'unchecked';
  };

  let locationList = newLocationList0111;

  if (dayjs(dropOffDate).isAfter('2024-02-04')) {
    locationList = newLocationList0205;
  }
  if (dayjs(dropOffDate).isAfter('2024-02-26')) {
    locationList = newLocationList0227;
  }

  if (isHpvStudy) {
    locationList = hpvStudyLocationList;
  }

  return (
    <>
      {locationList.map((item) => {
        return (
          <CollectionBootleButton
            key={item.id}
            isHiddenTime={true}
            isForceHiddenTime={hiddenLocationTime(dropOffDate, item.hiddenDays)}
            htmlType="submit"
            buttonClassName="w-full mb-[18px]  md:w-[26.66vw] md:text-[14px] md:mt-[0px] md:mb-[21px]"
            value={
              <span>
                <span className="font-bold">{t(item.t1)}</span>
                {t(item.t2)}
              </span>
            }
            status={forceThirdSubmitShowError(dropLocation) ? 'emptyError' : getStatus(item.value)}
            onClick={() => {
              const val = item.value;
              setDropLocation(val);
              const zhValPaths = item.t1.split('.');
              // @ts-ignore
              setDropLocationCn(zhLocales[zhValPaths[0]][zhValPaths[1]]);
              setFormValue({
                ...formValue,
                22: val,
              });
            }}
            style={{
              height: isLg ? '220px' : '7.3171rem',
              whiteSpace: 'pre-wrap',
              width: isLg ? '26.66vw' : '100%',
            }}
          ></CollectionBootleButton>
        );
      })}
    </>
  );
}
