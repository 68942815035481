import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import { CSSProperties } from 'react';

const borderColor = {
  default: '#D5D4DC',
  warning: '#EF8943',
  error: '#C83532',
  success: '#2BAC47',
};
// const bgColor = {
//   default: '#ffffff',
//   warning: '#FDF3EC',
//   error: '#FBEFEF',
//   success: '#F1F8F2',
// };
export type MessageTextStatus = 'error' | 'warning' | 'success' | undefined;
type MessageTextProps = {
  value?: string;
  onFocus?: () => void;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  status?: MessageTextStatus;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  inputClassName?: string;
  iconClassName?: string;
  defaultValue?: string;
  forceUppercase?: boolean;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
};

export default function MessageText(props: MessageTextProps = {}) {
  const renderHelpTextIcon = () => {
    if (!props.helpText || !props.status) {
      return null;
    }
    if (props.status === 'success') {
      return (
        <CheckCircleFilled
          className={props.iconClassName}
          style={{
            color: borderColor[props.status],
          }}
        />
      );
    } else {
      return (
        <ExclamationCircleFilled
          className={props.iconClassName ?? 'text-[16px] sm:text-[16px]'}
          style={{
            color: borderColor[props.status],
          }}
        />
      );
    }
  };

  return (
    <section
      style={{
        ...props.style,
      }}
    >
      <div
        className="flex items-center mb-[16px]  lg:justify-end"
        style={{
          ...props.innerStyle,
        }}
      >
        {renderHelpTextIcon()}
        <span
          className="text-[14px] ml-[4px] lg:text-[14px] lg:ml-[4px]"
          style={{
            color: borderColor[props.status || 'default'],
          }}
        >
          {props.helpText}
        </span>
      </div>
    </section>
  );
}
