import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../locales/en.json';
import zh from '../locales/zh.json';
import zh_CN from '../locales/zh_CN.json';

import { getLocale } from 'src/router/routerTools';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      zh: { translation: zh },
      en: { translation: en },
      // TODO 这里还存在问题，需要再看一下【目前还没成功引入zh_CN】
      zh_CN: { translation: zh_CN },
    },
    fallbackLng: getLocale().locale,
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
