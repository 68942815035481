/* eslint-disable @typescript-eslint/indent */
import { useMemoizedFn } from 'ahooks';
import { createContext, useContext } from 'react';
import { useNavigate, To, NavigateOptions } from 'react-router-dom';
import { isCNPortal2, isForceEnglishPage } from '@utils/index';
export const supportLocale = ['zh-HK', 'en', 'zh-CN'] as const;
export const defaultLanguage = isForceEnglishPage()
  ? supportLocale[1]
  : !isCNPortal2()
  ? supportLocale[0]
  : supportLocale[2];
export const zhHKLanguage = supportLocale[0];
export const enLanguage = supportLocale[1];
export const zhCNLanguage = supportLocale[2];
export const LanguageContext = createContext<(typeof supportLocale)[number]>(
  isForceEnglishPage() ? enLanguage : !isCNPortal2() ? zhHKLanguage : zhCNLanguage,
);

export function getLocale() {
  let locale: (typeof supportLocale)[number] = defaultLanguage;
  const hasPath = supportLocale.some((v) => {
    if (location.pathname.toLowerCase().startsWith('/' + v.toLowerCase())) {
      locale = v;
      return true;
    }
  });
  return { locale, hasPath };
}
export function useLocalNavigate() {
  const navigate = useNavigate();
  const language = useContext(LanguageContext);
  return useMemoizedFn((to: To, options?: NavigateOptions) => {
    if ((typeof to === 'string' && !to.startsWith('/')) || (typeof to !== 'string' && !to.pathname?.startsWith('/'))) {
      throw new Error('please use absolute path');
    }
    // 如果是enLanguage和zhCNLanguage，则需要加前缀
    if ([enLanguage, zhCNLanguage].includes(language as any)) {
      const preUrl = `/${language}`;
      if (typeof to === 'string' && !to.startsWith(preUrl)) {
        navigate(preUrl + to, options);
        return;
      } else if (typeof to !== 'string' && !to.pathname?.startsWith(preUrl)) {
        navigate(
          {
            ...to,
            pathname: preUrl + to.pathname,
          },
          options,
        );
        return;
      }
    }
    navigate(to, options);
  });
}
