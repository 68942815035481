import { TFunction } from 'i18next';
import { PapSelectType, HPVSelectType, PickDropSelectType } from '@consts/enums';

/**
 * 获取getAllValue
 * @param {TFunction<"translation", undefined, "translation">} t   translation函数
 * @returns AllValue
 */
// minus one
//
export const getAllValue = (t: TFunction<'translation', undefined, 'translation'>) => {
  return {
    labNewQuestionMinus9: {
      // 传给后端的id
      questionNo: '-9',
      questionDesc: {
        // 题目的序号
        serial: '-9',
        CN: '为什么要购买这个产品',
        EN: t('collectionBootle.question24'),
      },
      answerDesc: '',
    },
    labNewQuestionMinus8: {
      // 传给后端的id
      questionNo: '-8',
      questionDesc: {
        // 题目的序号
        serial: '-8',
        CN: '在哪里了解到Indicate检测',
        EN: t('collectionBootle.question23'),
      },
      answerDesc: '',
    },
    labNewQuestionMinus6: {
      // 传给后端的id
      questionNo: '-6',
      questionDesc: {
        // 题目的序号
        serial: '-6',
        CN: 'NGO',
        EN: 'NGO',
      },
      answerDesc: '',
    },
    labNewSubmissionScanTimeQuestion: {
      // 传给后端的id
      questionNo: 'NaN+1',
      questionDesc: {
        // 题目的序号
        serial: 'NaN+1',
        CN: '用户扫码时间',
        EN: t('collectionBootle.scanAddressTime'),
      },
      answerDesc: '',
    },
    labNewSubmissionAddressQuestion: {
      // 传给后端的id
      questionNo: 'NaN',
      questionDesc: {
        // 题目的序号
        serial: 'NaN',
        CN: '确认地址',
        EN: t('collectionBootle.submissionAddress'),
      },
      answerDesc: '',
    },
    labNewQuestionMinus4: {
      // 传给后端的id
      questionNo: '-4',
      questionDesc: {
        // 题目的序号
        serial: '-4',
        CN: '自寄服务的日期',
        EN: t('collectionBootle.questionMinus4'),
      },
      answerDesc: '',
    },
    labNewQuestionMinus3: {
      // 传给后端的id
      questionNo: '-3',
      questionDesc: {
        // 题目的序号
        serial: '-3',
        CN: '第四个勾选值',
        EN: t('collectionBootle.questionMinus3'),
      },
      answerDesc: '',
    },
    labNewQuestionMinus2: {
      // 传给后端的id
      questionNo: '-2',
      questionDesc: {
        // 题目的序号
        serial: '-2',
        CN: '第五个勾选值',
        EN: t('collectionBootle.questionMinus2'),
      },
      answerDesc: '',
    },
    labNewQuestionMinus1: {
      // 传给后端的id
      questionNo: '-1',
      questionDesc: {
        // 题目的序号
        serial: '-1',
        CN: '第六个勾选值',
        EN: t('collectionBootle.questionMinus1'),
      },
      answerDesc: '',
    },
    labNewQuestionZero: {
      // 传给后端的id
      questionNo: '0',
      questionDesc: {
        // 题目的序号
        serial: '0',
        CN: '样本id',
        EN: t('collectionBootle.question0'),
      },
      answerDesc: '',
    },
    labNewQuestionTwenty: {
      // 传给后端的id
      questionNo: '20',
      questionDesc: {
        // 题目的序号
        serial: '20',
        CN: '选择您喜欢的提货日期',
        EN: t('collectionBootle.question18'),
      },
      answerDesc: '',
    },
    labNewQuestionTwentyFive: {
      // 传给后端的id
      questionNo: '25',
      questionDesc: {
        // 题目的序号
        serial: '25',
        CN: '提交日期',
        EN: t('collectionBootle.FormSubmissionDate'),
      },
      answerDesc: '',
    },
    labNewQuestionThirteen: {
      // 传给后端的id
      questionNo: '13',
      questionDesc: {
        // 题目的序号
        serial: '13',
        CN: '如果你以前做过巴氏涂片检查，上次巴氏涂片检查的结果是什么？',
        EN: t('collectionBootle.question11'),
      },
      answerDesc: '',
    },
    questionOne: {
      // 传给后端的id
      questionNo: '1',
      questionDesc: {
        // 题目的序号
        serial: '1',
        CN: '英文姓',
        EN: t('collectionBootle.question1'),
      },
      answerDesc: '',
    },
    questionTwo: {
      questionNo: '2',
      questionDesc: {
        serial: '2',
        CN: '英文名',
        EN: t('collectionBootle.question2'),
      },
      answerDesc: '',
    },
    questionThree: {
      questionNo: '3',
      questionDesc: {
        serial: '3',
        CN: '聯絡電話',
        EN: t('collectionBootle.question3'),
      },
      answerDesc: '',
    },
    questionFour: {
      questionNo: '4',
      questionDesc: {
        serial: '4',
        CN: '電郵',
        EN: t('collectionBootle.question4'),
      },
      answerDesc: '',
    },
    questionFive: {
      questionNo: '5',
      questionDesc: {
        serial: '5',
        CN: '性別',
        EN: t('collectionBootle.question5'),
      },
      answerDesc: '',
    },
    questionSix: {
      questionNo: '6',
      questionDesc: {
        serial: '6',
        CN: '出生日期',
        EN: t('collectionBootle.question6'),
      },
      answerDesc: '',
    },
    questionSeven: {
      questionNo: '7',
      questionDesc: {
        serial: '7',
        CN: '證件號碼，可以是香港身份證/國家身份證/護照號碼',
        EN: t('collectionBootle.question7'),
      },
      answerDesc: '',
    },
    questionEight: {
      questionNo: '8',
      questionDesc: {
        serial: '8',
        CN: '證件號碼',
        EN: t('collectionBootle.question8'),
      },
      answerDesc: '',
    },
    questionNine: {
      questionNo: '9',
      questionDesc: {
        serial: '9',
        CN: '樣本採集日子',
        EN: t('collectionBootle.question21'),
      },
      answerDesc: '',
    },
    questionTen: {
      questionNo: '10',
      questionDesc: {
        serial: '10',
        CN: '樣本採集時間',
        EN: t('collectionBootle.question22'),
      },
      answerDesc: '',
    },
    questionEleven: {
      questionNo: '11',
      questionDesc: {
        serial: '11',
        CN: '病人以前做過巴氏塗片檢查嗎？',
        EN: t('collectionBootle.question9'),
      },
      answerDesc: '',
      select: {
        [PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType]: {
          questionTwelve: {
            questionNo: '12',
            questionDesc: {
              serial: '12',
              CN: '病人最後一次巴氏塗片檢查是什麽時候？',
              EN: t('collectionBootle.question10'),
            },
            answerDesc: '',
          },
          questionThirteen: {
            questionNo: '13',
            questionDesc: {
              serial: '13',
              CN: '如果是陽性，异常是什么？',
              EN: t('collectionBootle.question12'),
            },
            answerDesc: '',
          },
          questionFourteen: {
            questionNo: '14',
            questionDesc: {
              serial: '14',
              CN: '請列明結果  (如您選擇 陽性 / 不正常 – 其他)',
              EN: 'Please specify your results (If Chosen Positive / Abnormal – Others)',
            },
            answerDesc: '',
            // TODO 15的时候又有分叉，需要再看一下
          },
          questionFifteen: {
            questionNo: '15',
            questionDesc: {
              serial: '15',
              CN: '你做了HPV檢測嗎？如果是，結果是什麽？',
              EN: t('collectionBootle.question13'),
            },
            answerDesc: '',
            // TODO 一共21个问题=>需要传给BE=>BE的List表格
            select: {
              [HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType]: {
                questionSixteen: {
                  questionNo: '16',
                  questionDesc: {
                    serial: '16',
                    CN: '如有，結果是？',
                    EN: t('collectionBootle.question14'),
                  },
                  answerDesc: '',
                },
                questionSeventeen: {
                  questionNo: '17',
                  questionDesc: {
                    serial: '17',
                    CN: '請列明結果病毒是哪一型 (如您選擇 高/低危型人類乳頭瘤病毒陽性)',
                    EN: t('collectionBootle.questionSpecial'),
                  },
                  answerDesc: '',
                },
                // TODO 问题18得修改
                questionEighteen: {
                  questionNo: '18',
                  questionDesc: {
                    serial: '18',
                    CN: '請填寫全名以簽署',
                    EN: t('collectionBootle.question15'),
                  },
                  answerDesc: '',
                },
                questionNineteen: {
                  questionNo: '19',
                  questionDesc: {
                    serial: '19',
                    CN: '請選擇安排收取 / 投寄樣本樽',
                    EN: t('collectionBootle.question16'),
                  },
                  answerDesc: '',
                  select: {
                    [PickDropSelectType[PickDropSelectType.PickUp] as keyof typeof PickDropSelectType]: {
                      questionTwenty: {
                        questionNo: '20',
                        questionDesc: {
                          serial: '20',
                          CN: '請指定您想安排上門收取的地址(住宅/工/商廈) ',
                          EN: t('collectionBootle.question20'),
                        },
                        answerDesc: '',
                      },
                      questionTwentyOne: {
                        questionNo: '21',
                        questionDesc: {
                          serial: '21',
                          CN: '請指定您想安排上門收取的時間',
                          EN: t('collectionBootle.question19'),
                        },
                        answerDesc: '',
                      },
                      questionTwentyTwo: {
                        questionNo: '22',
                        questionDesc: {
                          serial: '22',
                          CN: '投寄樣本樽的地點',
                          EN: t('collectionBootle.question17'),
                        },
                        answerDesc: '',
                      },
                      // TODO 下面需要再添加serial
                      [PickDropSelectType[PickDropSelectType.DropOff] as keyof typeof PickDropSelectType]: {
                        questionTwenty: {
                          questionNo: '22',
                          questionDesc: {
                            serial: '20',
                            CN: '投寄樣本樽的地點',
                            EN: 'Choose your drop-off locations',
                          },
                          answerDesc: '',
                        },
                      },
                    },
                  },
                },
                // TODO 下面需要再添加serial
                // TODO
                [HPVSelectType[HPVSelectType.No] as keyof typeof HPVSelectType]: {
                  questionSixteen: {
                    questionNo: '18',
                    questionDesc: {
                      serial: '16',
                      CN: '請填寫全名以簽署',
                      EN: 'Please enter your full name as e-signature',
                    },
                    answerDesc: '',
                  },
                  questionSeventeen: {
                    questionNo: '19',
                    questionDesc: {
                      serial: '17',
                      CN: '請選擇安排收取 / 投寄樣本樽',
                      EN: 'Sample Bottle Pick Up / Drop Off',
                    },
                    answerDesc: '',
                    select: {
                      [PickDropSelectType[PickDropSelectType.PickUp] as keyof typeof PickDropSelectType]: {
                        questionEighteen: {
                          questionNo: '20',
                          questionDesc: {
                            serial: '18',
                            CN: '請指定您想安排上門收取的地址(住宅/工/商廈) ',
                            EN: 'Provide your delivery address',
                          },
                          answerDesc: '',
                        },
                        questionNineteen: {
                          questionNo: '21',
                          questionDesc: {
                            serial: '19',
                            CN: '請指定您想安排上門收取的時間',
                            EN: 'Choose your preferred pick-up time',
                          },
                          answerDesc: '',
                        },
                        questionTwenty: {
                          questionNo: '22',
                          questionDesc: {
                            serial: '20',
                            CN: '投寄樣本樽的地點',
                            EN: 'Choose your drop-off locations',
                          },
                          answerDesc: '',
                        },
                        // TODO 下面需要再添加serial
                        [PickDropSelectType[PickDropSelectType.DropOff] as keyof typeof PickDropSelectType]: {
                          questionEighteen: {
                            questionNo: '22',
                            questionDesc: {
                              serial: '18',
                              CN: '投寄樣本樽的地點',
                              EN: 'Choose your drop-off locations',
                            },
                            answerDesc: '',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          [PapSelectType[PapSelectType.No] as keyof typeof PapSelectType]: {
            questionTwelve: {
              questionNo: '15',
              questionDesc: {
                serial: '12',
                CN: '你做了HPV檢測嗎？如果是，結果是什麽？',
                EN: 'Have you been tested for HPV? ',
              },
              answerDesc: '',
              select: {
                [HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType]: {
                  questionThirteen: {
                    questionNo: '16',
                    questionDesc: {
                      serial: '13',
                      CN: '如有，結果是？',
                      EN: 'What was the result of your last HPV test?',
                    },
                    answerDesc: '',
                  },
                  questionFourteen: {
                    questionNo: '17',
                    questionDesc: {
                      serial: '14',
                      CN: '請列明結果病毒是哪一型 (如您選擇 高/低危型人類乳頭瘤病毒陽性)',
                      EN: 'Please specify which type if of the HPV (If Chosen Positive for High/Low Risk HPV)',
                    },
                    answerDesc: '',
                  },
                  // TODO 这里也要修改
                  questionFifteen: {
                    questionNo: '18',
                    questionDesc: {
                      serial: '15',
                      CN: '請填寫全名以簽署',
                      EN: 'Please enter your full name as e-signature',
                    },
                    answerDesc: '',
                  },
                  questionSixteen: {
                    questionNo: '19',
                    questionDesc: {
                      serial: '16',
                      CN: '請選擇安排收取 / 投寄樣本樽',
                      EN: 'Sample Bottle Pick Up / Drop Off',
                    },
                    answerDesc: '',
                    select: {
                      [PickDropSelectType[PickDropSelectType.PickUp] as keyof typeof PickDropSelectType]: {
                        questionSeventeen: {
                          questionNo: '20',
                          questionDesc: {
                            serial: '17',
                            CN: '請指定您想安排上門收取的地址(住宅/工/商廈) ',
                            EN: 'Provide your delivery address',
                          },
                          answerDesc: '',
                        },
                        questionEighteen: {
                          questionNo: '21',
                          questionDesc: {
                            serial: '18',
                            CN: '請指定您想安排上門收取的時間',
                            EN: 'Choose your preferred pick-up time',
                          },
                          answerDesc: '',
                        },
                        questionNineteen: {
                          questionNo: '22',
                          questionDesc: {
                            serial: '19',
                            CN: '投寄樣本樽的地點',
                            EN: 'Choose your drop-off locations',
                          },
                          answerDesc: '',
                        },
                        // TODO 下面需要再添加serial
                        [PickDropSelectType[PickDropSelectType.DropOff] as keyof typeof PickDropSelectType]: {
                          questionSixteen: {
                            questionNo: '22',
                            questionDesc: {
                              serial: '17',
                              CN: '投寄樣本樽的地點',
                              EN: 'Choose your drop-off locations',
                            },
                            answerDesc: '',
                          },
                        },
                      },
                    },
                  },
                  // TODO 下面需要再添加serial
                  // TODO
                  [HPVSelectType[HPVSelectType.No] as keyof typeof HPVSelectType]: {
                    questionThirteen: {
                      questionNo: '18',
                      questionDesc: {
                        serial: '13',
                        CN: '請填寫全名以簽署',
                        EN: 'Please enter your full name as e-signature',
                      },
                      answerDesc: '',
                    },
                    questionFourteen: {
                      questionNo: '19',
                      questionDesc: {
                        serial: '14',
                        CN: '請選擇安排收取 / 投寄樣本樽',
                        EN: 'Sample Bottle Pick Up / Drop Off',
                      },
                      answerDesc: '',
                      select: {
                        [PickDropSelectType[PickDropSelectType.PickUp] as keyof typeof PickDropSelectType]: {
                          questionFifteen: {
                            questionNo: '20',
                            questionDesc: {
                              serial: '15',
                              CN: '請指定您想安排上門收取的地址(住宅/工/商廈) ',
                              EN: 'Provide your delivery address',
                            },
                            answerDesc: '',
                          },
                          questionSixteen: {
                            questionNo: '21',
                            questionDesc: {
                              serial: '16',
                              CN: '請指定您想安排上門收取的時間',
                              EN: 'Choose your preferred pick-up time',
                            },
                            answerDesc: '',
                          },
                          questionSeventeen: {
                            questionNo: '22',
                            questionDesc: {
                              serial: '17',
                              CN: '投寄樣本樽的地點',
                              EN: 'Choose your drop-off locations',
                            },
                            answerDesc: '',
                          },
                          // TODO 下面需要再添加serial
                          [PickDropSelectType[PickDropSelectType.DropOff] as keyof typeof PickDropSelectType]: {
                            questionFifteen: {
                              questionNo: '22',
                              questionDesc: {
                                serial: '15',
                                CN: '投寄樣本樽的地點',
                                EN: 'Choose your drop-off locations',
                              },
                              answerDesc: '',
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
};

/**
 * getCNAllValue
 * @returns CNAllValue
 */
export const getCNAllValue = () => {
  return {
    1: '样本编号',
    2: '采样日期',
    3: '姓名',
    4: '联络号码',
    5: '证件号码 （身份证或其他证件）',
  };
};
