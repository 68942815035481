export enum BookingStatus {
  Created,
  Answered,
  InfoFilled, //对应现场问诊的开始
  WaitingForPayment,
  PaidSucc,
  PaidFail,
  Cancelled, //支付取消
  Consultation, //诊断
  Prescription, //开药方
  Completed, // 完成/对应现场问诊的结束
  Refund,
}
// 拿到key值的方法
// Direction[Direction.Up]

export enum ConsultationMethod {
  Online,
  Offline,
}
export enum GenderStatus {
  Male,
  Female,
}

export enum SimpleBookingStatus {
  Upcoming,
  Ongoing,
  Completed,
}

export enum WorkingConditionStatus {
  GP,
}

export enum Currency {
  hkd,
  usd,
}

export enum ModelType {
  Positive,
  Negative,
  Invalid,
}

export enum SelectDetailType {
  ScreeningResult,
  Prescriptions,
  Remarks,
}

export enum RemarkType {
  Edit,
  Lock,
}

export enum PaymentType {
  Appointment,
  Medication,
}
// 不需要存储中文的值了
export enum PapSelectType {
  Yes,
  No,
  是,
  否,
}

export enum SmearSelectType {
  Negative,
  Positive,
  陰性,
  陽性,
}

export enum HPVSelectType {
  Yes,
  No,
  是,
  否,
}

export enum PickDropSelectType {
  PickUp,
  DropOff,
  上門取件,
  自寄服務,
}

export enum CurPage {
  PersonalInfo,
  MedicalHistory,
  Delivery,
  UpgradeTest,
}

export enum PhoneRegion {
  HK,
  CN,
}

// 对应的密码
// 1: 'y4zHe2UTd7',
// 2: 'UDzpb1XMKu'
export enum ExcelType {
  LAB = 1,
  LOGISTICS = 2,
}

// uat环境对应的密码
// 1: 'BIMfqdbpQm',
// 2: 'FGwHp1fw9K'

// production对应的密码
// 1: 'hR6sbG8FJf',
// 2: 'jSIPCsBMRZ'

export enum BeSmsType {
  DropOff = 1,
  PickUp,
}

export enum DisagreeType {
  False = 0,
  True = 1,
}

export enum GenderType {
  Male = 0,
  Female = 1,
}

export enum HiddenDayType {
  PublicHoliday = 0,
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
}
