import { Steps } from 'antd';
import classNames from 'classnames';
import { useSteps } from './stepsFactory';
import { usePageContext } from '../BaseComponent/formContext';

export default function CustomSteps(props: { curPage: number }) {
  const { pageConfig } = usePageContext();

  const steps = useSteps(
    pageConfig.map((item) => ({
      cn: item.nameCn,
      en: item.name,
    })),
  );

  return (
    <Steps
      className={classNames('lg:mt-[0px]', 'mt-[18px] w-[100%]')}
      direction="horizontal"
      responsive={false}
      progressDot
      current={props.curPage}
      items={steps}
    />
  );
}
