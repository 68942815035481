import BaseButton from '@components/BaseButton/BaseButton';
import TextInput from '@components/TextInput/TextInput';
import { post } from '@utils/request';
import { message } from 'antd';
import { useState } from 'react';

export default function ScanSuccessInput() {
  const [collectionBottleId, setCollectionBottleId] = useState('');
  const [scanAddress, setScanAddress] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = () => {
    if (!collectionBottleId || !scanAddress || !timestamp) {
      message.error('please input all the fields');
      return;
    }
    setIsLoading(true);
    post('/hpv/attachNewCilinic', {
      sample_id: collectionBottleId,
      new_clinic: scanAddress,
      timestamp: timestamp,
    })
      .then(() => {
        setIsLoading(false);
        message.success('success to submit!');
        // 跳转至成功页面
      })
      .catch((error: any) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        // 专门的错误码，代表着用户之前已经扫描过，不抛出异常
        if (['15027', 15027].includes(error.response.data.code)) {
          return;
        }
        message.error(error.response.data.message);
      });
  };

  return (
    <div className="m-auto flex flex-col h-[400px] justify-around w-[500px] ">
      <div className="flex items-start mb-[16px] mt-[16px] flex-col">
        <p className="font-[600] text-[14px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[16px] flex lg:leading-[20px] lg:tracking-[0.4px] ">
          collectionBottleId
        </p>
        <TextInput
          value={collectionBottleId}
          onChange={(e) => {
            setCollectionBottleId(e.target.value);
          }}
          placeholder="collectionBottleId"
        />
      </div>
      <div className="flex items-start mb-[16px]  flex-col">
        <p className="font-[600] text-[14px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[16px] flex lg:leading-[20px] lg:tracking-[0.4px] ">
          scanAddress
        </p>
        <TextInput
          value={scanAddress}
          onChange={(e) => {
            setScanAddress(e.target.value);
          }}
          placeholder="scanAddress"
        />
      </div>
      <div className="flex items-start mb-[16px]  flex-col">
        <p className="font-[600] text-[14px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[16px] flex lg:leading-[20px] lg:tracking-[0.4px] ">
          timestamp
        </p>
        <TextInput
          value={timestamp}
          onChange={(e) => {
            setTimestamp(e.target.value);
          }}
          placeholder="timestamp"
        />
      </div>
      <BaseButton onClick={resetPassword} value="submit" loading={isLoading} />
    </div>
  );
}
