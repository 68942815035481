import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { FiledTitle } from '../components/FiledInput';
import { MEDIA_SIZE } from '@consts/consts';
import CollectionBootleButton from '../components/CollectionBootleButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MessageText from '../components/MessageText';

export default function RenderVaccinastionSelect(props: {
  isSecondSubmitCheck: boolean;
  formValue: any;
  setFormValue: React.Dispatch<any>;
}) {
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [vaccinastionSelect, setVaccinastionSelect] = useState<string>('');
  const [t] = useTranslation();
  const { setFormValue } = props;
  const [valent, setValent] = useState<string>();

  const showError1 = !props.formValue[28] && props.isSecondSubmitCheck;
  const showError2 = vaccinastionSelect === '是' && !props.formValue[29] && props.isSecondSubmitCheck;
  return (
    <>
      <div className="mb-[18px] md:mb-[18px]" />
      <FiledTitle title={t('collectionBootle.question28')} style={{ marginBottom: isLg ? '19px' : undefined }} />
      <CollectionBootleButton
        htmlType="submit"
        buttonClassName="w-full h-[38px] mb-[18px] md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
        value={t('collectionBootle.yes')}
        status={vaccinastionSelect === '是' ? 'check' : 'unchecked'}
        onClick={() => {
          setVaccinastionSelect('是');
          setFormValue((v: any) => ({
            ...v,
            28: 'Yes',
          }));
        }}
      ></CollectionBootleButton>
      <CollectionBootleButton
        htmlType="submit"
        buttonClassName="w-full h-[38px] mb-[18px] md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
        value={t('collectionBootle.no')}
        status={vaccinastionSelect === '否' ? 'check' : 'unchecked'}
        onClick={() => {
          setVaccinastionSelect('否');
          setFormValue((v: any) => ({
            ...v,
            28: 'No',
          }));
        }}
      ></CollectionBootleButton>
      {showError1 && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={'error'}
          style={{
            marginTop: isLg ? '0px' : '0.0976rem',
            position: 'relative',
            top: isLg ? '-10px' : '0px',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
      {vaccinastionSelect === '是' && (
        <>
          <div className="mb-[18px] md:mb-[18px]" />
          <FiledTitle title={t('collectionBootle.question29')} style={{ marginBottom: isLg ? '19px' : undefined }} />
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[38px] mb-[18px] md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
            value={t('collectionBootle.ques29Option3')}
            status={valent === '9' ? 'check' : 'unchecked'}
            onClick={() => {
              setFormValue((v: any) => ({
                ...v,
                29: '9-valent',
              }));
              setValent('9');
            }}
          ></CollectionBootleButton>{' '}
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[38px] mb-[18px] md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
            value={t('collectionBootle.ques29Option2')}
            status={valent === '4' ? 'check' : 'unchecked'}
            onClick={() => {
              setFormValue((v: any) => ({
                ...v,
                29: '4-valent',
              }));
              setValent('4');
            }}
          ></CollectionBootleButton>
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[38px] mb-[18px] md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
            value={t('collectionBootle.ques29Option1')}
            status={valent === '2' ? 'check' : 'unchecked'}
            onClick={() => {
              setFormValue((v: any) => ({
                ...v,
                29: '2-valent',
              }));
              setValent('2');
            }}
          ></CollectionBootleButton>
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[38px] mb-[18px] md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
            value={t('collectionBootle.ques29Option4')}
            status={valent === '5' ? 'check' : 'unchecked'}
            onClick={() => {
              setFormValue((v: any) => ({
                ...v,
                29: 'Not Sure',
              }));
              setValent('5');
            }}
          ></CollectionBootleButton>
          {showError2 && (
            <MessageText
              helpText={t('collectionBootle.fieldsRequiredMessage') as string}
              status={'error'}
              style={{
                marginTop: isLg ? '0px' : '0.0976rem',
                position: 'relative',
                top: isLg ? '-10px' : '0px',
              }}
              innerStyle={{
                justifyContent: 'flex-start',
                marginBottom: '0px',
              }}
            ></MessageText>
          )}
        </>
      )}
    </>
  );
}
