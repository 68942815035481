// import { CloseOutlined } from '@ant-design/icons';
// import { renderComponent, RenderComponentOptions } from './globalComponents';
// import { renderComponent, RenderComponentOptions } from '../../../utils/globalComponents';
// import { renderComponent, RenderComponentOptions } from './globalComponents';
import { renderComponent, RenderComponentOptions } from '../../../utils/globalComponents/globalComponents';

type DeliveryAlertInfoProps = {
  content: string | React.ReactNode;
};
export function DeliveryAlertInfo(props: DeliveryAlertInfoProps & { closeFunction: () => void }) {
  return (
    <>
      <div
        onClick={props.closeFunction}
        className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[10000] flex justify-center items-center"
      >
        {/* lg:h-[433px] */}
        <div className="w-[88.4%] rounded-[2px] bg-[#E6F7FF] p-[16px_26px_18px_26px] lg:w-[auto]  lg:rounded-[2px] lg:p-[16px_26px_18px_26px] max-h-[80vh] overflow-scroll">
          {/* <div className="lg:px-[30px] px-[18px] text-[#e0ecff] text-[22px] font-bold text-right">
            <CloseOutlined className="cursor-pointer" onClick={props.closeFunction} />
          </div> */}
          {props.content}
        </div>
      </div>
      <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[100] bg-[rgba(0,0,0,0.5)]"></div>
    </>
  );
}

export default function alertInfo(config: RenderComponentOptions<DeliveryAlertInfoProps>) {
  return renderComponent(config, DeliveryAlertInfo);
}
