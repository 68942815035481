import BaseCheckbox from '@components/BaseCheckbox/BaseCheckbox';
import FiledInput, { FiledTitle } from './FiledInput';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import MessageText from './MessageText';
import i18n from '@utils/i18n';

export default function HpvStudyConfirm(props: { handleCheck?: (f: () => boolean) => void; lastName: string }) {
  const mediaSize = useMediaQuery();
  const [t] = useTranslation();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [isCheck, setIsCheck] = useState(false);
  const [isCheck2, setIsCheck2] = useState(false);
  const [isCheck3, setIsCheck3] = useState(false);
  const [signature, setSignature] = useState('');
  const [isCheckError, setIsCheckError] = useState(false);
  const [isCheck2Error, setIsCheck2Error] = useState(false);
  const [isCheck3Error, setIsCheck3Error] = useState(false);
  const [signatureError, setSignatureError] = useState(false);
  const [isValidSignatureError, setIsValidSignatureError] = useState(false);

  const checkAll = useMemoizedFn(() => {
    if (!isCheck) {
      setIsCheckError(true);
    } else {
      setIsCheckError(false);
    }
    if (!isCheck2) {
      setIsCheck2Error(true);
    } else {
      setIsCheck2Error(false);
    }
    if (!isCheck3) {
      setIsCheck3Error(true);
    } else {
      setIsCheck3Error(false);
    }
    if (signature === '') {
      setSignatureError(true);
    } else {
      setSignatureError(false);
    }

    let signaturePass = true;
    if (props.lastName.toUpperCase() !== signature.toUpperCase()) {
      setSignatureError(true);
      signaturePass = false;
      setIsValidSignatureError(true);
    } else {
      setIsValidSignatureError(false);
      setSignatureError(false);
    }

    return isCheck && isCheck2 && isCheck3 && signature !== '' && signaturePass;
  });
  //
  useEffect(() => {
    props.handleCheck?.(checkAll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAll, props.handleCheck]);
  const isEn = i18n.language === 'en';
  return (
    <>
      <>
        <div
          className="flex cursor-pointer"
          style={{
            marginTop: isLg ? '29px' : '0.7073rem',
            marginBottom: isLg ? '0px' : '0rem',
          }}
          onClick={() => {
            setIsCheck(!isCheck);
          }}
        >
          <BaseCheckbox
            checked={isCheck}
            onChange={(e) => {
              setIsCheck(e.target.checked);
            }}
            style={{
              marginRight: isLg ? '16px' : '0.3902rem',
            }}
          />
          <FiledTitle
            title={t('collectionBootle.FullNameInfo1') as string}
            style={{
              marginTop: isLg ? '0px' : '0px',
              marginBottom: isLg ? '0px' : '0px',
            }}
          />
        </div>
        {isCheckError && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={'error'}
            style={{
              position: 'relative',
              top: isLg ? '10px' : '0.1951rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
        <br />
        <div
          className="flex cursor-pointer"
          style={{
            // marginTop: isLg ? '29px' : '0.7073rem',
            marginBottom: isLg ? '0px' : '0rem',
          }}
          onClick={() => {
            setIsCheck2(!isCheck2);
          }}
        >
          <BaseCheckbox
            checked={isCheck2}
            onChange={(e) => {
              setIsCheck2(e.target.checked);
            }}
            style={{
              marginRight: isLg ? '16px' : '0.3902rem',
            }}
          />
          <FiledTitle
            title={t('collectionBootle.FullNameInfoMiddle') as string}
            style={{
              marginTop: isLg ? '0px' : '0px',
              marginBottom: isLg ? '0px' : '0px',
            }}
          />
        </div>
        {isCheck2Error && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={'error'}
            style={{
              position: 'relative',
              top: isLg ? '10px' : '0.1951rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
        <br />
        <div
          onClick={() => {
            setIsCheck3(!isCheck3);
          }}
          className="flex cursor-pointer"
        >
          <BaseCheckbox
            checked={isCheck3}
            onChange={(e) => {
              setIsCheck3(e.target.checked);
            }}
            style={{
              marginRight: isLg ? '16px' : '0.3902rem',
            }}
          />
          <FiledTitle
            title={t('collectionBootle.FullNameInfo2') as string}
            style={{
              marginBottom: isLg ? '0px' : '0rem',
            }}
          />
        </div>
        {isCheck3Error && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={'error'}
            style={{
              position: 'relative',
              top: isLg ? '10px' : '0.1951rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
      </>
      <FiledInput
        title={
          isEn
            ? 'Patient Signature (Please enter your surname as e-signature)'
            : '患者簽名（請輸入您的姓氏作為電子簽名）'
        }
        filedInputClassName="mt-[43px] lg:mt-[74px]"
        placeholder={isEn ? 'Please enter your surname' : '輸入您的姓氏'}
        value={signature}
        onChange={(v) => {
          setSignature(v);
        }}
      />
      {signatureError && (
        <MessageText
          helpText={
            isValidSignatureError
              ? isEn
                ? 'Please enter the correct surname'
                : '請輸入正確姓氏'
              : (t('collectionBootle.fieldsRequiredMessage') as string)
          }
          placeholder={isEn ? 'Please enter your surname' : '請輸入您的姓氏'}
          status={'error'}
          style={{
            position: 'relative',
            top: isLg ? '10px' : '0.1951rem',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
    </>
  );
}
