/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react';
// import { useMemoizedFn } from 'ahooks';
import { CSVLink } from 'react-csv';
// import { CSVLink, CSVDownload } from 'react-csv';

import earth from '@images/greenEarth.svg';
import logo from '@images/INDICAIDLogo.png';
import { message } from 'antd';
// import { get } from '@utils/request';
import { switchLanguage } from '@utils/index';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import { zhHKLanguage, enLanguage } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
// import PasswordAlert from './components/PasswordAlert';
// import { FiledInputPropsOnCheckValidType } from './components/FiledInput';
// import FiledInput, { FiledInputPropsOnCheckValidType } from './components/FiledInput';
import TextInput, { TextInputStatus } from './components/TextInput';

import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';

import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import CnFiledInput, { CnFiledInputPropsOnCheckValidType } from './components/CnFiledInput';
import CollectionBootleButton, { ButtonStatus } from './components/CollectionBootleButton';
import website from '@configs/website.json';
// import { handleLocalStorage } from '@utils/index';
import { VERIFY_CODE_WAITING_SECONDS } from '@consts/consts';
import { useLSCountDown as useCountDown } from 'src/hooks/tools/countDown';
import { usePhoneNumberVerify } from 'src/hooks/pageHooks/accountVerify';
import { post } from '@utils/request';

import { useMemoizedFn } from 'ahooks';
import { ExcelType } from '@consts/enums';
import dayjs from 'dayjs';

const bgColor = {
  default: '#2DBDB6',
  disabled: '#F5F5F5',
  success: '#2DBDB6',
  dangerous: '#FF4D4F',
  orange: '#F37021',
  doctorDefault: '#009FAF',
  doctorClear: '#FFFFFF',
  doctorClearDisabled: '#FFFFFF',
  check: '#2DBDB6',
  // TODO 可能需要调整
  unchecked: undefined,
  next: '#009FAF',
};
const textColor = {
  default: '#ffffff',
  disabled: 'rgba(0, 0, 0, 0.25)',
  success: '#ffffff',
  dangerous: '#ffffff',
  orange: '#ffffff',
  doctorDefault: '#ffffff',
  doctorClear: '#009FAF',
  doctorClearDisabled: 'rgba(0, 0, 0, 0.25)',
  check: '#FFFFFF',
  unchecked: '#2DBDB6',
  next: '#FFFFFF',
};
const borderColor = {
  default: 'none',
  disabled: '1px solid #D9D9D9',
  success: 'none',
  dangerous: 'none',
  orange: 'none',
  doctorDefault: 'none',
  doctorClear: '1px solid #009FAF',
  doctorClearDisabled: '1px solid #D9D9D9',
  check: '1px solid #009FAF',
  unchecked: '1px solid #2DBDB6',
  next: 'none',
};

const style = {
  container: {
    background: '#f5f7fa',
  },
};

export type InputPropsOnCheckValidType = (
  val: string,
  setStatus: React.Dispatch<React.SetStateAction<TextInputStatus | undefined>>,
  setHelpText: React.Dispatch<React.SetStateAction<string>>,
  isPasswordCheck?: boolean,
) => void;

const ExportAgreeExcel: React.FC = () => {
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [t] = useTranslation();
  // TODO 实现export excel的功能
  const isZhHKLanguage = useIsZhHKLanguage();
  const [data, setData] = useState<any>();
  const [password, setPassword] = useState('');
  const [isPasswordCheck, setIsPasswordCheck] = useState(false);

  const [passwordHelpText, setPasswordHelpText] = useState('');
  const [passwordStatus, setPasswordStatus] = useState<TextInputStatus | undefined>();
  // TODO: 这个内容需要调整回来
  const [isShowModal, setIsShowModal] = useState(true);

  const [isOtpSubmitCheck, setIsOtpSubmitCheck] = useState(false);
  const [otp, setOtp] = useState('');

  const { phoneNumber, setPhoneNumber, isPhoneNumberValid, handleIsPhoneNumberValidChange } = usePhoneNumberVerify();
  const [otpButtonStatus, setOtpButtonStatus] = useState<ButtonStatus>('default');
  const [txt, setTxt] = useState<string>('');
  const [isFirstSubmitCheck, setIsFirstSubmitCheck] = useState(false);
  const timerId = website.password_can_resend_after;
  const { count, startCount: sendCode } = useCountDown(VERIFY_CODE_WAITING_SECONDS, timerId);

  const apiSendCode = () => {
    post('/user/send-otp', {
      type: 'phone',
      phone: phoneNumber,
    })
      .then(() => {
        sendCode();
        // alert('TODO: Alan\n' + '发送成功');
      })
      .catch((e) => {
        message.error(e.response.data.message);

        // alert('TODO: Alan\n' + e.response.data.message);
      });
  };

  const onOtpChange = (val: string) => {
    const newValue = val;
    setOtp(newValue);
  };

  const handleCheckOtp: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck, isPhoneOtpSubmitCheck) => {
      if (val === '' && !isPhoneOtpSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isPhoneOtpSubmitCheck) {
        setStatus('error');
        setHelpText(t('exportExcel.otpEmptyMessage') as string);
      } else {
        // apiAuth(isPhoneOtpSubmitCheck, setStatus, setHelpText);
        setStatus(undefined);
        setHelpText('');
      }
    },
  );
  // TODO 需要进行测试=>等待eather的接口
  const checkOtpAndPassword = () => {
    // TODO 这个接口需要替换掉，等待eather的接口
    post('/hpv/getExcelData', {
      phone: phoneNumber,
      otp: otp,
      secret: password,
      type: ExcelType.LAB,
    })
      .then((beValue) => {
        const headers = ['Email Address', 'submission date', 'aggree to receive marketing'];

        const csvData = beValue
          .map((d: any) => {
            const dJson = JSON.parse(d.data);
            if (!dJson['4'] || !dJson['26'] || !dJson['-2']) {
              return ['', '', ''];
            }
            return [dJson['4'].answer, dJson['26'].answer, dJson['-2'].answer];
          })
          .filter((d: any) => !!d[0]);

        csvData.unshift(headers);
        setData(csvData);
        // 设置excel参数结束

        setPasswordStatus('success');
        setPasswordHelpText('');
        // 每次点击的时候，才进行验证
        setIsOtpSubmitCheck(false);

        // TODO: 这是关键点，校验成功就隐藏弹窗
        setIsShowModal(false);
      })
      .catch((e) => {
        setPasswordStatus('error');
        setPasswordHelpText(e.response?.data.message as string);
        setIsOtpSubmitCheck(false);
      });
  };

  const onClickSubmit = () => {
    setIsFirstSubmitCheck(true);
    setIsOtpSubmitCheck(true);
    checkOtpAndPassword();
  };

  useEffect(() => {
    if (count === 0 && isPhoneNumberValid) {
      setOtpButtonStatus('check');
    } else {
      setOtpButtonStatus('disabled');
    }
  }, [count, isPhoneNumberValid]);

  useEffect(() => {
    if (localStorage.getItem(`timer:${website.password_can_resend_after}`)) {
      // setTxt(count === 0 ? '重新发送' : `重新发送 (${count}s)`);
      setTxt(
        count === 0
          ? (t('collectionBootle.ResendOTP') as string)
          : (t('collectionBootle.ResendCount', { count: count }) as string),
      );
    } else {
      // 获取验证码
      setTxt(t('collectionBootle.SendOTP') as string);
    }
  }, [count, t]);

  // 这里不检验密码
  useEffect(() => {
    const handleCheckPassword: InputPropsOnCheckValidType = (val, setStatus, setHelpText, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      }

      setStatus(undefined);
      setHelpText('');
      // else if (val === '' && isFiledInputSubmitCheck) {
      //   setStatus('error');
      //   setHelpText('password not empty' as string);
      // } else if (val && val === '123456') {
      //   setStatus('success');
      //   setHelpText('');
      // } else {
      //   setStatus('error');
      //   setHelpText('password matching failed' as string);
      // }
    };

    handleCheckPassword(password, setPasswordStatus, setPasswordHelpText, isPasswordCheck);
  }, [password, isPasswordCheck]);

  const onPasswordChange = (val: string) => {
    setPassword(val);
  };

  const passwordCheckModal = () => {
    return (
      <>
        <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[10000] flex justify-center items-center">
          <div className="w-[88.4%] rounded-[16px] bg-[#FFFFFF] p-[69px_20px_68px_20px] lg:w-[auto]  lg:rounded-[16px] lg:p-[24px_35px_46px_35px] max-h-[80vh] overflow-scroll shadow-[0_10px_30px_rgba(171,190,209,0.2)] lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)]">
            <div>
              <div className="flex justify-between items-start relative">
                <div className="flex flex-col">
                  <div className="text-[16px] leading-[28px] font-[600] text-[#212121] mb-[7px] w-[87.13%] lg:text-[18px] lg:leading-[36px] lg:mb-[23px]">
                    {t('exportExcel.passwordTitle')}
                  </div>
                  <div className="flex mb-[8px] lg:mb-[23px] lg:justify-between">
                    <PhoneNumberInput
                      wrapperClassName="h-[48px] lg:rounded-[8px] rounded-[6px] w-[100%] px-[15px] text-[16px]  border-[2px] flex items-center lg:text-[14px] lg:w-[100%] md:h-[48px] lg:h-[48px]  lg:px-[15px]"
                      value={phoneNumber}
                      onChange={(val: string) => {
                        setPhoneNumber(val);
                      }}
                      onIsValidChange={handleIsPhoneNumberValidChange}
                      isSubmitCheck={isFirstSubmitCheck}
                      defaultValue={phoneNumber}
                      outsideStyle={{
                        width: isLg ? '70.52%' : undefined,
                      }}
                      style={{
                        width: isLg ? '100%' : undefined,
                      }}
                    />
                    {/* TODO: 需要调整 */}
                    <CollectionBootleButton
                      htmlType="submit"
                      buttonClassName="w-[106px] ml-[5px] h-[48px] mb-[0px] md:w-[212px] md:h-[48px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px] md:ml-[17px]"
                      value={txt}
                      disable={otpButtonStatus === 'disabled'}
                      status={otpButtonStatus}
                      // status="check"
                      onClick={apiSendCode}
                      sectionClassName={isLg ? 'flex justify-end' : undefined}
                      style={{
                        padding: '0px',
                      }}
                    ></CollectionBootleButton>
                  </div>

                  <CnFiledInput
                    style={{
                      width: isLg ? undefined : undefined,
                      margin: isLg ? '0px' : undefined,
                    }}
                    value={otp}
                    onChange={onOtpChange}
                    placeholder={t('collectionBootle.OtpPlaceholder')}
                    onCheckValid={handleCheckOtp}
                    isSubmitCheck={isFirstSubmitCheck}
                    isOtpSubmitCheck={isOtpSubmitCheck}
                  />
                  <div className="flex flex-col lg:flex-row lg:h-[48px] mt-[0px] lg:mt-[23px] items-center">
                    <TextInput
                      status={passwordStatus}
                      placeholder={t('exportExcel.passwordPlaceholder') as string}
                      inputClassName={
                        'h-[48px] text-[14px] leading-[24px] tracking-[0.2px] text-[400] lg:text-[14px] lg:leading-[24px]  lg:w-[448px] md:h-[48px] lg:h-[48px] lg:tracking-[0.2px] lg:mr-[17px]'
                      }
                      onChange={(e) => onPasswordChange?.(e.target.value)}
                      helpText={passwordHelpText as string}
                    ></TextInput>
                    <CollectionBootleButton
                      htmlType="submit"
                      buttonClassName="w-full h-[50px] mb-[0px] mt-[37px] md:w-[212px] md:h-[48px] md:text-[14px] md:mb-[0px] md:mt-[0px]"
                      style={{
                        marginTop: isLg ? '0px' : '0.9024rem',
                      }}
                      value={t('collectionBootle.Submit')}
                      status={'check'}
                      onClick={() => {
                        onClickSubmit();
                        setIsPasswordCheck(true);
                      }}
                    ></CollectionBootleButton>
                  </div>
                </div>

                {/* TODO 使用绝对定位 */}
                <div
                  className="w-[14px]  lg:w-[14px] mr-[0px] ml-[auto] lg:mr-[0px] absolute top-[-9px] right-[-12px]  lg:top-[-9px] lg:right-[-12px]"
                  onClick={() => {
                    close();
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[100] bg-[rgba(0,0,0,0.5)]"></div>
      </>
    );
  };

  const onClickLanguage = () => {
    const origin = isZhHKLanguage ? zhHKLanguage : enLanguage;
    // 切换为另外一个语言
    const target = isZhHKLanguage ? enLanguage : zhHKLanguage;
    switchLanguage(origin, target);
  };

  if (isShowModal) {
    return passwordCheckModal();
  }

  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <div className="flex p-[42px_25px_28px_34px] justify-between items-center">
        <div className="w-[180px]">
          <img src={logo} alt="logo" className="object-cover w-[180px]  cursor-pointer" />
        </div>
        <div
          className="flex items-center justify-center cursor-pointer ml-[30px] lg:ml-[30px]"
          onClick={() => {
            onClickLanguage();
          }}
        >
          <div className="w-[24px]  lg:w-[29px]">
            <img src={earth} alt="earth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
          </div>
          <div className="lg:ml-[10px] font-[600] text-[#2DBDB6] lg:text-[18px] lg:leading-[18px]  text-center">
            {t('header.language')}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full h-[80vh]">
        {data && (
          <CSVLink
            data={data}
            filename={`customer agree hpv econsent list ${dayjs().format('YYYY-MM-DD hh:mm:ssa')}.csv`}
          >
            <div
              className="w-[220px] text-[16px] h-[41px] md:w-[220px] md:h-[37px] md:text-[16px] md:mt-[0px] md:mb-[0px] flex items-center justify-center text-center"
              style={{
                backgroundColor: bgColor.next,
                // backgroundImage: props.status === 'default' ? 'linear-gradient(91.08deg, #A7DC4D 0%, #2DBDB6 100%)' : '',
                color: textColor.next,
                border: borderColor.next,
                boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
                fontFamily: 'Inter',
                WebkitBoxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
                WebkitFilter: 'drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.043))',
                borderRadius: '0.122rem',
                // ...props.style,
              }}
            >
              {t('exportExcel.DownloadExcel')}
            </div>
          </CSVLink>
        )}
      </div>
    </div>
  );
};

export default ExportAgreeExcel;
