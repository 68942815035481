import earth from '@images/greenEarth.svg';
import logo from '@images/INDICAIDLogo.png';
// import './agreenments.css';
// import CollectionBootleButton from '../CollectionBootle/components/CollectionBootleButton';
// import React, { useState, useRef, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website.json';
// import { Empty } from 'antd';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import submitCorrect from '@images/submitCorrect.svg';
import dayjs from 'dayjs';
import { switchLanguage } from '@utils/index';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import { zhHKLanguage, enLanguage } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';

const bgColor = {
  default: '#2DBDB6',
  disabled: '#F5F5F5',
  success: '#2DBDB6',
  dangerous: '#FF4D4F',
  orange: '#F37021',
  doctorDefault: '#009FAF',
  doctorClear: '#FFFFFF',
  doctorClearDisabled: '#FFFFFF',
  check: '#2DBDB6',
  // TODO 可能需要调整
  unchecked: undefined,
  next: '#009FAF',
};
const textColor = {
  default: '#ffffff',
  disabled: 'rgba(0, 0, 0, 0.25)',
  success: '#ffffff',
  dangerous: '#ffffff',
  orange: '#ffffff',
  doctorDefault: '#ffffff',
  doctorClear: '#009FAF',
  doctorClearDisabled: 'rgba(0, 0, 0, 0.25)',
  check: '#FFFFFF',
  unchecked: '#2DBDB6',
  next: '#FFFFFF',
};
const borderColor = {
  default: 'none',
  disabled: '1px solid #D9D9D9',
  success: 'none',
  dangerous: 'none',
  orange: 'none',
  doctorDefault: 'none',
  doctorClear: '1px solid #009FAF',
  doctorClearDisabled: '1px solid #D9D9D9',
  check: 'none',
  unchecked: '1px solid #2DBDB6',
  next: 'none',
};

const CollectionSuccess: React.FC = () => {
  const [t] = useTranslation();
  const isZhHKLanguage = useIsZhHKLanguage();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const defaultFontStyle = useGetFontStyle();

  const onClickLanguage = () => {
    const origin = isZhHKLanguage ? zhHKLanguage : enLanguage;
    // 切换为另外一个语言
    const target = isZhHKLanguage ? enLanguage : zhHKLanguage;
    switchLanguage(origin, target);
  };

  return (
    <div className="min-h-[100vh] bg-[#f5f7fa] flex-col">
      <div
        className="w-full h-full flex flex-col min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)] bg-[#f5f7fa]"
        // style={style.container}
      >
        <div className="flex p-[42px_25px_28px_34px] justify-between items-center  lg:w-[63.68vw] lg:mx-[auto] lg:pl-[0px] lg:px-[0.55vw] lg:pt-[45px] lg:pb-[52px]">
          <div className="w-[180px] lg:w-[180px]">
            <img src={logo} alt="logo" className="object-cover w-[180px]  cursor-pointer  lg:w-[180px]" />
          </div>
          {isLg ? (
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={() => {
                onClickLanguage();
              }}
            >
              <div className="w-[24px]  lg:w-[29px]">
                <img src={earth} alt="earth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
              </div>
              <div className="lg:ml-[10px] font-[600] text-[#2DBDB6] lg:text-[18px] lg:leading-[18px]  text-center">
                {t('header.language')}
              </div>
            </div>
          ) : (
            <div
              className="w-[24px]  lg:w-[29px] cursor-pointer"
              onClick={() => {
                onClickLanguage();
              }}
            >
              <img src={earth} alt="earth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
            </div>
          )}
        </div>
        <div className="items-center flex flex-col w-[100vw] h-fit">
          <div className="w-[48px] mt-[62px] md:w-[68px] md:mt-[110px]">
            <img
              src={submitCorrect}
              alt="submitCorrect"
              className="object-cover w-[48px]  cursor-pointer lg:w-[68px]"
            />
          </div>
          <div className="font-[600] text-[22px] leading-[32px] text-[rgba(0,0,0,0.85)] mt-[15px] text-center lg:mt-[30px] lg:text-[28px] lg:leading-[32px]">
            {t('collectionSuccess.title')}
          </div>
          {isLg ? (
            <div className="mt-[15px] mb-[29px] flex lg:mt-[21px] lg:mb-[49px]">
              <div className="font-[500] lg:text-[14px] lg:leading-[22px] text-[rgba(0,0,0,0.45)]">
                {`${t('collectionSuccess.YourSampleID')}: ${
                  handleLocalStorage.get(website.collection_bootle_id) || ''
                } | ${t('collectionSuccess.Date')}:`}{' '}
                {dayjs().format('YYYY/MM/DD hh:mma')}
              </div>
            </div>
          ) : (
            <div className="mt-[15px] mb-[29px] flex flex-col lg:mt-[21px] lg:mb-[49px]">
              <div className="font-[500] text-[14px] leading-[22px] text-[rgba(0,0,0,0.45)]">
                {`${t('collectionSuccess.YourSampleID')}: ${
                  handleLocalStorage.get(website.collection_bootle_id) || ''
                }`}
              </div>
              <div className="font-[500] text-[14px] leading-[22px] text-[rgba(0,0,0,0.45)]">
                {`${t('collectionSuccess.Date')}: ${dayjs().format('YYYY/MM/DD hh:mma')}`}
              </div>
            </div>
          )}
          <a
            href="mailto:cs@indicaid.com"
            className="w-[87.9vw] h-[38px] mb-[27px] text-[16px]  md:w-[364px] md:h-[38px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px] text-center flex justify-center items-center"
            style={{
              backgroundColor: bgColor.check,
              // backgroundImage: props.status === 'default' ? 'linear-gradient(91.08deg, #A7DC4D 0%, #2DBDB6 100%)' : '',
              color: textColor.check,
              border: borderColor.check,
              boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
              fontFamily: defaultFontStyle,
              WebkitBoxShadow: undefined,
              WebkitFilter: undefined,
              borderRadius: isLg ? '5px' : '0.122rem',
              // ...props.style,
            }}
          >
            {t('collectionSuccess.QuestionsContactUs')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CollectionSuccess;
