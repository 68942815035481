/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react';
// import './CollectionBootle.css';
import { handleLocalStorage, getCNDefaultCollectionBootleDate, formatCNCollectionBootleDate } from '@utils/index';
import website from '@configs/website.json';
import { useLocalNavigate } from 'src/router/routerTools';
// import { useTranslation } from 'react-i18next';
import whiteEarth from '@images/CNImages/whiteEarth.svg';
import { useParams } from 'react-router-dom';
import { Empty } from 'antd';
import dayjs from 'dayjs';
// import FiledInput, { FiledInputPropsOnCheckValidType } from './components/FiledInput';
import CollectionBootleButton, { ButtonStatus } from './components/CollectionBootleButton';
import FiledInput, { FiledInputPropsOnCheckValidType, FiledTitle } from './components/FiledInput';
import { getCNAllValue } from '@utils/excel';
// import FiledInput, { FiledInputPropsOnCheckValidType, FiledTitle } from './components/FiledInput';

import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import { useMemoizedFn } from 'ahooks';
import {
  // hasValueMultiple,
  // changeMultipleArr,
  // removeNotDefaultValue,
  // removeHpvNotDefaultValue,
  // formatCollectionBootleDate,
  // getAnswerArr,
  isChinaName,
  // isEmail,
  // isValidHKID,
  // isValidCNID,
  // switchLanguage,
} from '@utils/index';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import { usePhoneNumberVerify } from 'src/hooks/pageHooks/accountVerify';
import { post } from '../../../utils/request';
// import { post, get } from '../../../utils/request';
import { VERIFY_CODE_WAITING_SECONDS } from '@consts/consts';
import { useLSCountDown as useCountDown } from 'src/hooks/tools/countDown';
import { message } from 'antd';

// dayjs().format('YYYY-MM-DD')
const CollectionBootle: React.FC = () => {
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  // const [t] = useTranslation();
  const { phoneNumber, setPhoneNumber, isPhoneNumberValid, handleIsPhoneNumberValidChange } = usePhoneNumberVerify();

  const [isCodeError, setIsCodeError] = useState(true);
  const navigate = useLocalNavigate();
  const collectionBottleId = useParams().collectionBottleId;
  const [fullName, setFullName] = useState('');
  const [identityNumber, setIdentityNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isFirstSubmitCheck, setIsFirstSubmitCheck] = useState(false);
  const [isOtpSubmitCheck, setIsOtpSubmitCheck] = useState(false);
  // TODO: 需要调整为false
  const [isPassOtp, setIsPassOtp] = useState(false);
  const [allValue, setAllValue] = useState<any>({});
  const [formValue, setFormValue] = useState<any>({
    0: "'" + collectionBottleId || '',
    1: '',
    2: '',
    3: '',
    4: '',
  });

  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('cnDisabled');
  const [otpButtonStatus, setOtpButtonStatus] = useState<ButtonStatus>('default');
  const [txt, setTxt] = useState<string>('');
  const timerId = website.doctor_can_resend_after;
  const { count, startCount: sendCode } = useCountDown(VERIFY_CODE_WAITING_SECONDS, timerId);

  console.log(isPhoneNumberValid, 'isPhoneNumberValid');

  useEffect(() => {
    const checkCode = () => {
      // TODO: 先统一允许通过，具体的限制需要咨询vc
      if (collectionBottleId) {
        setIsCodeError(false);
        return;
      }

      if (!collectionBottleId) {
        setIsCodeError(true);
        return;
      } else if (collectionBottleId && collectionBottleId.length !== 14) {
        setIsCodeError(true);
        return;
      }

      const lastValue = collectionBottleId[12].toString() + collectionBottleId[13].toString();

      const total =
        parseInt(collectionBottleId[4]) +
        parseInt(collectionBottleId[5]) +
        parseInt(collectionBottleId[6]) +
        parseInt(collectionBottleId[7]) +
        parseInt(collectionBottleId[8]) +
        parseInt(collectionBottleId[9]) +
        parseInt(collectionBottleId[10]) +
        parseInt(collectionBottleId[11]);

      const result = (99 - total).toString();
      if (result === lastValue) {
        setIsCodeError(false);
        handleLocalStorage.set(website.cn_collection_bootle_id, collectionBottleId);
      } else {
        setIsCodeError(true);
      }
    };
    checkCode();
  }, [collectionBottleId]);

  useEffect(() => {
    setAllValue(getCNAllValue());
  }, []);

  useEffect(() => {
    if (localStorage.getItem(`timer:${website.doctor_can_resend_after}`)) {
      setTxt(count === 0 ? '重新发送' : `重新发送 (${count}s)`);
    } else {
      setTxt('获取验证码');
    }
  }, [count]);

  useEffect(() => {
    if (count === 0 && isPhoneNumberValid) {
      setOtpButtonStatus('check');
    } else {
      setOtpButtonStatus('cnDisabled');
    }
  }, [count, isPhoneNumberValid]);

  // TODO: 这里还需要调整
  useEffect(() => {
    if (isPhoneNumberValid && isChinaName(fullName) && identityNumber) {
      setButtonStatus('check');
    } else {
      setButtonStatus('cnDisabled');
    }
  }, [isPhoneNumberValid, fullName, identityNumber]);

  useEffect(() => {
    const onSubmitData = async () => {
      // 代表着提交成功
      try {
        const formatDate = formatCNCollectionBootleDate(allValue, formValue);

        const submitTimeFormatDate = {
          ...formatDate,
        };
        const defaultValue = getCNDefaultCollectionBootleDate(getCNAllValue());
        submitTimeFormatDate[4 as keyof typeof defaultValue].answer = dayjs().format('YYYY/MM/DD hh:mma') + '';
        // TODO 存储的时候，香港也要进行处理
        submitTimeFormatDate.type = 'china';
        // console.log(defaultValue, 'defaultValue');
        // console.log(formatDate, 'formatDate');
        // console.log(submitTimeFormatDate, 'submitTimeFormatDate');
        const params = {
          data: JSON.stringify(submitTimeFormatDate),
        };
        await post('/hpv', params);
        // 移除timer
        localStorage.setItem(`timer:${website.doctor_can_resend_after}`, '');
        localStorage.removeItem(`timer:${website.doctor_can_resend_after}`);
        window.location.href = window.location.origin + '/cn/collectionSuccess';
        // navigate('cn/collectionSuccess');
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || '发生了错误';
        message.error(errorMessage);
      }

      // TODO 提交至BE，提交之前，先检查有没有报错信息
      // TODO 需要进行校验
      // setCurPage(CurPage[CurPage.Delivery] as keyof typeof CurPage);
    };

    if (isPhoneNumberValid && isChinaName(fullName) && identityNumber && isPassOtp) {
      // TODO 把数据保存至BE
      // setButtonStatus('check');
      onSubmitData();
    }
  }, [isPhoneNumberValid, fullName, identityNumber, isPassOtp, formValue, allValue]);

  // useEffect

  // submitTimeFormatDate[4 as keyof typeof defaultValue].answer = dayjs().format('YYYY/MM/DD hh:mma') + '';
  //       // TODO 存储的时候，香港也要进行处理
  //       submitTimeFormatDate.type = 'china';
  // console.log(getCNDefaultCollectionBootleDate(getCNAllValue()), 'getCNDefaultCollectionBootleDate(getCNAllValue())');

  const onClickFirstNext: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    setIsFirstSubmitCheck(true);
    setIsOtpSubmitCheck(true);

    return;
  };

  const onFullNameChange = (val: string) => {
    const newValue = val;
    setFullName(newValue);
    setFormValue({
      ...formValue,
      1: newValue,
    });
  };

  const onOtpChange = (val: string) => {
    const newValue = val;
    setOtp(newValue);
  };

  const onIdentityNumberChange = (val: string) => {
    const newValue = val;
    setIdentityNumber(newValue);
    setFormValue({
      ...formValue,
      3: newValue,
    });
  };

  const handleCheckFullName: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText('姓名不能为空' as string);
      } else if (isChinaName(val)) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText('姓名不符合格式' as string);
      }
    },
  );

  const handleCheckIdentityNumber: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText('证件号码不能为空' as string);
      } else if (val) {
        // 无需进行校验身份证号
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText('证件号码不符合格式' as string);
      }
    },
  );

  const apiAuth = (isPhoneOtpSubmitCheck: boolean | undefined, setStatus: any, setHelpText: any) => {
    if (!isPhoneOtpSubmitCheck) {
      return;
    }

    post('/user/otp-code-validation', {
      phone: phoneNumber,
      code: otp,
    })
      .then(() => {
        setStatus('success');
        setHelpText('');
        // 每次点击的时候，才进行验证
        setIsOtpSubmitCheck(false);
        setIsPassOtp(true);
        // alert('TODO: Alan\n' + '验证成功');
      })
      .catch((e) => {
        setStatus('error');
        setHelpText(e.response.data.message as string);
        // 每次点击的时候，才进行验证
        setIsOtpSubmitCheck(false);
        setIsPassOtp(false);
        // alert('TODO: Alan\n' + e.response.data.message);
      });
  };

  const handleCheckOtp: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck, isPhoneOtpSubmitCheck) => {
      if (val === '' && !isPhoneOtpSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isPhoneOtpSubmitCheck) {
        setStatus('error');
        setHelpText('验证码不能为空' as string);
      } else {
        apiAuth(isPhoneOtpSubmitCheck, setStatus, setHelpText);
        // setStatus(undefined);
        // setHelpText('');
      }
    },
  );

  // TODO:  这里需要调整
  const onClickTou = () => {
    const bottleId = handleLocalStorage.get(website.cn_collection_bootle_id) || '01010013437614';
    navigate(`/build/${bottleId}`);
  };

  const renderTitleText = () => {
    return '样本绑定';
  };
  const apiSendCode = () => {
    post('/user/send-otp', {
      type: 'phone',
      phone: phoneNumber,
    })
      .then(() => {
        sendCode();
        // alert('TODO: Alan\n' + '发送成功');
      })
      .catch((e) => {
        message.error(e.response.data.message);

        // alert('TODO: Alan\n' + e.response.data.message);
      });
  };
  // 内容区域
  const renderContent = () => {
    return (
      <div className="flex flex-col">
        <div className="flex">
          <div className="font-[500] text-[18px] leading-[28px] text-[#2DBDB6] lg:text-[22px] lg:leading-[28px]">
            样本编号：{' '}
          </div>
          <div className="font-[500] text-[18px] leading-[28px] text-[#212121] lg:text-[22px] lg:leading-[28px]">
            {collectionBottleId || '01010013437614'}
          </div>
        </div>
        <div className="flex">
          <div className="font-[500] text-[18px] leading-[28px] text-[#2DBDB6] lg:text-[22px] lg:leading-[28px]">
            采样日期：{' '}
          </div>
          <div className="font-[500] text-[18px] leading-[28px] text-[#212121] lg:text-[22px] lg:leading-[28px]">
            {dayjs().format('DD / MM / YYYY')}
          </div>
        </div>
        <div className="font-[500] text-[16px] leading-[28px] text-[#212121] mt-[20px] mb-[27px] lg:text-[16px] lg:leading-[28px]  lg:mt-[35px] lg:mb-[36px]">
          所有的文本输入栏都是必填项。请填写您的信息。
        </div>
        <FiledInput
          value={fullName}
          onChange={onFullNameChange}
          title={`1. ${allValue[3]}`}
          placeholder={'请填写姓名'}
          // filedInputClassName="mt-[29px]  lg:mt-[29px]"
          defaultValue={fullName}
          // forceUppercase={false}
          onCheckValid={handleCheckFullName}
          isSubmitCheck={isFirstSubmitCheck}
        />
        <div className="lg:w-[100%]  lg:mt-[35px] mt-[29px]">
          <FiledTitle title={`2. ${allValue[4]}`} />
          {/*  isSubmitCheck={isSubmitCheck} */}
          <PhoneNumberInput
            wrapperClassName="h-[48px] lg:rounded-[8px] rounded-[6px] w-[100%] px-[15px] text-[16px]  border-[2px] flex items-center lg:text-[14px] lg:w-[100%] md:h-[48px] lg:h-[48px]  lg:px-[15px] "
            value={phoneNumber}
            onChange={(val: string) => {
              setPhoneNumber(val);
              setFormValue({
                ...formValue,
                2: val,
              });
            }}
            onIsValidChange={handleIsPhoneNumberValidChange}
            isSubmitCheck={isFirstSubmitCheck}
            defaultValue={phoneNumber}
            defaultCountry="CN"
            contactNumberEmptyMessage="请输入电话号码"
            contactNumberInvalidMessage="联系方式无效，请重新输入"
          />
        </div>
        {/* 验证手机号列 */}
        <div className="flex mt-[23px] lg:mt-[23px] justify-start">
          <FiledInput
            style={{
              width: isLg ? '323px' : undefined,
              margin: isLg ? '0px' : undefined,
            }}
            value={otp}
            onChange={onOtpChange}
            placeholder={'请输入验证码'}
            onCheckValid={handleCheckOtp}
            isSubmitCheck={isFirstSubmitCheck}
            isOtpSubmitCheck={isOtpSubmitCheck}
          />
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-[140px] ml-[14px] h-[48px] mb-[0px] md:w-[140px] md:h-[48px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px] md:ml-[16px]"
            value={txt}
            disable={otpButtonStatus === 'disabled'}
            status={otpButtonStatus}
            // status="check"
            onClick={apiSendCode}
            sectionClassName={isLg ? 'flex justify-end' : undefined}
          ></CollectionBootleButton>
        </div>
        {/* 加手机号的发送 */}

        {/* 上面是验证码 */}
        {/* 下面是证件号码 */}
        <FiledInput
          value={identityNumber}
          onChange={onIdentityNumberChange}
          title={`3. ${allValue[5]}`}
          placeholder={'请填写证件号码'}
          filedInputClassName="mt-[27px]  lg:mt-[30px]"
          defaultValue={identityNumber}
          // forceUppercase={false}
          onCheckValid={handleCheckIdentityNumber}
          isSubmitCheck={isFirstSubmitCheck}
        />
        <CollectionBootleButton
          htmlType="submit"
          buttonClassName="w-full h-[48px] mb-[0px] mt-[35px] md:w-[343px] md:h-[56px] md:text-[16px] md:mt-[50px] md:mb-[0px] md:leading-[24px]"
          value={'提交'}
          disable={buttonStatus === 'disabled'}
          status={buttonStatus}
          // status="check"
          onClick={onClickFirstNext}
          sectionClassName={isLg ? 'flex justify-start' : undefined}
        ></CollectionBootleButton>
        {/* <button onClick={apiAuth}>校验</button> */}
      </div>
    );
  };

  return (
    // min-h-[100vh]
    <div className="collection-bottle bg-[#f5f7fa] flex-col lg:h-full ">
      {isCodeError ? (
        <div className="justify-center items-center flex w-[100vw] h-[70vh]">
          <Empty
            description={
              <div className="font-[600] text-[#e81022] text-[22px] leading-[36px]  font-[Montserrat] text-center">
                {'验证码错误！'}
              </div>
            }
          />
        </div>
      ) : (
        <>
          {/* 中间表单栏 */}
          <div
            //  h-full
            className="w-full flex flex-col bg-[#f5f7fa]  min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)] lg:w-[71.38vw] lg:m-auto lg:mb-[85px] lg:bg-[#FFFFFF] lg:rounded-[8px] lg:shadow-[0_12px_26px_rgba(16,30,115,0.06)] lg:mt-[97px]"
            // style={style.container}
          >
            {/* TODO 修改到header栏 */}
            <div className="h-[104px] flex items-center px-[22px] justify-between bg-[#2DBDB6] lg:h-[105px]  lg:px-[7.56vw]">
              <div className="font-[600] text-[#FFFFFF] text-[22px] leading-[24px] w-[100%] lg:w-[100%] lg:text-[28px] lg:leading-[24px] ">
                {renderTitleText()}
              </div>
              <div className="flex items-center justify-center lg:mr-[8px] cursor-pointer">
                <div className="w-[24px]  lg:w-[29px]">
                  <img
                    src={whiteEarth}
                    alt="whiteEarth"
                    className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]"
                  />
                </div>
                <div className="text-[18px] ml-[10px] lg:ml-[10px] font-[600] text-[#FFFFFF] lg:text-[18px] lg:leading-[18px]  text-center w-fit whitespace-nowrap">
                  简体
                </div>
              </div>
            </div>
            {/* 样本编号： 01010013437614 */}
            <div className="p-[20px_22px_63px_22px] lg:p-[36px_9.86vw_85px_7.63vw]">{renderContent()}</div>
          </div>
          {/* 底部栏 */}
          <div className="w-full py-[35px] pl-[36px] pr-[25px] bg-[#989898] font-[400] text-[#FFFFFF] text-[12px] leading-[18px] lg:pl-[14.3vw] lg:pr-[14.65vw]  lg:pt-[35px] lg:pb-[58px] lg:text-[16px] lg:leading-[28px]">
            {
              '此内容由表单所有者创建。 您提交的数据将发送给表单所有者。 Microsoft 不对其客户的隐私或安全做法负责，包括此表单所有者的隐私或安全做法。 永远不要泄露您的密码。'
            }
            {/* {t('footer.text1')} */}
            <br></br>
            <br></br>
            {'此表单的所有者没有提供关于他们将如何使用您的回复数据的隐私声明。 不要提供个人或敏感信息 |'}{' '}
            {/* {t('footer.text2')} */}
            <span
              className="font-[400] text-[#FFFFFF] text-[12px] leading-[18px] lg:text-[16px] lg:leading-[28px] cursor-pointer underline"
              onClick={onClickTou}
            >
              {'使用条款'}
              {/* {t('footer.text3')} */}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default CollectionBootle;
