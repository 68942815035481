import { renderComponent, RenderComponentOptions } from './globalComponents';
import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';

type AlertInfoProps = {
  content: string | React.ReactNode;
};
export function AlertInfo(props: AlertInfoProps & { closeFunction: () => void }) {
  return (
    <StyleProvider hashPriority="high">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#2DBDB6',
            borderRadius: 5,
          },
          components: {
            Input: {
              lineWidth: 2,
            },
            Button: {
              colorPrimary: '#2DBDB6',
              colorTextBase: '#2DBDB6',
              colorBorder: '#2DBDB6',
            },
            Radio: {
              colorPrimary: '#2DBDB6',
            },
            Checkbox: {
              colorPrimary: '#2DBDB6',
              borderRadius: 50,
              colorIconHover: '#2DBDB6',
              colorPrimaryBgHover: '#2DBDB6',
            },
            Calendar: {
              colorPrimary: '#2DBDB6',
            },
          },
        }}
      >
        <div
          onClick={props.closeFunction}
          className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[10000] bg-[#00000060] flex justify-center items-center"
        >
          <div className="lg:w-[471px] w-[360px] bg-white overflow-hidden rounded-[10px] lg:pt-[18px] lg:pb-[55px] pt-[18px] pb-[30px] px-[20px]">
            <div>{props.content}</div>
          </div>
        </div>
      </ConfigProvider>
    </StyleProvider>
  );
}

export default function alertInfo(config: RenderComponentOptions<AlertInfoProps>) {
  return renderComponent(config, AlertInfo);
}
