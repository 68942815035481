import earth from '@images/greenEarth.svg';
// import './agreenments.css';
// import CollectionBootleButton from '../CollectionBootle/components/CollectionBootleButton';
// import React, { useState, useRef, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { handleLocalStorage } from '@utils/index';
// import website from '@configs/website.json';
// import { Empty } from 'antd';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import submitCorrect from '@images/submitCorrect.svg';
// import CollectionBootleButton from '../CollectionBootle/components/CollectionBootleButton';
import dayjs from 'dayjs';
import { switchLanguage } from '@utils/index';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import { zhHKLanguage, enLanguage } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
import doctorExcelLogo from '@images/doctorExcelLogo.png';

const ExportDoctorExcelSuccess: React.FC = () => {
  const [t] = useTranslation();
  const isZhHKLanguage = useIsZhHKLanguage();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  console.log(isLg, 'isLg');

  const onClickLanguage = () => {
    const origin = isZhHKLanguage ? zhHKLanguage : enLanguage;
    // 切换为另外一个语言
    const target = isZhHKLanguage ? enLanguage : zhHKLanguage;
    switchLanguage(origin, target);
  };

  return (
    <div className="min-h-[100vh] bg-[#fff] flex-col">
      <div
        className="w-full h-full flex flex-col min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)] bg-[#fff]"
        // style={style.container}
      >
        <div className="flex p-[51px_69px_0px_69px] lg:p-[51px_69px_0px_69px] w-full justify-between items-center">
          <div className="w-[151px] lg:w-[151px]">
            <img
              src={doctorExcelLogo}
              alt="doctorExcelLogo"
              className="object-cover w-[151px] lg:w-[151px]  cursor-pointer"
            />
          </div>
          <div
            className="flex items-center justify-center cursor-pointer ml-[30px] lg:ml-[30px]"
            style={{
              display: 'none',
            }}
            onClick={() => {
              onClickLanguage();
            }}
          >
            <div className="w-[24px]  lg:w-[29px]">
              <img src={earth} alt="earth" className="object-cover  cursor-pointer  w-[24px]  lg:w-[29px]" />
            </div>
            <div className="lg:ml-[10px] font-[600] text-[#2DBDB6] lg:text-[18px] lg:leading-[18px]  text-center">
              {t('header.language')}
            </div>
          </div>
        </div>
        <div className="items-center flex flex-col w-[100vw]">
          <div className="w-[48px] mt-[62px] lg:w-[68px] lg:mt-[219px]">
            <img
              src={submitCorrect}
              alt="submitCorrect"
              className="object-cover w-[48px]  cursor-pointer lg:w-[48px]"
            />
          </div>
          <div
            className="font-[700] text-[32px] leading-[62px] text-[rgba(0,0,0,0.85)] mt-[24px] text-center lg:mt-[24px] lg:text-[32px] lg:leading-[62px]"
            style={{
              fontFamily: 'Inter',
            }}
          >
            Your submission has been successful
          </div>

          <div className="mt-[15px] mb-[29px] flex lg:mt-[24px] lg:mb-[49px]">
            <div
              className="font-[500] text-[14px] leading-[24px] lg:text-[14px] lg:leading-[24px] text-[#25282B]"
              style={{
                fontFamily: 'Montserrat',
              }}
            >
              {'Submission Date'} <span className="text-[#0072BC]">{dayjs().format('YYYY/MM/DD HH:mm')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportDoctorExcelSuccess;
