/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
// import { CSVLink, CSVDownload } from 'react-csv';

import earth from '@images/greenEarth.svg';
// import logo from '@images/INDICAIDLogo.png';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import { get } from '@utils/request';
import { switchLanguage } from '@utils/index';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import { zhHKLanguage, enLanguage } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
// import PasswordAlert from './components/PasswordAlert';
// import { FiledInputPropsOnCheckValidType } from './components/FiledInput';
// import FiledInput, { FiledInputPropsOnCheckValidType } from './components/FiledInput';
import TextInput, { TextInputStatus } from './components/TextInput';

import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';

import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import CnFiledInput, { CnFiledInputPropsOnCheckValidType } from './components/CnFiledInput';
import CollectionBootleButton, { ButtonStatus } from './components/CollectionBootleButton';
import website from '@configs/website.json';
import { handleLocalStorage } from '@utils/index';
import { VERIFY_CODE_WAITING_SECONDS } from '@consts/consts';
import { useLSCountDown as useCountDown } from 'src/hooks/tools/countDown';
import { usePhoneNumberVerify } from 'src/hooks/pageHooks/accountVerify';
import { post } from '@utils/request';

import { useMemoizedFn } from 'ahooks';
import { ExcelType } from '@consts/enums';
import dayjs from 'dayjs';
import doctorLoginLogo from '@images/doctorLoginLogo.png';
import doctorExcelLogo from '@images/doctorExcelLogo.png';

const bgColor = {
  default: '#2DBDB6',
  disabled: '#F5F5F5',
  success: '#2DBDB6',
  dangerous: '#FF4D4F',
  orange: '#F37021',
  doctorDefault: '#009FAF',
  doctorClear: '#FFFFFF',
  doctorClearDisabled: '#FFFFFF',
  check: '#2DBDB6',
  // TODO 可能需要调整
  unchecked: undefined,
  next: '#009FAF',
  viewList: undefined,
};
const textColor = {
  default: '#ffffff',
  disabled: 'rgba(0, 0, 0, 0.25)',
  success: '#ffffff',
  dangerous: '#ffffff',
  orange: '#ffffff',
  doctorDefault: '#ffffff',
  doctorClear: '#009FAF',
  doctorClearDisabled: 'rgba(0, 0, 0, 0.25)',
  check: '#FFFFFF',
  unchecked: '#2DBDB6',
  next: '#FFFFFF',
  viewList: '#0072BC',
};
const borderColor = {
  default: 'none',
  disabled: '1px solid #D9D9D9',
  success: 'none',
  dangerous: 'none',
  orange: 'none',
  doctorDefault: 'none',
  doctorClear: '1px solid #009FAF',
  doctorClearDisabled: '1px solid #D9D9D9',
  check: '1px solid #009FAF',
  unchecked: '1px solid #2DBDB6',
  next: 'none',
  viewList: '1px solid #0072BC',
};

const style = {
  container: {
    background: '#fff',
  },
};

export type InputPropsOnCheckValidType = (
  val: string,
  setStatus: React.Dispatch<React.SetStateAction<TextInputStatus | undefined>>,
  setHelpText: React.Dispatch<React.SetStateAction<string>>,
  isPasswordCheck?: boolean,
) => void;

const dataHeader = [
  { label: '樣本 ID', key: 'sampleId' },
  { label: '英文姓氏（如身份證中所述）', key: 'surname' },
  { label: '英文名字（如身份證中所述）', key: 'givenName' },
  { label: '流動電話', key: 'mobile' },
  { label: '電子郵件(選填)', key: 'email' },
  { label: '性別', key: 'gender' },
  { label: '出生日期', key: 'dob' },
  { label: '身份證件類型', key: 'idType' },
  { label: '證件號碼', key: 'idNumber' },
  { label: '您以前做過子宮頸抹片檢查嗎？', key: 'cervicalSmear' },
  { label: '您上次做子宮頸抹片檢查是什麼時候？', key: 'lastCervicalSmearDate' },
  { label: '您上一次子宮頸抹片檢查的結果是什麼？', key: 'lastCervicalSmearResult' },
  { label: '如果是陽性，請問有何異常情況？', key: 'lastCervicalSmearAbnormal' },
  { label: '您是否接受過人類乳頭瘤病毒（HPV）檢測？', key: 'lastHpvTestResult' },
  { label: '您上次HPV檢測的結果是什麼？', key: 'lastHpvTestType' },
  { label: '請列明結果病毒是哪一型 (如您選擇 高/低危型人類乳頭瘤病毒陽性)', key: 'lastHpvTestTypeDetail' },
  { label: '樣本採集日期（YYYY/MM/DD）', key: 'sampleCollectionDate' },
  { label: '樣本採集時間（HH:MM）', key: 'sampleCollectionTime' },
  { label: '表格提交日期', key: 'formSubmissionDate' },
  { label: '医生', key: 'doctorName' },
  { label: '医生是否同意', key: 'doctorAgree' },
];

const ViewMeiTuanDataList: React.FC = () => {
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [t] = useTranslation();
  // TODO 实现export excel的功能
  const isZhHKLanguage = useIsZhHKLanguage();
  const [data, setData] = useState<any>();
  const [password, setPassword] = useState('');
  const [isPasswordCheck, setIsPasswordCheck] = useState(false);

  const [passwordHelpText, setPasswordHelpText] = useState('');
  const [passwordStatus, setPasswordStatus] = useState<TextInputStatus | undefined>();
  // TODO: 这个内容需要调整回来
  const [isShowModal, setIsShowModal] = useState(true);

  const [isOtpSubmitCheck, setIsOtpSubmitCheck] = useState(false);
  const [otp, setOtp] = useState('');

  const { phoneNumber, setPhoneNumber, isPhoneNumberValid, handleIsPhoneNumberValidChange } = usePhoneNumberVerify();
  const [otpButtonStatus, setOtpButtonStatus] = useState<ButtonStatus>('disabled');
  const [txt, setTxt] = useState<string>('');
  const [isFirstSubmitCheck, setIsFirstSubmitCheck] = useState(false);
  const timerId = website.doctor_excel_password_can_resend_after;
  const { count, startCount: sendCode } = useCountDown(VERIFY_CODE_WAITING_SECONDS, timerId);
  const [isLoading, setIsLoading] = useState(false);

  const apiSendCode = () => {
    post('/hpv/doctorOpt', {
      phone: phoneNumber,
    })
      .then(() => {
        sendCode();
        // alert('TODO: Alan\n' + '发送成功');
      })
      .catch((e) => {
        message.error(e.response.data.message);

        // alert('TODO: Alan\n' + e.response.data.message);
      });
  };

  const onOtpChange = (val: string) => {
    const newValue = val;
    setOtp(newValue);
  };

  const handleCheckOtp: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck, isPhoneOtpSubmitCheck) => {
      if (val === '' && !isPhoneOtpSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isPhoneOtpSubmitCheck) {
        setStatus('error');
        setHelpText(t('exportExcel.otpEmptyMessage') as string);
      } else {
        // apiAuth(isPhoneOtpSubmitCheck, setStatus, setHelpText);
        setStatus(undefined);
        setHelpText('');
      }
    },
  );

  const getExcelData = useMemoizedFn((token: string, checkPhone: string) => {
    post('/hpv/getMeituanDataInfo', {
      phone: checkPhone,
      token: token,
    })
      .then((beValue) => {
        const result = beValue.list.map((item: { doctorName: string; doctorAgree: string }) => {
          item.doctorName = 'Dr. Lui Tat Hung';
          item.doctorAgree = 'Yes';
          return item;
        });
        setData(result);
        setPasswordStatus('success');
        setPasswordHelpText('');
        // 每次点击的时候，才进行验证
        setIsOtpSubmitCheck(false);

        // TODO: 这是关键点，校验成功就隐藏弹窗
        setIsShowModal(false);
        setIsLoading(false);
      })
      .catch((e) => {
        const errorStatus = e.response.status;
        setPasswordStatus('error');
        setIsOtpSubmitCheck(false);
        setIsLoading(false);
        if (errorStatus === 401 || errorStatus === '401') {
          return;
        } else {
          setPasswordHelpText(e.response.data.message as string);
        }
      });
  });

  // TODO 需要进行测试=>等待eather的接口
  const checkOtpAndPassword = () => {
    // TODO 这个接口需要替换掉，等待eather的接口
    post('/hpv/doctorVerify', {
      phone: phoneNumber,
      otp: otp,
    })
      .then((beValue) => {
        handleLocalStorage.set(website.doctor_excel_name, beValue?.doctorName);
        console.log(password, ExcelType.LAB);
        getExcelData(beValue.token, phoneNumber);

        setPasswordStatus('success');
        setPasswordHelpText('');
        // 每次点击的时候，才进行验证
        setIsOtpSubmitCheck(false);
      })
      .catch((e) => {
        setPasswordStatus('error');
        setPasswordHelpText(e.response.data.message as string);
        setIsOtpSubmitCheck(false);
      });
  };

  const onClickSubmit = () => {
    setIsFirstSubmitCheck(true);
    setIsOtpSubmitCheck(true);
    checkOtpAndPassword();
  };

  useEffect(() => {
    if (count === 0 && isPhoneNumberValid) {
      setOtpButtonStatus('doctorOtpDefault');
    } else {
      setOtpButtonStatus('disabled');
    }
  }, [count, isPhoneNumberValid]);

  useEffect(() => {
    if (localStorage.getItem(`timer:${website.doctor_excel_password_can_resend_after}`)) {
      // setTxt(count === 0 ? '重新发送' : `重新发送 (${count}s)`);
      setTxt(
        count === 0
          ? (t('collectionBootle.ResendOTP') as string)
          : (t('collectionBootle.ResendCount', { count: count }) as string),
      );
    } else {
      // 获取验证码
      setTxt(t('collectionBootle.SendOTP') as string);
    }
  }, [count, t]);

  // 这里不检验密码
  useEffect(() => {
    const handleCheckPassword: InputPropsOnCheckValidType = (val, setStatus, setHelpText, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      }

      setStatus(undefined);
      setHelpText('');
    };

    handleCheckPassword(password, setPasswordStatus, setPasswordHelpText, isPasswordCheck);
  }, [password, isPasswordCheck]);

  const onPasswordChange = (val: string) => {
    setPassword(val);
  };

  const LoginPage = () => {
    return (
      <>
        <div
          className="flex flex-col items-center"
          // style={{
          //   background:
          //     'linear-gradient(117.95deg, #D6EFFF -5.4%, #F1F5FC 90.69%, #E9F6FD 90.69%), linear-gradient(180deg, #F2F5FA 0%, #F0F4F9 100%, #F1F5FC 100%)',
          // }}
        >
          <img
            src={doctorLoginLogo}
            alt="doctorLoginLogo"
            className="object-cover w-[264px] lg:w-[264px] z-[10000] mt-[95px] mb-[64px] lg:mt-[95px] lg:mb-[64px]"
          />
          <div className="z-[10000] flex justify-center items-center mb-[188px] lg:mb-[188px]">
            {/* w-[88.4%] */}
            {/* lg:w-[583px] */}
            <div className="rounded-[12px] bg-[#FFFFFF] p-[52px_69px_52px_69px]  lg:rounded-[12px] lg:p-[52px_69px_52px_69px]  shadow-[0_34px_44px_rgba(105,129,137,0.1)] lg:shadow-[0_34px_44px_rgba(105,129,137,0.1)] ">
              <div>
                <div className="flex justify-between items-start relative">
                  <div className="flex flex-col">
                    <div
                      className="text-[32px] leading-[62px] font-[700] text-[#25282B] mb-[19px] lg:text-[32px] lg:leading-[62px] lg:mb-[19px] text-center"
                      style={{
                        fontFamily: 'Inter',
                      }}
                    >
                      Login
                    </div>
                    <div
                      className="text-[18px] leading-[22px] font-[400] text-[#000000] mb-[74px] lg:text-[18px] lg:leading-[22px] lg:mb-[74px] text-center"
                      style={{
                        fontFamily: 'Inter',
                      }}
                    >
                      Please login using your phone number
                    </div>
                    {/* TODO: 写到这个位置=>需要再看一下 */}
                    <div
                      className="text-[16px] leading-[20px] font-[600] text-[#1D1D1D] mb-[8px] tracking-[0.4px] lg:text-[18px] lg:leading-[20px] lg:mb-[8px] lg:tracking-[0.4px]"
                      style={{
                        fontFamily: 'Montserrat',
                      }}
                    >
                      Contact number
                      <span className="font-[600] text-[#FF0000]">*</span>
                    </div>
                    <div className="flex mb-[33px] lg:mb-[33px] lg:justify-between flex-col lg:flex-row ">
                      <PhoneNumberInput
                        wrapperClassName="h-[48px] lg:rounded-[8px] rounded-[6px] w-[100%] px-[15px] text-[16px]  border-[2px] flex items-center lg:text-[14px] lg:w-[100%] md:h-[48px] lg:h-[48px]  lg:px-[15px]"
                        value={phoneNumber}
                        onChange={(val: string) => {
                          setPhoneNumber(val);
                        }}
                        onIsValidChange={handleIsPhoneNumberValidChange}
                        isSubmitCheck={isFirstSubmitCheck}
                        defaultValue={phoneNumber}
                        outsideStyle={{
                          width: isLg ? '288px' : undefined,
                        }}
                        style={{
                          fontFamily: 'Montserrat',
                          width: isLg ? '100%' : undefined,
                        }}
                        // onlyShowHkSelect={true}
                      />
                      {/* TODO: 需要调整 */}
                      <CollectionBootleButton
                        htmlType="submit"
                        buttonClassName="ml-[0px] w-full ml-[5px] h-[48px] mb-[0px] mt-[8px] md:mt-[0px] md:ml-[10px] md:w-[147px] md:h-[48px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px] md:ml-[17px]"
                        value={txt}
                        disable={otpButtonStatus === 'disabled'}
                        status={otpButtonStatus}
                        // status="check"
                        onClick={apiSendCode}
                        sectionClassName={isLg ? 'flex justify-end' : undefined}
                        style={{
                          fontFamily: 'Montserrat',
                          padding: '0px',
                        }}
                      ></CollectionBootleButton>
                    </div>

                    <div
                      className="text-[16px] leading-[20px] font-[600] text-[#1D1D1D] mb-[8px] tracking-[0.4px] lg:text-[18px] lg:leading-[20px] lg:mb-[8px] lg:tracking-[0.4px]"
                      style={{
                        fontFamily: 'Montserrat',
                      }}
                    >
                      One-time password
                      <span className="font-[600] text-[#FF0000]">*</span>
                    </div>
                    <CnFiledInput
                      style={{
                        width: isLg ? undefined : undefined,
                        margin: isLg ? '0px' : undefined,
                      }}
                      value={otp}
                      onChange={onOtpChange}
                      placeholder={'Enter 4 digit OTP code'}
                      onCheckValid={handleCheckOtp}
                      isSubmitCheck={isFirstSubmitCheck}
                      isOtpSubmitCheck={isOtpSubmitCheck}
                    />
                    {/* <div
                      className="text-[16px] leading-[20px] font-[600] text-[#1D1D1D] mb-[8px] tracking-[0.4px] lg:text-[18px] lg:leading-[20px] lg:mb-[8px] lg:tracking-[0.4px] mt-[33px] lg:mt-[33px]"
                      style={{
                        fontFamily: 'Montserrat',
                      }}
                    >
                      Your security password
                      <span className="font-[600] text-[#FF0000]">*</span>
                    </div> */}

                    {/* Login按钮 */}
                    <CollectionBootleButton
                      htmlType="submit"
                      buttonClassName="w-full h-[50px] mb-[0px] mt-[37px] md:w-[212px] md:h-[48px] md:text-[14px] md:mt-[37px] md:mb-[0px]  mt-[0px] md:mt-[0px]"
                      style={{
                        marginTop: isLg ? '37px' : '0.9024rem',
                        width: isLg ? '100%' : '100%',
                      }}
                      value={'Login'}
                      status={'doctorOtpDefault'}
                      onClick={() => {
                        onClickSubmit();
                        setIsPasswordCheck(true);
                      }}
                    ></CollectionBootleButton>
                    {/* TODO: isOnlyRenderErrorText 只是为了展示报错信息，后续需要优化 */}
                    <div className="flex flex-col lg:flex-row lg:h-[0px] mt-[0px] lg:mt-[0px] w-full lg:w-full">
                      <TextInput
                        isOnlyRenderErrorText={true}
                        sectionClassName="w-full lg:w-full"
                        status={passwordStatus}
                        placeholder={t('exportExcel.passwordPlaceholder') as string}
                        inputClassName={
                          'h-[48px] text-[14px] leading-[24px] tracking-[0.2px] text-[400] w-[100%] lg:text-[14px] lg:leading-[24px]  lg:w-[100%] md:h-[48px] lg:h-[48px] lg:tracking-[0.2px] lg:mr-[17px]'
                        }
                        onChange={(e) => onPasswordChange?.(e.target.value)}
                        helpText={passwordHelpText as string}
                      ></TextInput>
                    </div>
                    <div
                      className="text-[14px] leading-[17px] font-[400] text-[#000000] mb-[0px] tracking-[0.4px]  mt-[39px]  lg:text-[14px] lg:leading-[17px] lg:mb-[0px] lg:tracking-[0.4px] lg:mt-[39px] text-center cursor-pointer"
                      style={{
                        fontFamily: 'Inter',
                      }}
                    >
                      Need help? <span className="text-[#0072BC] underline">Contact system administrator</span>{' '}
                    </div>
                  </div>

                  {/* TODO 使用绝对定位 */}
                  <div
                    className="w-[14px]  lg:w-[14px] mr-[0px] ml-[auto] lg:mr-[0px] absolute top-[-9px] right-[-12px]  lg:top-[-9px] lg:right-[-12px]"
                    onClick={() => {
                      close();
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[100]"
          style={{
            background:
              'linear-gradient(117.95deg, #D6EFFF -5.4%, #F1F5FC 90.69%, #E9F6FD 90.69%), linear-gradient(180deg, #F2F5FA 0%, #F0F4F9 100%, #F1F5FC 100%)',
          }}
        ></div>
      </>
    );
  };

  const onClickLanguage = () => {
    const origin = isZhHKLanguage ? zhHKLanguage : enLanguage;
    // 切换为另外一个语言
    const target = isZhHKLanguage ? enLanguage : zhHKLanguage;
    switchLanguage(origin, target);
  };

  if (isLoading) {
    return (
      <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
        <div className="w-full h-full items-center justify-center mt-[50vh]"></div>
      </Spin>
    );
  }

  if (isShowModal) {
    return LoginPage();
  }

  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[0px] md:px-[0px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <div className="flex p-[51px_69px_0px_69px] lg:p-[51px_69px_0px_69px] w-full justify-between items-center">
        <div className="w-[151px] lg:w-[151px]">
          <img
            src={doctorExcelLogo}
            alt="doctorExcelLogo"
            className="object-cover w-[151px] lg:w-[151px]  cursor-pointer"
          />
        </div>
        <div
          className="flex items-center justify-center cursor-pointer ml-[30px] lg:ml-[30px]"
          onClick={() => {
            onClickLanguage();
          }}
          style={{
            display: 'none',
          }}
        >
          <div className="w-[24px]  lg:w-[29px]">
            <img src={earth} alt="earth" className="object-cover  cursor-pointer  w-[24px]  lg:w-[29px]" />
          </div>
          <div className="lg:ml-[10px] font-[600] text-[#2DBDB6] lg:text-[18px] lg:leading-[18px]  text-center">
            {t('header.language')}
          </div>
        </div>
      </div>
      <div className="flex justify-center  w-full flex-col">
        <div
          className="mt-[120px] text-[32px] leading-[62px] font-[700] text-[#25282B] mb-[0px] lg:text-[32px] lg:leading-[62px] lg:mb-[0px] text-center lg:mt-[120px]"
          style={{
            fontFamily: 'Inter',
          }}
        >
          Lab Test Request Form
        </div>
        <div
          className="mt-[23px] text-[14px] leading-[24px] font-[500] text-[#25282B] mb-[0px] lg:text-[14px] lg:leading-[24px] lg:mb-[0px] text-center lg:mt-[23px]"
          style={{
            fontFamily: 'Montserrat',
          }}
        >
          Earliest Form Submission Date <span className="text-[#0072BC]">{dayjs().format('YYYY-MM-DD')}</span>
        </div>
        <div
          className="mt-[67px] text-[18px] leading-[22px] font-[400] text-[#000000] mb-[31px] lg:text-[18px] lg:leading-[22px] lg:mb-[31px] text-center lg:mt-[67px]"
          style={{
            fontFamily: 'Inter',
          }}
        >
          I, {handleLocalStorage.get(website.doctor_excel_name)}, would like to order the INDICAID HPV Urine test for
          the patients listed here.
        </div>
      </div>
      {data && (
        <CSVLink data={data} headers={dataHeader} filename={`${dayjs().format('YYYY-MM-DD hh:mm:ssa')}.csv`}>
          <div
            className="w-[364px] text-[16px] h-[48px] md:w-[364px] md:h-[48px] md:text-[16px] md:mt-[0px] md:mb-[0px] flex items-center justify-center text-center mx-[auto]"
            style={{
              backgroundColor: bgColor.viewList,
              // backgroundImage: props.status === 'default' ? 'linear-gradient(91.08deg, #A7DC4D 0%, #2DBDB6 100%)' : '',
              color: textColor.viewList,
              border: borderColor.viewList,
              // boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
              fontFamily: 'Montserrat',
              // WebkitBoxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
              WebkitFilter: 'drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.043))',
              borderRadius: isLg ? '5px' : '0.122rem',
              // marginRight: '0px',
              // ...props.style,
            }}
          >
            {'View list'}
          </div>
        </CSVLink>
      )}
    </div>
  );
};

export default ViewMeiTuanDataList;
