import logo from '@images/INDICAIDLogo.png';
// import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { useLocalNavigate } from 'src/router/routerTools';
import { post } from '@utils/request';
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useRef } from 'react';
import website from '@configs/website.json';
import { useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

export default function PaymentSuccessSubmit() {
  const payment_intent = useSearchParams()[0].get('payment_intent');
  // const d5 = useSearchParams()[0].get('d5');
  const navigate = useLocalNavigate();
  const [t] = useTranslation();

  const isSubmiting = useRef(false);
  // ①如果有sessionStorage
  // ②url里面状态为success
  // ③submit数据到BE
  useEffect(() => {
    if (isSubmiting.current) {
      return;
    }
    isSubmiting.current = true;

    const submitData = sessionStorage.getItem(website.payment_submit_data)
      ? JSON.parse(sessionStorage.getItem(website.payment_submit_data) || '')
      : undefined;

    const submitPaymentSuccessData = async () => {
      // 代表着提交成功
      try {
        const params = submitData;

        await post('/hpv/add', params);

        await post('/hpv/payment/callback', {
          paymentIntentId: payment_intent,
        });

        // 如果成功，需要移除sessionStorage
        sessionStorage.removeItem(website.payment_submit_data);
        sessionStorage.removeItem(website.is_user_pick_up_submit);
        localStorage.setItem(website.collection_bootle_id, params?.hpvInfo?.sampleId || '');
        navigate('/collectionSuccess');
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage =
          (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || t('collectionBootle.error');
        message.error(errorMessage);
      }

      isSubmiting.current = false;
    };

    if (submitData && payment_intent) {
      const debounceSubmit = _.throttle(submitPaymentSuccessData, 300);
      debounceSubmit();
    } else {
      message.error(t('collectionBootle.error'));
      isSubmiting.current = false;
    }

    // return () => {
    //   submitPaymentSuccessData.cancel();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh)] lg:min-h-[calc(100vh)] bg-[#F6F8FB] lg:h-[100%]"
      // style={style.container}
    >
      <div className="flex p-[42px_25px_28px_34px] justify-center items-center">
        <div className="w-[180px]">
          <img src={logo} alt="logo" className="object-cover w-[180px]  cursor-pointer" />
        </div>
      </div>
      <div className="flex items-center justify-center w-full h-[80vh]">
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    </div>
  );
}
