import TextInput, { TextInputStatus } from '@components/TextInput/TextInput';

import { useEffect, useState } from 'react';
import { CSSProperties } from 'react';

export type FiledInputProps = {
  title?: string | null;
  status?: TextInputStatus;
  helpText?: string;
  placeholder: string;
  value: string;
  onChange?: (v: string) => void;
  /**
   * 检查数据合法性
   * @param v 值
   * @param option
   * @returns
   */
  onCheckValid?: (v: string) => boolean;
  filedInputClassName?: string;
  defaultValue?: string;
  forceUppercase?: boolean;
  showPop?: boolean;
  formName?: string;
  clearNumber?: number;
  isFullName?: boolean;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  overlayStyle?: CSSProperties;
  fontFamilyValue?: string;
  subHelpText?: string;
};

export function FiledTitle(props: { title: string; style?: CSSProperties }) {
  return (
    <p
      className="font-[600] text-[14px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[16px] flex lg:leading-[20px] lg:tracking-[0.4px] "
      style={props?.style}
    >
      {props.title}
      {/* <span className="font-[600] text-[#FF0000]">*</span> */}
    </p>
  );
}

export default function FiledInput(props: FiledInputProps) {
  const { value, onCheckValid, status, helpText } = props;

  const [innerStatus, setStatus] = useState<TextInputStatus | undefined>(status);

  useEffect(() => {
    setStatus(status);
  }, [status]);

  useEffect(() => {
    if (onCheckValid) {
      if (onCheckValid(value)) {
        setStatus('success');
      }
    }
  }, [value, onCheckValid]);

  return (
    <div className={`lg:w-[100%] m-auto w-[100%] ${props?.filedInputClassName}`}>
      {props.title && <FiledTitle title={props.title} />}
      <TextInput
        value={props.value}
        status={innerStatus}
        placeholder={props.placeholder}
        formName={props.formName}
        clearNumber={props.clearNumber}
        inputClassName={
          'h-[48px] text-[14px] leading-[24px] tracking-[0.2px] text-[400] lg:text-[14px] lg:leading-[24px]  lg:w-[100%] md:h-[48px] lg:h-[48px] lg: tracking-[0.2px]'
        }
        onChange={(e) => props.onChange?.(e.target.value)}
        helpText={helpText}
        defaultValue={props?.defaultValue}
        forceUppercase={props?.forceUppercase}
        isFullName={props?.isFullName}
        onBlur={props?.onBlur}
        fontFamilyValue={props?.fontFamilyValue}
        subHelpText={props?.subHelpText}
      ></TextInput>
    </div>
  );
}
