import { createPageComponent } from '@components/ConfigPage/BaseComponent/formContext';
import CollectionHeader from '@components/ConfigPage/common/CollectionHeader';
import { StepBox } from '@components/ConfigPage/common/StepBox';
import { useParams } from 'react-router-dom';
import { compaginConfig } from './0749.config';
import QA from '../QA';
import Agree from '../Agree';
import { Empty } from 'antd';
import { t } from 'i18next';
import { useState, useEffect } from 'react';
import compaginApi from '../apis/compagin.api';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';
import { message } from 'antd';
const Page = createPageComponent();

export default function Test() {
  const step = useParams().step || 1;
  const { compaginId, collectionBottleId } = useParams();
  const defaultFontStyle = useGetFontStyle();

  const [isCodeError, setIsCodeError] = useState(false);
  useEffect(() => {
    compaginApi.checkSampleId(collectionBottleId!, compaginId!).then((res) => {
      if (!res) {
        setIsCodeError(true);
      }
    });

    compaginApi.isSampleIdUsed(collectionBottleId!).catch((e) => {
      setIsCodeError(true);
      if (e.response) {
        message.error(e.response.data.message);
      } else {
        message.error((e as any).message);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (step === 'q' && !compaginConfig.qa) {
    return <></>;
  }

  const render = () => {
    if (step === 'q' && !isCodeError) {
      return <QA content={compaginConfig.qa!.content} contentCn={compaginConfig.qa!.contentCn} />;
    } else if (step === 'a' && !isCodeError) {
      return (
        <Agree
          title={compaginConfig.agreement!.title}
          titleCn={compaginConfig.agreement!.titleCn}
          contentList={compaginConfig.agreement!.contentList}
        />
      );
    } else {
      return (
        <>
          {isCodeError ? (
            <div className="justify-center items-center flex w-[100vw] h-[70vh]">
              <Empty
                description={
                  <div
                    className="font-[600] text-[#e81022] text-[22px] leading-[36px]   text-center"
                    style={{
                      fontFamily: defaultFontStyle,
                    }}
                  >
                    {t('agreenments.codeErrorMessage')}
                  </div>
                }
              />
            </div>
          ) : (
            <StepBox step={+step - 1}></StepBox>
          )}
        </>
      );
    }
  };

  return (
    <Page compaginName={compaginConfig.compaginName} pageConfig={compaginConfig.pageConfigs}>
      <CollectionHeader headerImg={compaginConfig.headerImg} />
      {render()}
    </Page>
  );
}
