import BaseCheckbox from '@components/BaseCheckbox/BaseCheckbox';
import { MEDIA_SIZE } from '@consts/consts';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useAddCheckValidFunc } from './BaseComponent/formContext';

export default function AgreeCheckbox(props: { content: string; contentCn: string }) {
  const isZhHKLanguage = useIsZhHKLanguage();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [t] = useTranslation();

  const [isChecked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState(false);

  useAddCheckValidFunc(() => {
    if (isChecked) {
      return true;
    }
    setIsError(true);
    return false;
  });

  return (
    <>
      <div className="flex">
        <BaseCheckbox
          checked={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked);
          }}
          style={{
            marginRight: isLg ? '16px' : '0.3902rem',
            marginTop: isLg ? '0px' : undefined,
          }}
        />
        <div>{isZhHKLanguage ? props.contentCn : props.content}</div>
      </div>
      {isError && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={'error'}
          style={{
            marginTop: '5px',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
    </>
  );
}
