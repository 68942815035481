import { isEmail } from '@utils/index';
import { useMemoizedFn } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddCheckValidFunc, usePageContext } from './BaseComponent/formContext';
import FiledInput from './BaseComponent/FiledInput';
import { BasePageComponentProps, PageComponentConfigMap } from './BaseComponent/PageComponentMap';
// import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';

type EmailInputProps = BasePageComponentProps & PageComponentConfigMap['email'];

const EmailInput = (props: EmailInputProps) => {
  const { pageValues, setPageValue } = usePageContext();

  const onValueChange = (v: string) => {
    setPageValue(props.title, v);
  };

  const value = pageValues[props.title]?.value;

  const [t] = useTranslation();
  const [email, setEmail] = useState(value || '');
  const [doubleCheckEmail, setDoubleCheckEmail] = useState(value || '');
  const handleEmailChange = (v: string) => {
    setEmail(v);
    onValueChange?.(v);
  };
  const onDoubleCheckEmailChange = (v: string) => {
    setDoubleCheckEmail(v);
  };

  const [isError, setIsError] = useState(false);
  const [isDoubleCheckError, setIsDoubleCheckError] = useState(false);

  const checkValid = useMemoizedFn(() => {
    if (!email && !doubleCheckEmail && props.required === false) {
      return true;
    }

    if (email && isEmail(email) && email === doubleCheckEmail) {
      return true;
    } else if (!email || !isEmail(email)) {
      setIsError(true);
    }
    if (email !== doubleCheckEmail || !email) {
      setIsDoubleCheckError(true);
    }
    return false;
  });

  useAddCheckValidFunc(checkValid);

  // const isZhHKLanguage = useIsZhHKLanguage();

  return (
    <>
      <div className="mt-[30px] font-[500] text-[#25282B] text-[14px] leading-[28px] lg:mt-[40px] lg:text-[16px] lg:leading-[28px]">
        {props.No + '. ' + t('collectionBootle.EmailReminder')}
      </div>
      {/* email */}
      <FiledInput
        value={email}
        onChange={handleEmailChange}
        title={t('collectionBootle.question4')}
        placeholder={t('collectionBootle.question4Placeholder')}
        filedInputClassName="mt-[29px] lg:mt-[29px]"
        defaultValue={email}
        fontFamilyValue={'Montserrat'}
        status={isError ? 'error' : undefined}
      />
      {/* doubleCheckEmail */}
      <FiledInput
        value={doubleCheckEmail}
        onChange={onDoubleCheckEmailChange}
        title={t('collectionBootle.DoubleCheckEmailTitle')}
        placeholder={t('collectionBootle.question4Placeholder') as string}
        filedInputClassName="mt-[12px] lg:mt-[12px]"
        defaultValue={doubleCheckEmail}
        fontFamilyValue={'Montserrat'}
        status={isDoubleCheckError ? 'error' : undefined}
      />
    </>
  );
};

export default EmailInput;
