import { useTranslation } from 'react-i18next';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import allImg from '../../CollectionSuccess/All.png';
import aboutImg from '../../CollectionSuccess/about.png';
import CollectionBootleButton from '../components/CollectionBootleButton';

export default function RenderUpgrade(props: {
  onClick?: () => void;
  onSkipClick?: (e: any) => void;
  isPickUpNeedPayment: boolean;
  onBackClick?: () => void;
}) {
  const [t] = useTranslation();
  const isZhHKLanguage = useIsZhHKLanguage();

  let skipText = props.isPickUpNeedPayment ? '跳過' : '跳過 & 提交';
  if (!isZhHKLanguage) {
    skipText = props.isPickUpNeedPayment ? 'Skip' : 'Skip & Submit';
  }
  return (
    <div className="flex flex-col justify-center items-center pt-[34px] md:pt-[34px]">
      <div className="w-fit">
        <div
          className="
            md:w-[766px]
            md:rounded-[10px]
            rounded-[10px]
            w-[364px]
            md:py-[21px] md:px-[49px]
            py-[22px] pl-[27px] pr-[15px]
            border-[1px] border-solid
            border-[#eaeaea] md:mt-[14px]
            bg-white md:mb-[33px] mb-[30px]"
        >
          <p className="md:text-[22px] text-[22px] leading-[28px] text-center md:text-center font-semibold md:leading-[28px]">
            {isZhHKLanguage ? (
              t('HPV12.title2')
            ) : (
              <>
                Upgrade to HPV Urine Test - <br className="md:hidden" /> 27 Types
              </>
            )}
          </p>
          <div className="flex items-end md:mb-[10px] mb-[18px] md:mt-[12px] mt-[7px] md:justify-center justify-center">
            <p className="text-[#2DBDB6] font-semibold md:text-[24px] text-[24px]">$400</p>
            <p className="md:text-[18px] text-[#212121] md:ml-[6px] ml-[6px] text-[18px]">{t('HPV12.currency')}</p>
          </div>
          <div className="w-full h-[1px] md:h-[1px] bg-[#2DBDB61A] mb-[16px]" />
          <div className="flex items-center">
            <img className="md:w-[20px] md:h-[20px] md:mr-[6px] w-[20px] h-[20px] mr-[6px]" src={allImg} alt="all" />
            <p className="md:text-[14px] md:leading-[24px] font-semibold text-[14px] leading-[24px]">
              {t('HPV12.sub1')}
            </p>
          </div>
          <ul className="md:text-[14px] md:text-[#444444] md:leading-[22px] list-disc md:ml-[44px] md:mt-[7px] md:mb-[13px] mb-[15px] text-[14px] leading-[22px] ml-[44px] mt-[7px]">
            <li>{t('HPV12.sub1_1')}</li>
            <li>{t('HPV12.sub1_2')}</li>
          </ul>
          <div className="flex items-center">
            <img
              className="md:w-[20px] md:h-[20px] md:mr-[6px] w-[20px] h-[20px] mr-[6px]"
              src={aboutImg}
              alt="about"
            />
            <p className="md:text-[14px] md:leading-[24px] font-semibold text-[14px] leading-[24px]">
              {t('HPV12.sub2')}
            </p>
          </div>
          <ul className="md:text-[14px] md:text-[#444444] md:leading-[22px] list-disc md:ml-[44px] md:mt-[7px] md:mb-[13px] mb-[15px] text-[14px] leading-[22px] ml-[44px] mt-[7px]">
            <li>{t('HPV12.sub2_1')}</li>
            <li>{t('HPV12.sub2_2')}</li>
          </ul>
          <CollectionBootleButton
            onClick={props.onClick}
            sectionClassName="flex justify-center mt-[20px] md:mt-[43px]"
            buttonClassName="w-full h-[38px] mb-[18px] md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
            value={
              <div className="flex h-full items-center">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_4393_7436)">
                    <path
                      d="M15.3516 1.5H3.64842L0.5 7.67467L9.5 16.5001L18.5 7.67467L15.3516 1.5ZM2.36926 7.37707L4.59764 3.00645H14.4022L16.6306 7.37707L9.5 14.3695L2.36926 7.37707Z"
                      fill="white"
                    />
                    <path
                      d="M9.50002 10.6036L6.20254 7.37012L5.11621 8.43535L9.50002 12.7343L13.8838 8.43535L12.7975 7.37012L9.50002 10.6036Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4393_7436">
                      <rect width="18" height="18" fill="white" transform="translate(0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="ml-[4px] md:ml-[5px]">{t('collectionBootle.progress4')}</p>
              </div>
            }
          />
          <p className="text-[16px] md:text-[16px] underline text-[#2dbdb6] text-center cursor-pointer">
            <span onClick={props.onSkipClick}>{skipText}</span>
          </p>
        </div>
        <div
          onClick={props.onBackClick}
          className="
        md:w-[170px] md:h-[50px]
        w-full h-[38px]
        border-[1px] border-solid border-[#2DBDB6]
        rounded-[10px] md:rounded-[10px]
        text-[#009FAF]
        flex items-center justify-center
        text-[14px] md:text-[16px]
        font-medium
        cursor-pointer
        hover:bg-[#2DBDB6] hover:text-white
      "
        >
          {isZhHKLanguage ? '返回' : 'Back'}
        </div>
      </div>
    </div>
  );
}
