import { get } from '@utils/request';

interface ISampleStatus {
  isExist: boolean;
  isExpired: boolean;
  region: number;
  type: number;
  expiredDate?: string;
}

export function getSampleStatus(sampleId: string) {
  return get<ISampleStatus>(`/codes/sample_id/status?sampleId=${sampleId}`);
}
