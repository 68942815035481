import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  // AddressElement,
} from '@stripe/react-stripe-js';
import { message as alertMessage } from 'antd';
import classNames from 'classnames';

import { handleLocalStorage } from '@utils/index';
import website from '@configs/website.json';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { PaymentData } from './Payment';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import React, { useEffect, useState } from 'react';
import FiledInput, { FiledInputPropsOnCheckValidType } from '../FiledInput';
import CollectionBootleButton, { ButtonStatus } from '../CollectionBootleButton';
import { useMemoizedFn } from 'ahooks';
import { post } from '@utils/request';
import { useLocalNavigate } from 'src/router/routerTools';

// import logo from '@images/INDICAIDLogo.png';
// import { useSearchParams } from 'react-router-dom';
// import { Input } from 'antd';
// import { MailOutlined } from '@ant-design/icons';
// import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
export type PaymentForm = {
  paymentData: PaymentData;
  setIsOnPayment?: (isOnPayment: boolean) => void;
  isNeedUpgrade?: boolean;
  isPickUp?: boolean;
};

export default function CheckoutForm(props: PaymentForm) {
  const navigate = useLocalNavigate();
  const submitData = sessionStorage.getItem(website.payment_submit_data)
    ? JSON.parse(sessionStorage.getItem(website.payment_submit_data) || '')
    : undefined;

  const isHkLanguage = useIsZhHKLanguage();
  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  // const d4 = useSearchParams()[0].get('d4');
  // const d5 = useSearchParams()[0].get('d5');

  const stripe = useStripe();

  const elements = useElements();

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [otpButtonStatus, setOtpButtonStatus] = useState<ButtonStatus>('default');
  const [coupon, setCoupon] = useState('');
  const [checkCouponErrorMessage, setCheckCouponErrorMessage] = useState<boolean | string | undefined>(false);

  useEffect(() => {
    setOtpButtonStatus('default');
  }, []);

  const onCouponChange = (val: string) => {
    const newValue = val.toUpperCase();
    setCoupon(newValue);
  };

  const handleCheckCoupon: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      console.log(isFiledInputSubmitCheck, 'isFiledInputSubmitCheck');
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(isFiledInputSubmitCheck as string);
      } else if (val) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('PaymentPage.InvalidCoupon') as string);
      }
    },
  );

  const apiSendCode = () => {
    post('/hpv/use_coupon', {
      code: coupon,
    })
      .then(() => {
        alertMessage.success(t('PaymentPage.VerificationPassed') as string);
        navigate('/paymentSuccessSubmit?payment_intent=cp');
      })
      .catch((e) => {
        setCheckCouponErrorMessage(e.response.data.message as string);
        // alertMessage.error(e.response.data.message as string);
      });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    //获取当前网址，如： http://localhost:80/jflow-web/index.jsp
    const curPath = window.document.location.href;
    const pathName = window.document.location.pathname;
    const pos = curPath.indexOf(pathName);
    const localhostPaht = curPath.substring(0, pos);
    console.log(localhostPaht, 'localhostPaht');
    console.log(isHkLanguage, 'isHkLanguage');
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // TODO: 看看能不能不传return_url
        return_url: isHkLanguage ? `${localhostPaht}/paymentSuccessSubmit` : `${localhostPaht}/en/paymentSuccessSubmit`,
      },
    });

    if (!error) {
      // 成功的逻辑在这里面
      return;
    }

    handleLocalStorage.set(website.payment_error, JSON.stringify(error));
    // navigate('/paymentFailure');

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.code == 'incomplete_address') {
      setMessage(t('PaymentPage.AddressIncomplete') as string);
    } else {
      setMessage(error.message || (t('PaymentPage.Unknown') as string));
    }
    setIsLoading(false);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  // const shippingMode = true;
  return (
    <div className="flex flex-col pb-[40px] bg-[#F6F8FB] lg:pb-[40px]">
      {/* <div className="w-[180px]">
        <img src={logo} alt="logo" className="object-cover w-[180px]  cursor-pointer" />
      </div> */}
      <div className="mb-[43px] rounded-[10px] bg-[#EFEFEF] shadow-[0_10px_30px_0px_rgba(171,190,209,0.20)] p-[19px_14px_20px_14px] px-[20px] flex flex-col lg:mb-[43px] lg:rounded-[10px] lg:shadow-[0_10px_30px_0px_rgba(171,190,209,0.20)] lg:p-[19px_14px_20px_14px] lg:px-[40px] lg:pt-[40px]">
        <div className="font-[600] text-[16px] leading-[24px] text-[#212121] mb-[20px] lg:text-[16px] lg:leading-[24px] lg:mb-[20px]">
          {t('PaymentPage.BillingSummary')}
        </div>

        {/* 升级费用 */}
        {props.isNeedUpgrade && (
          <div className="flex mb-[8px] justify-between lg:mb-[8px]">
            <div className="font-[500] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
              {t('PaymentPage.upgradeToHPV27Fee')}
            </div>
            <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
              HKD $400
            </div>
          </div>
        )}
        {/* pick up 费用 */}
        {props.isPickUp && (
          <>
            <div className="flex mb-[8px] justify-between lg:mb-[8px]">
              <div className="font-[500] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                {t('PaymentPage.sampleFee')}
              </div>
              <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                HKD $120
              </div>
            </div>
            <div className="font-[500] text-[14px] leading-[22px] text-[#212121] mb-[8px] lg:font-[500] lg:text-[14px] lg:leading-[22px] lg:mb-[8px]">
              {t('PaymentPage.PickAddress')}
            </div>
            <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] break-all">
              {isHkLanguage ? submitData?.cnPosition : submitData?.position}
            </div>
          </>
        )}
        <hr className="my-[16px] lg:my-[16px]"></hr>
        {/* wrapperClassName="h-[48px] lg:rounded-[8px] rounded-[6px] w-[100%] px-[15px] text-[16px]  border-[2px] flex items-center lg:text-[14px] lg:w-[100%] lg:h-[48px] lg:h-[48px]  lg:px-[15px]" */}
        {props.isPickUp && (
          <>
            <div className={classNames('mb-[0px] lg:mb-[0px]', 'flex  lg:justify-between')}>
              <FiledInput
                value={coupon}
                onChange={onCouponChange}
                title={undefined}
                placeholder={t('PaymentPage.DiscountCode')}
                filedInputClassName="mt-[0px] lg:mt-[0px] discount-code"
                defaultValue={coupon}
                forceUppercase={true}
                onCheckValid={handleCheckCoupon}
                isSubmitCheck={checkCouponErrorMessage}
                fontFamilyValue={'Montserrat'}
              />
              <CollectionBootleButton
                htmlType="submit"
                buttonClassName="w-[160px] ml-[5px] h-[48px] mb-[0px] md:w-[180px] md:h-[48px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px] md:ml-[16px] ml-[11px]"
                value={t('PaymentPage.send')}
                disable={otpButtonStatus === 'disabled'}
                status={otpButtonStatus}
                // status="check"
                onClick={apiSendCode}
                sectionClassName={
                  isLg
                    ? 'flex justify-end h-[48px] md:h-[48px] md:w-[180px]'
                    : 'h-[48px] md:h-[48px] w-[160px] md:w-[160px] items-center'
                }
                style={{
                  padding: '0px',
                }}
              ></CollectionBootleButton>
            </div>
            <hr className="mt-[16px] mb-[21px] lg:mt-[16px] lg:mb-[21px]"></hr>
          </>
        )}

        <div className="flex justify-between">
          <div className="font-[500] text-[16px] leading-[24px] text-[#212121] lg:text-[16px] lg:leading-[24px]">
            {t('PaymentPage.Total')}
          </div>
          <div className="font-[500] text-[16px] leading-[24px] text-[#000] lg:text-[16px] lg:leading-[24px]">
            HKD ${(props.isNeedUpgrade ? 400 : 0) + (props.isPickUp ? 120 : 0)}
          </div>
        </div>
      </div>
      {/* form表单 */}
      <form id="payment-form" className="lg:p-[40px] p-[20px] pb-[48px] bg-[#fff] w-full" onSubmit={handleSubmit}>
        {/* <Input size="large" className="outline-0" placeholder="email" prefix={<MailOutlined />} />
      <PhoneNumberInput /> */}
        <div style={{ fontSize: 18, fontWeight: 600, color: '#1A1F36', marginBottom: isLg ? 24 : 48 }}>
          {t('PaymentPage.PaymentDetail')}
        </div>
        <LinkAuthenticationElement id="indicaid-payment-form-email" />
        <PaymentElement
          id="indicaid-payment-form-payment"
          options={{
            layout: 'tabs',
            defaultValues: {
              billingDetails: {
                address: {
                  country: 'HK',
                },
              },
            },
          }}
        />
        {/* {shippingMode && (
        <div style={{ fontSize: 18, fontWeight: 600, color: '#1A1F36', marginBottom: 24, marginTop: 41 }}>
          {t('PaymentPage.ShippingAddress')}
        </div>
      )}
      <AddressElement
        id="indicaid-payment-form-addr"
        options={{
          mode: shippingMode ? 'shipping' : 'billing',
          allowedCountries: ['HK'],
          // defaultValues: {
          //   address: {
          //     country: 'HK',
          //   },
          // },
        }}
      /> */}

        <button
          className="submit-payments mt-[20px] w-full h-[50px] lg:w-[100%] lg:h-[50px]"
          disabled={isLoading || !stripe || !elements}
          id="indicaid-payment-form-submit"
          style={{
            width: isLg ? '100%' : '100%',
          }}
        >
          <span id="button-text">{isLoading ? antIcon : t('PaymentPage.PayNow')}</span>
        </button>
        {message && (
          <div className="text-left flex" id="payment-message" style={{ color: '#C83532', gap: 5 }}>
            <ExclamationCircleFilled className={'text-[16px] sm:text-[16px]'} />
            {message}
          </div>
        )}
        <button
          onClick={() => {
            props?.setIsOnPayment && props?.setIsOnPayment(false);
            sessionStorage.removeItem(website.is_user_pick_up_submit);
          }}
          className="back-payments mt-[12px] lg:mt-[12px] w-full h-[50px] lg:w-[100%] lg:h-[50px]"
          style={{
            width: isLg ? '100%' : '100%',
          }}
        >
          <span>{t('PaymentPage.backText')}</span>
        </button>
      </form>
    </div>
  );
}
