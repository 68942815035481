import logo from '@images/INDICAIDLogo.png';
import { CSSProperties, useRef } from 'react';
import { useContext, useEffect, useState } from 'react';
import CheckoutForm from './CheckoutForm';

import { StripeElementLocale, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import getStripePromise from './getStripePromise';

import { LanguageContext, useLocalNavigate } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
// import { post } from '../../../utils/request';
import { post } from '@utils/request';
import './payments.css';
import website from '@configs/website.json';
import { LeftOutlined } from '@ant-design/icons';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';

export interface ApiGetPaymentResponse {
  // productType: ProductType.Medication;
  expire: number;
  firstName: string;
  lastName: string;
  symptoms: string;
  prescription?: string;
  bookingId: number;
  displayId: string;
  clientSecret: string;
  intentId: string;
  date: string;
  time: string;
  doctorName: string;
  total: {
    amount: number; // 440
    text: string; // $ 440
  };
}
export type PaymentData = ApiGetPaymentResponse;

export type PaymentStatus = 'error' | 'warning' | 'success' | undefined;
type PaymentProps = {
  value?: string;
  onFocus?: () => void;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  status?: PaymentStatus;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  inputClassName?: string;
  iconClassName?: string;
  defaultValue?: string;
  forceUppercase?: boolean;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  setIsOnPayment?: (isOnPayment: boolean) => void;
  isNeedUpgrade?: boolean;
  isPickUp?: boolean;
};

export default function Payment(props: PaymentProps = {}) {
  const navigate = useLocalNavigate();
  const [t] = useTranslation();
  const defaultFontStyle = useGetFontStyle();
  const [paymentData, setPaymentData] = useState<PaymentData>();
  const clientSecret = paymentData?.clientSecret;
  const beIntentId = paymentData?.intentId;
  const submitData = sessionStorage.getItem(website.payment_submit_data)
    ? JSON.parse(sessionStorage.getItem(website.payment_submit_data) || '')
    : undefined;
  console.log(beIntentId, 'beIntentId');

  console.log(paymentData, 'paymentData');

  const language = useContext(LanguageContext);

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
    // zh-HK
    locale: language as StripeElementLocale,
  };

  const isCreating = useRef(false);

  useEffect(() => {
    if (isCreating.current) {
      return;
    }
    isCreating.current = true;

    let product = '';
    if (props?.isNeedUpgrade && props?.isPickUp) {
      product = 'combinedHpv12AndDilivery';
    } else if (props?.isNeedUpgrade && !props?.isPickUp) {
      product = 'upgradeHpv12';
    } else if (!props?.isNeedUpgrade && props?.isPickUp) {
      product = 'dilivery';
    } else {
      console.error('error');

      return;
    }

    // post('/payment/create'
    post('/hpv/payment/create', {
      metadata: {
        sampleId: submitData?.hpvInfo?.sampleId || '',
        product,
      },
    })
      .then((rs: ApiGetPaymentResponse) => {
        if (rs.expire == 0) {
          console.log('error');
          // TODO: 后续需要修改
          // navigate('/paymentFailure');
          // handleLocalStorage.set(
          //   website.payment_error,
          //   JSON.stringify({
          //     type: 'custom_expire_medication',
          //   }),
          // );
          // navigate(`/paymentFailure${isHaveSelectResultType ? `?selectResultType=${selectResultType}` : ''}`);
        } else {
          // TODO 刷新的时候，好像会有bug=>需要再看一下
          // TODO: rs.....
          setPaymentData(rs);
          sessionStorage.setItem('paymentData', JSON.stringify(rs));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        isCreating.current = false;
      });
  }, [navigate, props?.isNeedUpgrade, props?.isPickUp, submitData?.hpvInfo?.sampleId]);

  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh)] lg:min-h-[calc(100vh)] bg-[#F6F8FB] lg:h-fit"
      // style={style.container}
    >
      <div className="flex p-[42px_25px_28px_34px] justify-between items-center">
        <div className="w-[180px]">
          <img src={logo} alt="logo" className="object-cover w-[180px]  cursor-pointer" />
        </div>
      </div>
      <div
        onClick={() => {
          props?.setIsOnPayment && props?.setIsOnPayment(false);
          sessionStorage.removeItem(website.is_user_pick_up_submit);
        }}
        className="text-[#2DBDB6] flex justify-start items-center mb-[40px] lg:mb-[40px] w-full lg:w-full ml-[0px] lg:ml-[18.68vw] cursor-pointer hover:text-[#00857E]"
        style={{
          display: 'none',
        }}
      >
        <LeftOutlined className="text-[18px] lg:text-[18px]" />
        <div
          className="text-[16px] lg:text-[16px]  ml-[14px] lg:ml-[14px] font-[500]"
          style={{
            fontFamily: defaultFontStyle,
          }}
        >
          {t('PaymentPage.backText')}
        </div>
      </div>
      <div className="flex items-center justify-center w-full min-h-[80vh]">
        <div className="flex justify-center">
          <div className="payments w-[100%] lg:w-[30vw] lg:min-[500px]">
            {clientSecret && (
              <Elements options={options} stripe={getStripePromise()}>
                <CheckoutForm
                  isNeedUpgrade={props?.isNeedUpgrade}
                  isPickUp={props?.isPickUp}
                  paymentData={paymentData}
                  setIsOnPayment={props?.setIsOnPayment}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
