import { useMemo } from 'react';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';

const useSteps = (
  steps: {
    cn: string;
    en: string;
  }[],
) => {
  const defaultFontStyle = useGetFontStyle();
  const isZhHKLanguage = useIsZhHKLanguage();

  return useMemo(() => {
    return steps.map((step) => ({
      title: (
        <span
          style={{
            fontFamily: defaultFontStyle,
            display: 'inline-block',
            maxWidth: '400px',
            whiteSpace: 'break-spaces',
            textAlign: 'center',
          }}
        >
          {isZhHKLanguage ? step.cn : step.en}
        </span>
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFontStyle, isZhHKLanguage, steps]);
};
export { useSteps };
