/* eslint-disable @typescript-eslint/indent */
// import React, { useEffect, useState } from 'react';
// import { useMemoizedFn } from 'ahooks';

import earth from '@images/greenEarth.svg';
import logo from '@images/INDICAIDLogo.png';
// import { message } from 'antd';
// import { get } from '@utils/request';
import { switchLanguage } from '@utils/index';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import { zhHKLanguage, enLanguage } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
// import { post } from '@utils/request';
// import PasswordAlert from './components/

import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import scan from '@images/scan.png';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useLocalNavigate } from 'src/router/routerTools';
import { Button, Divider } from 'antd';
import i18n from '@utils/i18n';

const style = {
  container: {
    background: '#f5f7fa',
  },
};

const Scan: React.FC = () => {
  const navigate = useLocalNavigate();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [t] = useTranslation();
  // TODO 实现export excel的功能
  const isZhHKLanguage = useIsZhHKLanguage();
  const collectionBottleId = useParams().collectionBottleId;

  const onClickLanguage = () => {
    const origin = isZhHKLanguage ? zhHKLanguage : enLanguage;
    // 切换为另外一个语言
    const target = isZhHKLanguage ? enLanguage : zhHKLanguage;
    switchLanguage(origin, target);
  };

  // TODO: 需要测试有没有成功添加数据
  const onClickScan: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    console.log('onClickScan');

    navigate(`/scanQRCode?collectionBottleId=${collectionBottleId}`);
    // post('/hpv/attachNewCilinic', {
    //   sample_id: collectionBottleId,
    //   new_clinic: 'alan_test_clinic',
    // })
    // .then(() => {
    //   // 跳转至成功页面

    // })
    // .catch((error) => {
    //   message.error(error.response.data.message);
    //   // console.log(e, 'e');
    //   // alert('TODO: Alan\n' + e.response.data.message);
    // });
  };

  const isEn = i18n.language === 'en';

  return (
    <div
      className="w-full h-[100vh] flex  bg-opacity-[0.8] flex-col py-[0] pl-[30px] pr-[20px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <div className="flex p-[42px_5px_28px_4px] lg:p-[42px_5px_0px_4px] justify-between items-center">
        <div className="w-[180px] lg:w-[180px]">
          <img src={logo} alt="logo" className="object-cover w-[180px] lg:w-[180px]  cursor-pointer" />
        </div>
        <div
          className="flex items-center justify-center cursor-pointer ml-[30px] lg:ml-[668px]"
          onClick={() => {
            onClickLanguage();
          }}
        >
          <div className="w-[24px]  lg:w-[29px]">
            <img src={earth} alt="earth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
          </div>
          {isLg && (
            <div className="lg:ml-[10px] font-[600] text-[#2DBDB6] lg:text-[18px] lg:leading-[18px]  text-center">
              {t('header.language')}
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center flex-col">
        <img
          src={scan}
          alt={'scan'}
          className="object-contain w-[225px] mt-[48px] mb-[31px]  lg:mt-[129px] lg:mb-[66px]"
        />
        <div className="font-[600] text-[22px] leading-[32px] text-[rgba(0,0,0,0.85)] mb-[9px]  lg:text-[28px] lg:leading-[32px] lg:mb-[9px]">
          {t('scan.title')}
        </div>
        <div className="font-[500] text-[18px] leading-[28px] text-[rgba(0,0,0,0.85)] mb-[28px] text-center w-[79.2vw]  lg:text-[18px] lg:leading-[28px]  lg:mb-[23px]">
          {t('scan.description')}
        </div>
        <div className="font-[500] text-[14px] leading-[22px] text-[rgba(0,0,0,0.45)] mb-[42px] text-center  lg:text-[14px] lg:leading-[22px] lg:mb-[22px]">
          {`${t('scan.SampleID')}: ${collectionBottleId}`}
          <br />
          {`${t('scan.Date')}: ${dayjs().format('YYYY/MM/DD hh:mma')}`}
        </div>
        <div className="mb-[107px] md:mb-[341px] md:w-[364px] w-full">
          <Button type="primary" size="large" className="w-full" onClick={onClickScan}>
            {t('scan.Scan')}
          </Button>
          <Divider
            style={{
              margin: 0,
              marginTop: '10px',
            }}
          >
            <span className="text-[#706F6F] font-light">{isEn ? 'Or' : '或'}</span>
          </Divider>
          <Button
            onClick={() => {
              navigate(`/manual/${collectionBottleId}`);
            }}
            size="large"
            className="w-full"
            type="default"
          >
            <span className="text-[#2DBDB6]">{isEn ? 'Select your drop-off address' : '選擇您的送件地點'}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Scan;
