/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useRef, useState } from 'react';
// import { default as RenamedCaptcha } from '@utils/captcha';
import HCaptcha from '@hcaptcha/react-hcaptcha';
// TODO 需要做的需求
// ①loading状态
// ②获取校验码，=>校验成功之后，跳转至人机校验页面
// ③看看如何进行人机校验
const style = {
  container: {
    background: '#FDFDFD',
  },
};

const CheckCode: React.FC = () => {
  const [token, setToken] = useState<any>(null);
  const [email, setEmail] = useState('');
  const captchaRef = useRef<HCaptcha>(null);

  const onSubmit = () => {
    // this reaches out to the hcaptcha library and runs the
    // execute function on it. you can use other functions as well
    // documented in the api:
    // https://docs.hcaptcha.com/configuration#jsapi
    // captchaRef?.current?.execute &&
    captchaRef?.current?.execute();
    // console.log(captchaRef?.current, 'captchaRef?.current');
  };

  const onExpire = () => {
    console.log('hCaptcha Token Expired');
  };

  const onError = (err: any) => {
    console.log(`hCaptcha Error: ${err}`);
  };

  useEffect(() => {
    if (token) {
      // Token is set, can submit here
      console.log(`User Email: ${email}`);
      console.log(`hCaptcha Token: ${token}`);
    }
  }, [token, email]);

  useEffect(() => {
    if (token) {
      // Token is set, can submit here
      console.log(`User Email: ${email}`);
      console.log(`hCaptcha Token: ${token}`);
    }
  }, [token, email]);

  useEffect(() => {
    setTimeout(() => {
      const sbtitle = document.getElementById('warning');
      console.log(sbtitle, 'sbtitle');
      if (sbtitle) {
        sbtitle.style.visibility = 'hidden';
      }
    }, 2000);
  }, [token, email, captchaRef]);

  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      我是checkCode
      <form>
        <input type="email" value={email} placeholder="Email adddress" onChange={(evt) => setEmail(evt.target.value)} />
        <button onClick={onSubmit}>Submit</button>
        <HCaptcha
          // This is testing sitekey, will autopass
          // Make sure to replace
          // 0xf60DC28b9c9CA6d0c870852fc11eD34071F751B3
          // sitekey="0xf60DC28b9c9CA6d0c870852fc11eD34071F751B3"
          sitekey="10000000-ffff-ffff-ffff-000000000001"
          onVerify={setToken}
          onError={onError}
          onExpire={onExpire}
          ref={captchaRef}
          languageOverride="en"
        />
        {/* <RenamedCaptcha
          // This is testing sitekey, will autopass
          // Make sure to replace
          sitekey="10000000-ffff-ffff-ffff-000000000001"
          onVerify={setToken}
          onError={onError}
          onExpire={onExpire}
          ref={captchaRef}
        /> */}
        {token && <div>Success! Token: {token}</div>}
      </form>
    </div>
  );
};

export default CheckCode;
