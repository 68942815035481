import BaseButton from '@components/BaseButton/BaseButton';
import TextInput from '@components/TextInput/TextInput';
import { get, post } from '@utils/request';
import { Select } from 'antd';
import { useState } from 'react';

export default function ResetExportExcelPassword() {
  const sendOtp = () => {
    get('/hpv/sendOtpToResetSecret');
  };
  const [value, setValue] = useState('1');
  const [otp, setOtp] = useState('');
  const resetPassword = () => {
    post('/hpv/resetSecret', {
      otp,
      type: value,
    });
  };

  console.log(value, 'value');

  return (
    <div className="m-auto flex flex-col h-[200px] justify-around w-[500px]">
      <div className="flex items-center">
        <TextInput
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
          }}
          placeholder="input otp"
        />
        <BaseButton onClick={sendOtp} sectionClassName="ml-[30px]" value="send OTP" />
      </div>
      <Select
        value={value}
        onChange={setValue}
        options={[
          { label: 'Customer Service', value: '1' },
          { label: 'Logistics', value: '2' },
        ]}
      />
      <BaseButton onClick={resetPassword} value="submit" />
    </div>
  );
}
