import { Outlet } from 'react-router-dom';
// import SideBar from '@components/DoctorComponents/SideBar/SideBar';
import { useRouterChangeAutoScrollTop } from './hooks/tools/RouterAutoScrollTop';
import './CNApp.css';
function CNApp() {
  useRouterChangeAutoScrollTop();
  return (
    //
    <div className="flex cn-app">
      {/* <SideBar /> */}
      <Outlet />
    </div>
  );
}

export default CNApp;
