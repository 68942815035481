import { MEDIA_SIZE } from '@consts/consts';
import CollectionBootleButton, { ButtonStatus } from '@pages/CollectionBootle/components/CollectionBootleButton';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';

import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useAddCheckValidFunc, usePageContext } from './BaseComponent/formContext';
import pageComponentMap, {
  BasePageComponentProps,
  PageComponentConfig,
  PageComponentConfigMap,
} from './BaseComponent/PageComponentMap';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import classNames from 'classnames';
import BaseTextArea from '@pages/CollectionBootle/components/BaseTextArea';
type ButtonSelectProps = BasePageComponentProps & PageComponentConfigMap['buttonSelect'];

const numAlpha = 'abcdefghijklmnopqrstuvwxyz';

export default function ButtonSelect(props: ButtonSelectProps) {
  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const isZhHKLanguage = useIsZhHKLanguage();

  const [isError, setIsError] = useState(false);

  const { pageValues, setPageValue } = usePageContext();

  const isLarge = props.isLarge;
  let maxSelect = props.maxSelect;
  let minSelect = props.minSelect;

  if ((!maxSelect && minSelect) || (!minSelect && maxSelect)) {
    throw new Error('minSelect and maxSelect must be set together');
  }
  if (!maxSelect) {
    maxSelect = 1;
  }
  if (!minSelect) {
    minSelect = 1;
  }

  const [extraInfo, setExtraInfo] = useState<{
    [key: string]: string;
  }>({});

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [components, setComponents] = useState<PageComponentConfig[] | undefined>(undefined);

  const value = pageValues[props.title]?.value;

  useEffect(() => {
    if (value) {
      try {
        const valueJson = JSON.parse(value);
        const _selectItems = Object.keys(valueJson);

        const _components = props.configs.find((c) => _selectItems.includes(c.value))?.components;
        setComponents(_components);

        setSelectedItems(_selectItems);
        setExtraInfo(valueJson);
      } catch (e) {
        console.error(e);
      }
    }
  }, [props.configs, value]);

  const clearChildrenComponentsValue = (_components: PageComponentConfig[]) => {
    _components.forEach((item) => {
      setPageValue(item.title, '');
      if (item.type === 'buttonSelect') {
        item.configs.forEach((c) => {
          if (c.components) {
            clearChildrenComponentsValue(c.components);
          }
        });
      }
    });
  };

  const onValueChange = (v: string, _components: PageComponentConfig[], needTextInput?: boolean) => {
    const prev = selectedItems;
    let isRemove = prev.includes(v);

    let newSelectItems: string[] = [];
    if (isRemove) {
      newSelectItems = prev.filter((item) => item !== v);
      setComponents([]);
      clearChildrenComponentsValue(_components);
    } else if (prev.length >= (maxSelect || 1)) {
      const removeValue = prev.shift();
      const needRemoveComponents = props.configs.find((c) => c.value === removeValue)?.components;
      if (needRemoveComponents) {
        clearChildrenComponentsValue(needRemoveComponents);
      }

      newSelectItems = [...prev, v];
      setComponents(_components);
    } else {
      newSelectItems = [...prev, v];
      setComponents(_components);
    }

    let _extraInfo = {
      [v]: needTextInput ? '' : v,
    };

    if (isRemove) {
      _extraInfo = {};
    }

    const prevExtraInfo = { ...extraInfo };

    Object.keys(prevExtraInfo).forEach((prevKey) => {
      if (!newSelectItems.includes(prevKey)) {
        delete prevExtraInfo[prevKey];
      }
    });

    const newExtraInfo = {
      ...prevExtraInfo,
      ..._extraInfo,
    };

    setExtraInfo(newExtraInfo);
    setPageValue(props.title, JSON.stringify(newExtraInfo));
    setSelectedItems(newSelectItems);
  };

  const [errorTextInner, setErrorTextInner] = useState('');

  useAddCheckValidFunc(() => {
    if (JSON.stringify(extraInfo) === '{}') {
      setIsError(true);
      setErrorTextInner(t('collectionBootle.fieldsRequiredMessage') as string);
      return false;
    }

    if (minSelect && selectedItems.length < minSelect) {
      setIsError(true);
      if (isZhHKLanguage) {
        setErrorTextInner(`至少需要選擇${minSelect}個選項`);
      } else {
        setErrorTextInner(`needs to select at least ${minSelect} options`);
      }
      return false;
    }

    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i];
      if (props.configs.find((c) => c.value === item)?.needTextInput) {
        if (!extraInfo[item]) {
          setIsError(true);
          if (isZhHKLanguage) {
            setErrorTextInner(`${item}需要填寫`);
          } else {
            setErrorTextInner(`${item} needs to fill in`);
          }

          return false;
        }
      }
    }

    setIsError(false);

    return true;
  });

  const errorText = useMemo(() => {
    if (!isError) {
      return '';
    }
    let etxt = isZhHKLanguage ? props.errorTextCn : props.errorText;
    return etxt ? etxt : errorTextInner;
  }, [errorTextInner, isError, isZhHKLanguage, props.errorText, props.errorTextCn]);

  const renderComponents = () => {
    const slectedIdx = props.configs.findIndex((c) => c.components === components);

    return components?.map((item, idx) => {
      const Component = pageComponentMap[item.type as 'email'];
      if (Component) {
        return (
          <Component
            No={props.No ? `${props.No}_${numAlpha[slectedIdx] || 'a'}` : `${idx + 1}`}
            key={item.title}
            {...item}
          />
        );
      }
      return null;
    });
  };
  return (
    <>
      <div className="flex flex-col w-[100%] mt-[29px]  lg:mt-[29px]">
        <FiledTitle title={props.No + '. ' + (isZhHKLanguage ? props.titleCn : props.title)} />
        {props.configs.map((config) => {
          let buttonStatus = selectedItems.includes(config.value) ? 'check' : 'unchecked';
          if (isError && buttonStatus === 'unchecked') {
            buttonStatus = 'emptyError';
          }

          const isSelect = selectedItems.includes(config.value);

          return (
            <Fragment key={config.value}>
              <CollectionBootleButton
                value={isZhHKLanguage ? config.labelCn : config.label}
                status={buttonStatus as ButtonStatus}
                htmlType="submit"
                style={{
                  height: isLg ? '1.9024rem' : 'auto',
                  minHeight: isLg ? '90px' : '1.9024rem',
                  whiteSpace: 'pre-wrap',
                }}
                buttonClassName={classNames(
                  'w-full mb-[18px] md:w-[30vw] md:text-[14px] md:mt-[0px] md:mb-[24px]',
                  isLarge ? '' : 'h-[38px] md:h-[38px]',
                )}
                onClick={() => {
                  setIsError(false);

                  if (config.needTextInput) {
                    setSelectedItems((prev) => {
                      if (prev.includes(config.value)) {
                        setComponents([]);
                        return prev.filter((item) => item !== config.value);
                      }
                      setComponents(config.components || []);

                      return [...prev, config.value];
                    });
                  } else {
                    onValueChange(config.value, config.components || [], config.needTextInput);
                  }
                }}
              />

              {isSelect && config.needTextInput && (
                <>
                  <div className="font-[400] text-[#25282B] text-[14px] leading-[28px] tracking-[0.4px]  mb-[6px]  lg:text-[14px] lg:leading-[20px] lg:mt-[0px]  lg:mb-[12px]">
                    請指定
                  </div>

                  <BaseTextArea
                    textAreaClassName="w-full mb-[18px] md:w-[26.66vw] md:text-[14px] md:mt-[0px] md:mb-[24px]"
                    defaultValue={extraInfo[config.value]}
                    onChange={(e) => {
                      const prevExtraInfo = {
                        ...extraInfo,
                      };
                      const newExtraInfo = {
                        ...prevExtraInfo,
                        [config.value]: e.target.value,
                      };

                      setExtraInfo(newExtraInfo);
                      setPageValue(props.title, JSON.stringify(newExtraInfo));
                    }}
                    collectionDatePickerClassName={isError ? 'my-select-selector-empty-type' : undefined}
                  ></BaseTextArea>
                </>
              )}
            </Fragment>
          );
        })}
        {isError && (
          <MessageText
            helpText={errorText as string}
            status={isError ? 'error' : undefined}
            style={{
              marginTop: isLg ? '11px' : '0.2683rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
      </div>
      {renderComponents()}
    </>
  );
}
