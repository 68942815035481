import TextInput, { TextInputStatus } from '@components/TextInput/TextInput';

import { useEffect, useState } from 'react';
import { CSSProperties } from 'react';
import { Popover } from 'antd';
import './filedInput.css';

export type CnFiledInputPropsOnCheckValidType = (
  v: string,
  option: {
    setStatus: React.Dispatch<React.SetStateAction<TextInputStatus>>;
    setHelpText: React.Dispatch<React.SetStateAction<string>>;
  },
  isSubmitCheck?: boolean,
  isOtpSubmitCheck?: boolean,
) => void;

export type CnFiledInputProps = {
  title?: string;
  placeholder: string;
  value: string;
  onChange?: (v: string) => void;
  /**
   * 检查数据合法性
   * @param v 值
   * @param option
   * @returns
   */
  onCheckValid?: CnFiledInputPropsOnCheckValidType;
  isSubmitCheck?: boolean;
  isOtpSubmitCheck?: boolean;
  filedInputClassName?: string;
  defaultValue?: string;
  forceUppercase?: boolean;
  showPop?: boolean;
  formName?: string;
  clearNumber?: number;
  isFullName?: boolean;
  style?: CSSProperties;
};

export function FiledTitle(props: { title: string; style?: CSSProperties }) {
  return (
    <p
      className="font-[600] text-[14px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[16px] flex lg:leading-[20px] lg:tracking-[0.4px] "
      style={props?.style}
    >
      {props.title}
      {/* <span className="font-[600] text-[#FF0000]">*</span> */}
    </p>
  );
}

export default function CnFiledInput(props: CnFiledInputProps) {
  const { value, onCheckValid, isSubmitCheck, isOtpSubmitCheck } = props;

  const [helpText, setHelpText] = useState('');
  const [status, setStatus] = useState<TextInputStatus | undefined>();

  useEffect(() => {
    if (isOtpSubmitCheck) {
      onCheckValid?.(
        value,
        {
          setHelpText,
          setStatus,
        },
        isSubmitCheck,
        isOtpSubmitCheck,
      );
    } else {
      onCheckValid?.(
        value,
        {
          setHelpText,
          setStatus,
        },
        isSubmitCheck,
      );
    }
  }, [value, onCheckValid, isSubmitCheck, isOtpSubmitCheck]);

  const renderContent = () => {
    return (
      <div
        className={`lg:w-[100%] m-auto w-[100%] ${props?.filedInputClassName}`}
        style={{
          ...props?.style,
        }}
      >
        {props.title && <FiledTitle title={props.title} />}
        <TextInput
          value={props.value}
          status={status}
          placeholder={props.placeholder}
          formName={props.formName}
          clearNumber={props.clearNumber}
          // text-[#8B849B]
          // TODO 字体大小不对，需要再看一下
          inputClassName={
            'h-[48px] text-[14px] leading-[24px] tracking-[0.2px] text-[400] lg:text-[14px] lg:leading-[24px]  lg:w-[100%] md:h-[48px] lg:h-[48px] lg: tracking-[0.2px]'
          }
          onChange={(e) => props.onChange?.(e.target.value)}
          helpText={helpText}
          defaultValue={props?.defaultValue}
          forceUppercase={props?.forceUppercase}
          isFullName={props?.isFullName}
        ></TextInput>
      </div>
    );
  };

  return props?.showPop ? (
    <Popover
      // trigger="click"
      content={
        // lg:leading-[20px]
        <p className="font-[600] text-[14px] text-[#8B849B] leading-[20px] tracking-[0.4px] lg:text-[14px] lg:leading-[20px] flex  lg:tracking-[0.4px] justify-center items-center">
          {props.placeholder}
        </p>
      }
    >
      {renderContent()}
    </Popover>
  ) : (
    renderContent()
  );
}
