import { useMemoizedFn } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FiledInput from './BaseComponent/FiledInput';
import { useAddCheckValidFunc } from './BaseComponent/formContext';
import MessageText from '@pages/CollectionBootle/components/MessageText';

export default function FullNameInput() {
  const [isError, setIsError] = useState(false);

  const [t] = useTranslation();
  const [fullName, setFulltName] = useState('');

  const checkValid = useMemoizedFn(() => {
    if (fullName.trim()) {
      return true;
    }
    setIsError(true);
    return false;
  });

  useAddCheckValidFunc(checkValid);

  return (
    <>
      <FiledInput
        value={fullName}
        onChange={(v: string) => {
          setFulltName(v);
        }}
        title={t('collectionBootle.question15')}
        placeholder={t('collectionBootle.question15Placeholder')}
        filedInputClassName="mt-[29px] lg:mt-[29px]"
        forceUppercase={true}
        status={isError ? 'error' : undefined}
      />
      {isError && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={'error'}
          style={{
            marginTop: '5px',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
    </>
  );
}
