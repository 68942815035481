import { read, utils, writeFile } from 'xlsx';

export async function readXlsx<TData>(file: File) {
  const ab = await file.arrayBuffer();
  const workbook = read(ab);
  const ws = workbook.Sheets[workbook.SheetNames[0]];
  const data = utils.sheet_to_json<TData>(ws);

  return data;
}

export async function writeXlsx<TData>(data: TData[], filename: string) {
  const ws = utils.json_to_sheet(data);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Sheet1');
  writeFile(wb, filename, { compression: true });
}

export function generateDemoFile<TData>(data: TData[], filename: string) {
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet([data]);
  utils.book_append_sheet(wb, ws, 'Sheet1');
  writeFile(wb, filename, { compression: true });
}

export function writeXlsxWithArray<TData>(data: TData[], filename: string) {
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet([data]);
  utils.book_append_sheet(wb, ws, 'Sheet1');
  writeFile(wb, filename, { compression: true });
}

export async function readXlsxToArray(file: File) {
  const ab = await file.arrayBuffer();
  const workbook = read(ab);
  const ws = workbook.Sheets[workbook.SheetNames[0]];
  const data = utils.sheet_to_json<Array<string>>(ws, { header: 1 });

  return data;
}
