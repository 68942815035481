import { TextInputStatus } from '@components/TextInput/TextInput';
// import type { DatePickerProps } from 'antd';
// import { DatePicker } from 'antd';
// import { Input, Form } from 'antd';
import { Input } from 'antd';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';
import './baseTextArea.css';

// import { Dayjs } from 'dayjs';
// import './collectionDatePicker.css';
// import dayjs from 'dayjs';

// import { useEffect, useState } from 'react';

export type BaseTextAreaPropsOnCheckValidType = (
  v: string,
  option: {
    setStatus: React.Dispatch<React.SetStateAction<TextInputStatus>>;
    setHelpText: React.Dispatch<React.SetStateAction<string>>;
  },
  isSubmitCheck?: boolean,
) => void;

export type BaseTextAreaProps = {
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  /**
   * 检查数据合法性
   * @param v 值
   * @param option
   * @returns
   */
  onCheckValid?: BaseTextAreaPropsOnCheckValidType;
  isSubmitCheck?: boolean;
  collectionDatePickerClassName?: string;
  defaultValue?: string;
  disable?: boolean;
  textAreaClassName?: string;
};

const { TextArea } = Input;

export default function BaseTextArea(props: BaseTextAreaProps) {
  const mediaSize = useMediaQuery();
  const defaultFontStyle = useGetFontStyle();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  return (
    <div className={`lg:w-[100%] m-auto w-[100%] flex flex-col ${props?.collectionDatePickerClassName}`}>
      {/* <Form form={form}>
            <Form.Item name="textarea"> */}
      <TextArea
        className={props?.textAreaClassName}
        placeholder={props?.placeholder}
        onChange={props?.onChange}
        defaultValue={props?.defaultValue}
        // TODO 需要找zach确认
        autoSize={{ minRows: 6, maxRows: 7 }}
        style={{
          // 修改样式
          borderColor: '#B5B3BF',
          fontFamily: defaultFontStyle,
          color: '#52575C',
          backgroundColor: props?.disable ? '#FFFFFF' : undefined,
          fontWeight: '400',
          fontSize: isLg ? '14px' : '0.3902rem',
          lineHeight: isLg ? '22px' : '0.6829rem',
        }}
        allowClear
        disabled={props?.disable}
        // ref={ref}
      />
    </div>
  );
}
