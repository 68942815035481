import { useMemoizedFn } from 'ahooks';
import React, { useEffect, useState } from 'react';
import { PageConfig } from './ConfigPage.type';
import { PageComponentConfig, PageComponentType } from './PageComponentMap';
import { useParams } from 'react-router-dom';
import { useLocalNavigate } from 'src/router/routerTools';
import { getLocalPageValues, setLocalPageValues } from './LocalPageValues';

export type CheckValidFunc = () => boolean | Promise<boolean>;
type CancelFunc = () => void;
const defaultCtxValue = {
  compaginName: '',
  checkValidFuncs: [],
  addCheckValidFunc: () => {
    return () => {};
  },
  pageConfig: [],
  pageValues: {},
  setPageValue: () => {},
};
type PageValue = {
  title: string;
  titleCn: string;
  value: string;
  sortIndex: number;
};
export const createStepContext = () => {
  return React.createContext<{
    compaginName: string;
    checkValidFuncs: CheckValidFunc[];
    addCheckValidFunc: (func: CheckValidFunc) => CancelFunc;
    pageConfig: PageConfig[];
    pageValues: Record<string, PageValue>;
    setPageValue: (title: string, value: string) => void;
  }>(defaultCtxValue);
};

export const pageContext = createStepContext();
export const usePageContext = () => React.useContext(pageContext);

export const useAddCheckValidFunc = (func: () => boolean | Promise<boolean>) => {
  const { addCheckValidFunc } = usePageContext();
  const memoFunc = useMemoizedFn(func);
  useEffect(() => {
    return addCheckValidFunc(memoFunc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const createPageValues = (pageConfig: PageConfig[]) => {
  const valueObj: Record<string, PageValue> = {};

  function readComponets(components: PageConfig['components'], parentIdx: number) {
    components.forEach((component, idx) => {
      idx = idx + 1;
      if (
        component.type === PageComponentType.IDNUMBER ||
        component.type === PageComponentType.SAMPLE_COLLECTION_TIME
      ) {
        component.titleConfigs.forEach((titleConfig) => {
          valueObj[titleConfig.title] = {
            title: titleConfig.title,
            titleCn: titleConfig.titleCn,
            value: '',
            sortIndex: parentIdx + idx / 10,
          };
        });
      } else if (component.type === PageComponentType.FEEDBACKSELECT) {
        component.feedbackConfigs.forEach((feedbackConfig) => {
          feedbackConfig.items.forEach((item) => {
            const key = `${feedbackConfig.title}(${item.label})`;
            const keyCn = `${feedbackConfig.titleCn}(${item.labelCn})`;
            valueObj[key] = {
              title: key,
              titleCn: keyCn,
              value: '',
              sortIndex: parentIdx + idx / 10,
            };
          });
        });
      } else {
        valueObj[component.title] = {
          title: component.title,
          titleCn: component.titleCn,
          value: '',
          sortIndex: parentIdx + idx / 10,
        };

        if (component.type === PageComponentType.BUTTONSELECT) {
          component.configs.forEach((c) => {
            if (c.components) {
              readComponets(c.components, idx);
            }
          });
        }
      }
    });
  }

  pageConfig.forEach((page, idx) => {
    readComponets(page.components, idx + 1);
  });

  return valueObj;
};

export const createPageComponent = () => {
  const checkValidFuncs: CheckValidFunc[] = [];
  const sampleId = window.location.pathname.split('/').at(-1);
  if (!sampleId) {
    throw new Error('sampleId is not found');
  }
  const pageValuesLocal = getLocalPageValues(sampleId);
  let initialPageValues: Record<string, PageValue> = {};
  if (pageValuesLocal) {
    try {
      initialPageValues = JSON.parse(pageValuesLocal);
    } catch (e) {
      // do nothing
      console.error(e);
    }
  }

  return function PageComponent(props: { children: React.ReactNode; compaginName: string; pageConfig: PageConfig[] }) {
    const addCheckValidFunc = useMemoizedFn((func: CheckValidFunc) => {
      checkValidFuncs.push(func);
      return () => {
        const index = checkValidFuncs.indexOf(func);
        if (index > -1) {
          checkValidFuncs.splice(index, 1);
        }
      };
    });

    const [pageValues, setPageValues] = useState<Record<string, PageValue>>(initialPageValues);
    const { collectionBottleId } = useParams();

    if (!collectionBottleId) {
      throw new Error('collectionBottleId is not found');
    }

    useEffect(() => {
      if (Object.keys(initialPageValues).length === 0) {
        const _pageValues = createPageValues(props.pageConfig);
        setPageValues(_pageValues);
        setLocalPageValues(collectionBottleId, _pageValues);
      }
    }, [collectionBottleId, props.pageConfig]);

    const setPageValue = useMemoizedFn((title: string, value: string) => {
      setPageValues((prev) => {
        const newPageValue = {
          ...prev,
          [title]: {
            ...prev[title],
            value,
          },
        };
        setLocalPageValues(collectionBottleId, newPageValue);
        return newPageValue;
      });
    });

    return (
      <pageContext.Provider
        value={{
          compaginName: props.compaginName,
          checkValidFuncs,
          addCheckValidFunc,
          pageConfig: props.pageConfig,
          pageValues,
          setPageValue,
        }}
      >
        {props.children}
      </pageContext.Provider>
    );
  };
};

export function useCheckLastStepDataValid() {
  const { compaginId, collectionBottleId, step } = useParams();

  if (!collectionBottleId || !compaginId || !step) {
    throw new Error('collectionBottleId, compaginId, step is not found');
  }

  const { pageConfig } = usePageContext();
  const navigete = useLocalNavigate();

  useEffect(() => {
    if (step === '1') {
      return;
    } else {
      const localPageValues = getLocalPageValues(collectionBottleId);
      if (!localPageValues) {
        return;
      }

      const pageValues = JSON.parse(localPageValues);

      const stepIndex = Number(step) - 2;
      const stepConfig = pageConfig[stepIndex];
      const goLastStep = () => {
        navigete(`/compagin/${compaginId}/${stepIndex + 1}/${collectionBottleId}`, {
          replace: true,
        });
      };

      const checkComponentsValid = (components: PageComponentConfig[]) => {
        for (let i = 0; i < components.length; i++) {
          const component = components[i];
          if (component.required === false) {
            continue;
          }

          if (
            component.type === PageComponentType.IDNUMBER ||
            component.type === PageComponentType.SAMPLE_COLLECTION_TIME
          ) {
            component.titleConfigs.forEach((titleConfig) => {
              if (!pageValues[titleConfig.title]?.value) {
                goLastStep();
                return;
              }
            });
          } else if (component.type === PageComponentType.FEEDBACKSELECT) {
            component.feedbackConfigs.forEach((feedbackConfig) => {
              feedbackConfig.items.forEach((item) => {
                const key = `${feedbackConfig.title}(${item.label})`;
                if (!pageValues[key]?.value) {
                  goLastStep();
                  return;
                }
              });
            });
          } else {
            if (!pageValues[component.title]?.value) {
              goLastStep();
              return;
            }

            if (component.type === PageComponentType.BUTTONSELECT) {
              component.configs.forEach((c) => {
                if (c.value === pageValues[component.title]?.value) {
                  if (c.components) {
                    checkComponentsValid(c.components);
                  }
                }
              });
            }
          }
        }
      };
      checkComponentsValid(stepConfig.components);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);
}
