/* eslint-disable @typescript-eslint/indent */

import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';
import CNApp from 'src/CNApp';
import App from 'src/App';
import CheckCode from '@pages/CheckCode/CheckCode';
// import CollectionBootle from '@pages/CollectionBootle/CollectionBootle';
import CollectionBootle from '@pages/CollectionBootle/CollectionBootle';
import Agreements from '@pages/Agreements/Agreements';
import ExportExcel from '@pages/ExportExcel/ExportExcel';
import ExportLogisticExcel from '@pages/ExportLogisticExcel/ExportLogisticExcel';
import ExportDoctorExcel from '@pages/ExportDoctorExcel/ExportDoctorExcel';
import ExportDoctorExcelSuccess from '@pages/ExportDoctorExcelSuccess/ExportDoctorExcelSuccess';

import CollectionSuccess from '@pages/CollectionSuccess/CollectionSuccess';
import Scan from '@pages/Scan/Scan';
import ScanSuccess from '@pages/ScanSuccess/ScanSuccess';
import PaymentSuccessSubmit from '@pages/PaymentSuccessSubmit/PaymentSuccessSubmit';

import CNCollectionBootle from '@pages/CN/CollectionBootle/CollectionBootle';
import CNCollectionSuccess from '@pages/CN/CollectionSuccess/CollectionSuccess';
import CNExportExcel from '@pages/CN/ExportExcel/ExportExcel';

import i18n from '@utils/i18n';
import { getLocale, LanguageContext } from './routerTools';
import { useEffect } from 'react';
import ScanQRCode from '@pages/ScanQRCode/ScanQRCode';
import ScanSuccessInput from '@pages/ScanSuccessInput/ScanSuccessInput';
import ResetExportExcelPassword from '@pages/ResetExportExcelPassword/ResetExportExcelPassword';
import ManualDropOff from '@pages/ManualDroppOff/ManualDroppOff';
import ExportAgreeExcel from '@pages/ExportExcel/ExportCustomerAgreeExcel';
import ProductExpired from '@pages/productExpired/productExpired';
import ViewMeiTuanDataList from '@pages/ExportDoctorExcel/ViewMetuanDataList';
import AddMeituanData from '@pages/ExportDoctorExcel/AddMetuanDataList';
import T1015 from '@components/ConfigPage/compagins/0749';
import CompaginCollectionSuccess from '@components/ConfigPage/CollectionSuccess';

// TODO: 斜线需要再看一下=>看一下有没有bug
const AllRoutes: RouteObject[] = [
  {
    path: 'cn',
    element: <CNApp />,
    errorElement: <div>error....</div>,
    children: [
      {
        path: 'collectionBottle/:collectionBottleId',
        element: <CNCollectionBootle />,
      },
      {
        path: 'collectionSuccess',
        element: <CNCollectionSuccess />,
      },
      {
        path: 'exportExcel',
        element: <CNExportExcel />,
      },
    ],
  },
  {
    path: '',
    element: <App />,
    errorElement: <div>error....</div>,
    children: [
      {
        path: 'checkCode',
        element: <CheckCode />,
      },
      {
        path: 'collectionBottle',
        element: <CollectionBootle />,
      },
      {
        path: 'agreements/:collectionBootleId',
        element: <Agreements />,
      },
      {
        path: 'build/:collectionBootleId',
        element: <Agreements />,
      },
      {
        path: 'exportExcel',
        element: <ExportExcel />,
      },
      {
        path: 'exportAgreeExcel',
        element: <ExportAgreeExcel />,
      },
      {
        path: 'exportLogisticExcel',
        element: <ExportLogisticExcel />,
      },
      {
        path: 'exportDoctorExcel',
        element: <ExportDoctorExcel />,
      },
      {
        path: 'exportDoctorExcelSuccess',
        element: <ExportDoctorExcelSuccess />,
      },
      {
        path: 'collectionSuccess',
        element: <CollectionSuccess />,
      },
      {
        path: 'scan/:collectionBottleId',
        element: <Scan />,
      },
      {
        path: 'manual/:collectionBottleId',
        element: <ManualDropOff />,
      },
      {
        path: 'scanSuccess/:collectionBottleId',
        element: <ScanSuccess />,
      },
      {
        path: 'scanQRCode',
        element: <ScanQRCode />,
      },
      {
        path: 'ScanSuccessInput',
        element: <ScanSuccessInput />,
      },
      {
        path: 'ResetExportExcelPassword',
        element: <ResetExportExcelPassword />,
      },
      {
        path: 'paymentSuccessSubmit',
        element: <PaymentSuccessSubmit />,
      },
      {
        path: 'productExpired',
        element: <ProductExpired />,
      },
      {
        path: 'viewMeituanData',
        element: <ViewMeiTuanDataList />,
      },
      {
        path: 'addMeituanData',
        element: <AddMeituanData />,
      },
      {
        path: 'compagin/:compaginId/:step/:collectionBottleId',
        element: <T1015 />,
      },
      {
        path: 'submitsuccess/:collectionBottleId',
        element: <CompaginCollectionSuccess />,
      },
    ],
  },
];

const EnLanguageComponentWrapper = () => {
  useEffect(() => {
    i18n.changeLanguage('en');
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};
const ZhHKLanguageComponentWrapper = () => {
  useEffect(() => {
    i18n.changeLanguage('zh-HK');
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};
const ZhCNLanguageComponentWrapper = () => {
  useEffect(() => {
    i18n.changeLanguage('zh-CN');
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};
const router = createBrowserRouter([
  {
    path: '/',
    children: AllRoutes,
    element: (
      <LanguageContext.Provider value={getLocale().locale}>
        <Outlet />
      </LanguageContext.Provider>
    ),
  },
  {
    path: '/zh-HK',
    children: AllRoutes,
    element: (
      <LanguageContext.Provider value="zh-HK">
        <ZhHKLanguageComponentWrapper />
      </LanguageContext.Provider>
    ),
  },
  {
    path: '/en',
    children: AllRoutes,
    element: (
      <LanguageContext.Provider value="en">
        <EnLanguageComponentWrapper />
      </LanguageContext.Provider>
    ),
  },
  {
    path: '/zh-CN',
    children: AllRoutes,
    element: (
      <LanguageContext.Provider value="zh-CN">
        <ZhCNLanguageComponentWrapper />
      </LanguageContext.Provider>
    ),
  },
]);

export default router;
