import { CompaginConfig } from '../BaseComponent/ConfigPage.type';
import headerImg from '@images/HKU_O&G_faculty-logo.png';

const pageConfigs = [
  {
    name: 'Personal Information',
    nameCn: '個人資料',
    components: [
      {
        type: 'lastName',
        title: 'Last Name',
        titleCn: '姓',
      },
      {
        type: 'firstName',
        title: 'First Name',
        titleCn: '名',
        value: '',
      },
      {
        type: 'phone',
        title: 'Phone',
        titleCn: '電話',
      },
      {
        type: 'email',
        title: 'Email',
        titleCn: '電郵',
        required: false,
      },
      {
        type: 'buttonSelect',
        title: 'Gender',
        titleCn: '性別',
        configs: [
          {
            value: 'Female',
            label: 'Female',
            labelCn: '女',
          },
          {
            value: 'Male',
            label: 'Male',
            labelCn: '男',
          },
        ],
      },
      {
        type: 'birthday',
        title: 'Birthday',
        titleCn: '生日',
      },
      {
        type: 'idNumber',
        title: 'ID Number',
        titleCn: '身份證號碼',
        titleConfigs: [
          {
            title: 'Identification Document Type',
            titleCn: '身份證件類型',
          },
          {
            title: 'Identification Document No.',
            titleCn: '證件號碼',
          },
        ],
      },
    ],
  },
  {
    name: 'HPV vaccination and screening history',
    nameCn: 'HPV疫苗接種和篩查史',
    components: [
      {
        type: 'buttonSelect',
        title: 'Have you had HPV vaccination?',
        titleCn: '您是否接種過HPV疫苗？',
        configs: [
          {
            value: 'Yes',
            label: 'Yes',
            labelCn: '有',
            components: [
              {
                type: 'buttonSelect',
                title: 'Type of HPV vaccine',
                titleCn: 'HPV疫苗的種類',
                configs: [
                  {
                    value: '2vHPV (Cervarix)',
                    label: '2vHPV (Cervarix)',
                    labelCn: '二價疫苗 (卉妍康) ',
                  },
                  {
                    value: '4vHPV ( Gardasil )',
                    label: '4vHPV ( Gardasil )',
                    labelCn: '四價疫苗 (4合1加衛苗)',
                  },
                  {
                    value: '9vHPV ( Gardasil )',
                    label: '9vHPV ( Gardasil )',
                    labelCn: '九價疫苗 (9合1加衛苗)',
                  },
                  {
                    value: 'Unknown',
                    label: 'Unknown',
                    labelCn: '不清楚',
                  },
                ],
              },
            ],
          },
          {
            value: 'No',
            label: 'No',
            labelCn: '没有',
          },
        ],
      },
      {
        type: 'buttonSelect',
        title:
          "When was the most recent time you had a Pap smear, excluding the one conducted in last year's clinical study?",
        titleCn: '除了去年臨床研究中進行的檢測外，你最近一次做抹片檢查是什麼時候？',

        configs: [
          {
            value: 'Never',
            label: 'Never',
            labelCn: '從來沒有',
          },
          {
            value: 'Less than 1 year ago',
            label: 'Less than 1 year ago',
            labelCn: '少於一年',
          },
          {
            value: 'Less than 3 years ago',
            label: 'Less than 3 years ago',
            labelCn: '少於三年',
          },
          {
            value: 'More than 3 years ago',
            label: 'More than 3 years ago',
            labelCn: '多於三年',
          },
          {
            value: 'More than 5 years ago',
            label: 'More than 5 years ago',
            labelCn: '多於五年',
          },
          {
            value: 'Unknown',
            label: 'Unknown',
            labelCn: '不清楚',
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'What was your HPV test result in the pilot study last year?',
        titleCn: '在去年的臨床研究中，你的HPV檢測結果是什麼？',
        configs: [
          {
            value: 'Positive',
            label: 'Positive',
            labelCn: '陽性',
          },
          {
            value: 'Negative',
            label: 'Negative',
            labelCn: '陰性',
          },
          {
            value: 'Unknown',
            label: 'Unknown',
            labelCn: '不清楚',
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'In the pilot study last year, did you undergo the recommended Pap smear test as a follow-up test?',
        titleCn: '在去年的臨床研究中，您是否接受了推薦的抹片檢查作為後續檢查？',
        configs: [
          {
            value: 'Yes',
            label: 'Yes',
            labelCn: '有',
            components: [
              {
                type: 'buttonSelect',
                title:
                  'Why were you willing to undergo smear testing as a follow-up check in last year’s pilot study? (Can choose more than one)',
                titleCn: '您為什麼願意接受抹片檢查作為後續檢查？ (可以選擇多個答案)',
                isLarge: true,
                maxSelect: 6,
                minSelect: 1,
                configs: [
                  {
                    value: 'Concerned about my health',
                    label: 'Concerned about my health',
                    labelCn: '擔心自己的健康',
                  },
                  {
                    value: 'Followed the advice of healthcare providers',
                    label: 'Followed the advice of healthcare providers',
                    labelCn: '聽從醫療保健提供者的建議',
                  },
                  {
                    value: 'Concerns about potential health risks identified in initial Urine HPV Test',
                    label: 'Concerns about potential health risks identified in initial Urine HPV Test',
                    labelCn: '對尿液HPV檢測中發現的潜在健康風險的擔憂',
                  },
                  {
                    value: 'Free test offer',
                    label: 'Free test offer',
                    labelCn: '免費測試',
                  },
                  {
                    value: 'Curiosity about the process and results',
                    label: 'Curiosity about the process and results',
                    labelCn: '對過程和結果的好奇心',
                  },
                  {
                    value: 'Others',
                    label: 'Others',
                    labelCn: '其他',
                    needTextInput: true,
                  },
                ],
              },
            ],
          },
          {
            value: 'No',
            label: 'No',
            labelCn: '没有',
            components: [
              {
                type: 'buttonSelect',
                title:
                  'Why were you not willing to undergo smear testing as a follow-up check in last year’s pilot study? (Can choose more than one)',
                titleCn: '您為什麼不願意接受抹片檢查作為後續檢查？ (可以選擇多個答案)',
                isLarge: true,
                maxSelect: 6,
                minSelect: 1,
                configs: [
                  {
                    value: 'Uncomfortable with the testing procedure',
                    label: 'Uncomfortable with the testing procedure',
                    labelCn: '對測試程序感到不舒服',
                  },
                  {
                    value: 'Lack of time or availability',
                    label: 'Lack of time or availability',
                    labelCn: '沒有時間或不便參加',
                  },
                  {
                    value: 'Concerns about the accuracy of the test',
                    label: 'Concerns about the accuracy of the test',
                    labelCn: '對測試準確性表示擔憂',
                  },
                  {
                    value: 'Lack of understanding about the importance of the test',
                    label: 'Lack of understanding about the importance of the test',
                    labelCn: '對測試的重要性缺乏瞭解',
                  },
                  {
                    value: 'Fear of potential health risks associated with the test',
                    label: 'Fear of potential health risks associated with the test',
                    labelCn: '擔心與測試相關的潜在健康風險',
                  },
                  {
                    value: 'Others',
                    label: 'Others',
                    labelCn: '其他',
                    needTextInput: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Sample Collection',
    nameCn: '樣本收集',
    components: [
      {
        type: 'buttonSelect',
        title: 'Which method do you prefer the MOST for cervical screening?',
        titleCn: '哪一種方法會作為您子宮頸篩查的首選？',
        isLarge: true,
        configs: [
          {
            value: 'Self-collecting Urine sample for HPV testing',
            label: 'Self-collecting Urine sample for HPV testing',
            labelCn: '自我收集尿液樣本做HPV檢測',
          },
          {
            value: 'Self-collecting vaginal swab for HPV testing',
            label: 'Self-collecting vaginal swab for HPV testing',
            labelCn: '自我收集陰道拭子做HPV檢測',
          },
          {
            value:
              'Physician conducting a speculum (vaginal) examination to collect a sample from the cervix for Pap smear or HPV testing',
            label:
              'Physician conducting a speculum (vaginal) examination to collect a sample from the cervix for Pap smear or HPV testing',
            labelCn: '醫生進行窺器（陰道）檢查並收集子宮頸樣本做抹片檢查或HPV檢測',
          },
          {
            value: 'No preference',
            label: 'No preference',
            labelCn: '沒有偏好',
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'Sample(s) you are willing to provide (Can choose more than one):',
        titleCn: '您願意提供的樣本 (可以選擇多個答案):',
        minSelect: 1,
        maxSelect: 3,
        configs: [
          {
            value: 'Urine',
            label: 'Urine',
            labelCn: '尿液',
          },
          {
            value: 'Self-collected vaginal swab',
            label: 'Self-collected vaginal swab',
            labelCn: '自我採樣陰道拭子',
          },
          {
            value: 'Cervical smear',
            label: 'Cervical smear',
            labelCn: '宮頸塗片',
          },
        ],
      },
      {
        type: 'feedbackSelect',
        title: 'Your views and feedback on different collection methods:',
        titleCn: '您對不同採集方法的看法和用後感：',
        feedbackConfigs: [
          {
            title: 'Overall, how did you feel about the sample collection process?',
            titleCn: '總括來說，您對樣本採集過程有何看法?',
            feedbackMap: [
              {
                label: 'Very bad',
                labelCn: '很差',
                value: '1',
              },
              {
                label: 'Bad',
                labelCn: '差',
                value: '2',
              },
              {
                label: 'Neutral',
                labelCn: '一般',
                value: '3',
              },
              {
                label: 'Good',
                labelCn: '好',
                value: '4',
              },
              {
                label: 'Very good',
                labelCn: '很好',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },
          {
            title: 'How simple was the process of collecting this sample?',
            titleCn: '您認為收集樣本的過程簡單嗎?',
            feedbackMap: [
              {
                label: 'Very hard',
                labelCn: '很困難',
                value: '1',
              },
              {
                label: 'Hard',
                labelCn: '困難',
                value: '2',
              },
              {
                label: 'Neutral',
                labelCn: '中等',
                value: '3',
              },
              {
                label: 'Easy',
                labelCn: '容易',
                value: '4',
              },
              {
                label: 'Very easy',
                labelCn: '很容易',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },

          {
            title: 'How convenient was it to collect this sample?',
            titleCn: '您認為收集這個樣本方便嗎?',
            feedbackMap: [
              {
                label: 'Very inconvenient',
                labelCn: '很不方便',
                value: '1',
              },
              {
                label: 'Inconvenient',
                labelCn: '不方便',
                value: '2',
              },
              {
                label: 'Neutral',
                labelCn: '一般',
                value: '3',
              },
              {
                label: 'Convenient',
                labelCn: '方便',
                value: '4',
              },
              {
                label: 'Very convenient',
                labelCn: '很方便',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },

          {
            title: 'Did you feel embarrassed while collecting this sample?',
            titleCn: '您在收集樣本時感到尷尬嗎?',
            feedbackMap: [
              {
                label: 'Very embarrassed',
                labelCn: '很尷尬',
                value: '1',
              },
              {
                label: 'Embarrassed',
                labelCn: '尷尬',
                value: '2',
              },
              {
                label: 'Neutral',
                labelCn: '一般',
                value: '3',
              },
              {
                label: 'Not embarrassed',
                labelCn: '不尷尬',
                value: '4',
              },
              {
                label: 'Not embarrassed at all',
                labelCn: '完全不尷尬',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },
          {
            title: 'Did you experience any discomfort /pain while collecting this sample?',
            titleCn: '您在收集樣本時感到不適/疼痛嗎?',
            feedbackMap: [
              {
                label: 'Severe discomfort / pain',
                labelCn: '嚴重的不適/疼痛',
                value: '1',
              },
              {
                label: 'Quite uncomfortable / painful',
                labelCn: '相當不適/疼痛',
                value: '2',
              },
              {
                label: 'Some discomfort / pain; Can tolerate',
                labelCn: '一些不適/疼痛，但能够忍受',
                value: '3',
              },
              {
                label: 'Mild discomfort / pain',
                labelCn: '輕度不適/疼痛',
                value: '4',
              },
              {
                label: 'No discomfort / pain',
                labelCn: '沒有不適/疼痛',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },

          {
            title: 'How confident are you in the accuracy of the sample collection process?',
            titleCn: '您對樣本採集過程的準確性有多大信心？',
            feedbackMap: [
              {
                label: 'Not confident at all',
                labelCn: '完全沒有信心',
                value: '1',
              },
              {
                label: 'Not confident',
                labelCn: '沒有信心',
                value: '2',
              },
              {
                label: 'Neutral',
                labelCn: '一般',
                value: '3',
              },
              {
                label: 'Confident',
                labelCn: '有信心',
                value: '4',
              },
              {
                label: 'Very confident',
                labelCn: '很有信心',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'Would you be willing to do the HPV test again in the future? (Urine)',
        titleCn: '您是否願意以後再次進行HPV測試? (尿液樣本)',
        configs: [
          {
            value: 'Yes',
            label: 'Yes',
            labelCn: '是',
            components: [
              {
                type: 'buttonSelect',
                title: 'Why are you willing to do the HPV test (Urine) again? (can choose more than one)',
                titleCn: '您為什麼願意再次進行HPV測試(尿液樣本)？(可以選擇多個答案)',
                isLarge: true,
                minSelect: 1,
                maxSelect: 6,
                configs: [
                  {
                    value: 'Sampling process is straightforward',
                    label: 'Sampling process is straightforward',
                    labelCn: '採樣過程簡單',
                  },
                  {
                    value: 'Sampling is convenient',
                    label: 'Sampling is convenient',
                    labelCn: '採樣方便',
                  },
                  {
                    value: 'I am confident in the accuracy of the sampling',
                    label: 'I am confident in the accuracy of the sampling',
                    labelCn: '我對採樣過程的準確性有信心',
                  },
                  {
                    value: 'I did not feel awkward during the sampling process',
                    label: 'I did not feel awkward during the sampling process',
                    labelCn: '我在採樣過程中沒有感到尷尬',
                  },
                  {
                    value: 'I did not feel uncomfortable / painful during the sampling process',
                    label: 'I did not feel uncomfortable / painful during the sampling process',
                    labelCn: '我在採樣過程中並沒有感到不適或疼痛',
                  },
                  {
                    value: 'I can save time by not having to visit the clinic',
                    label: 'I can save time by not having to visit the clinic',
                    labelCn: '我可以省去前往診所的時間',
                  },
                ],
              },
            ],
          },
          {
            value: 'No',
            label: 'No',
            labelCn: '否',
            components: [
              {
                type: 'buttonSelect',
                title: 'Why are you not willing to do the HPV test (Urine) again? (can choose more than one)',
                titleCn: '您為什麼不願意再次進行HPV測試(尿液樣本)？(可以選擇多個答案)',
                isLarge: true,
                minSelect: 1,
                maxSelect: 6,
                configs: [
                  {
                    value: 'Sampling process is difficult',
                    label: 'Sampling process is difficult',
                    labelCn: '採樣過程較困難',
                  },
                  {
                    value: 'Sampling process is cumbersome',
                    label: 'Sampling process is cumbersome',
                    labelCn: '採樣過程繁瑣',
                  },
                  {
                    value: 'I am not confident in the accuracy of the sampling',
                    label: 'I am not confident in the accuracy of the sampling',
                    labelCn: '我對採樣過程的準確性沒有信心',
                  },
                  {
                    value: 'I feel awkward during the sampling process',
                    label: 'I feel awkward during the sampling process',
                    labelCn: '我對採樣過程感到尷尬',
                  },
                  {
                    value: 'I feel uncomfortable/painful during the sampling process',
                    label: 'I feel uncomfortable/painful during the sampling process',
                    labelCn: '我在採樣過程中感到不適或疼痛',
                  },
                  {
                    value: "I'm worried that I might get hurt during the sampling process",
                    label: "I'm worried that I might get hurt during the sampling process",
                    labelCn: '我擔心採樣過程中會受傷',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'Would you be willing to do the HPV test again in the future? (Vaginal Swab)',
        titleCn: '您是否願意以後再次進行HPV測試? (陰道拭子樣本)',
        configs: [
          {
            value: 'Yes',
            label: 'Yes',
            labelCn: '是',
            components: [
              {
                type: 'buttonSelect',
                title: 'Why are you willing to do the HPV test (Vaginal Swab) again? (can choose more than one)',
                titleCn: '您為什麼願意再次進行HPV測試(陰道拭子樣本)？(可以選擇多個答案)',
                isLarge: true,
                minSelect: 1,
                maxSelect: 6,
                configs: [
                  {
                    value: 'Sampling process is straightforward',
                    label: 'Sampling process is straightforward',
                    labelCn: '採樣過程簡便',
                  },
                  {
                    value: 'Sampling is convenient',
                    label: 'Sampling is convenient',
                    labelCn: '採樣方便',
                  },
                  {
                    value: 'I am confident in the accuracy of the sampling',
                    label: 'I am confident in the accuracy of the sampling',
                    labelCn: '我對採樣過程的準確性有信心',
                  },
                  {
                    value: 'I did not feel awkward during the sampling process',
                    label: 'I did not feel awkward during the sampling process',
                    labelCn: '我在採樣過程中沒有感到尷尬',
                  },
                  {
                    value: 'I did not feel uncomfortable/painful during the sampling process',
                    label: 'I did not feel uncomfortable/painful during the sampling process',
                    labelCn: '我在採樣過程中並沒有感到不適或疼痛',
                  },
                  {
                    value: 'I can save time by not having to visit the clinic',
                    label: 'I can save time by not having to visit the clinic',
                    labelCn: '我可以省去前往診所的時間',
                  },
                ],
              },
            ],
          },
          {
            value: 'No',
            label: 'No',
            labelCn: '否',
            components: [
              {
                type: 'buttonSelect',
                title: 'Why are you not willing to do the HPV test (Vaginal Swab) again? (can choose more than one)',
                titleCn: '您為什麼不願意再次進行HPV測試(陰道拭子樣本)？(可以選擇多個答案)',
                isLarge: true,
                minSelect: 1,
                maxSelect: 6,
                configs: [
                  {
                    value: 'Sampling process is difficult',
                    label: 'Sampling process is difficult',
                    labelCn: '採樣過程較困難',
                  },
                  {
                    value: 'Sampling process is cumbersome',
                    label: 'Sampling process is cumbersome',
                    labelCn: '採樣過程繁瑣',
                  },
                  {
                    value: 'I am not confident in the accuracy of the sampling',
                    label: 'I am not confident in the accuracy of the sampling',
                    labelCn: '我對採樣過程的準確性沒有信心',
                  },
                  {
                    value: 'I feel awkward during the sampling process',
                    label: 'I feel awkward during the sampling process',
                    labelCn: '我對採樣過程感到尷尬',
                  },
                  {
                    value: 'I feel uncomfortable / painful during the sampling process',
                    label: 'I feel uncomfortable / painful during the sampling process',
                    labelCn: '我在採樣過程中感到不適或疼痛',
                  },
                  {
                    value: "I'm worried that I might get hurt during the sampling process",
                    label: "I'm worried that I might get hurt during the sampling process",
                    labelCn: '我擔心採樣過程中會受傷',
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        type: 'sampleCollectionTime',
        title: 'Sample Collection Date and Time',
        titleCn: '樣本採集日期和時間',
        titleConfigs: [
          {
            title: 'Sample Collection Date ( YYYY / MM / DD )',
            titleCn: '樣本採集日期（YYYY/MM/DD）',
          },
          {
            title: 'Sample Collection Time ( HH : MM )',
            titleCn: '樣本採集時間（HH:MM）',
          },
        ],
      },
      // {
      //   type: 'buttonSelect',
      //   title: 'How did you learn about the INDICAID HPV Urine Test?',
      //   titleCn: '您從何得悉有關INDICAID妥析 HPV尿液測試的產品資訊?',
      //   isLarge: true,
      //   configs: [
      //     {
      //       value: 'Online Ads (E.g. Google Ads)',
      //       label: 'Online Ads (E.g. Google Ads)',
      //       labelCn: '網上廣告 (例如Google Ads)',
      //     },
      //     {
      //       value: 'Social media Ads (E.g. Facebook Ads or Instagram Ads)',
      //       label: 'Social media Ads (E.g. Facebook Ads or Instagram Ads)',
      //       labelCn: '社交媒體廣告 (例如Facebook廣告、Instagram廣告)',
      //     },
      //     {
      //       value: 'INDICAID Email Newsletter',
      //       label: 'INDICAID Email Newsletter',
      //       labelCn: '來自INDICAID妥析的電子郵件',
      //     },
      //     {
      //       value: 'Recommendations from KOLs or Social Media Influencers (Facebook/Instagram/Youtube)',
      //       label: 'Recommendations from KOLs or Social Media Influencers (Facebook/Instagram/Youtube)',
      //       labelCn: '意見領袖(KOL)/網紅(Influencer)於社交媒體(例如Facebook/Instagram/Youtube)推薦',
      //     },
      //     {
      //       value: 'Recommendations from medical professionals / professors',
      //       label: 'Recommendations from medical professionals / professors',
      //       labelCn: '專業人士推薦 (例如醫生、教授)',
      //     },
      //     {
      //       value: 'Retail Store Ads (E.g. flyers, posters, or displays in retail stores)',
      //       label: 'Retail Store Ads (E.g. flyers, posters, or displays in retail stores)',
      //       labelCn: '實體店廣告 (例如零售店內的傳單、海報、展示牌等)',
      //     },
      //     {
      //       value: 'Outdoor Ads',
      //       label: 'Outdoor Ads',
      //       labelCn: '戶外廣告',
      //     },
      //     {
      //       value: 'Search engine',
      //       label: 'Search engine',
      //       labelCn: '關鍵字搜索',
      //     },
      //     {
      //       value: 'Blogs',
      //       label: 'Blogs',
      //       labelCn: '網誌分享 (Blogs)',
      //     },
      //     {
      //       value: 'Others',
      //       label: 'Others',
      //       labelCn: '其他，請説明：',
      //       needTextInput: true,
      //     },
      //   ],
      // },
      // {
      //   type: 'buttonSelect',
      //   title: 'Why did you choose to use the INDICAID HPV Urine Test?(Please select 2 items)',
      //   titleCn: '為什麼選擇使用INDICAID妥析 HPV尿液測試?(請選擇2項)',
      //   isLarge: true,
      //   minSelect: 2,
      //   maxSelect: 2,
      //   configs: [
      //     {
      //       value:
      //         'I would like to learn about my cancer risk from a potential HPV infection (such as cervical cancer, vulvar cancer, vaginal cancer, penile cancer)',
      //       label:
      //         'I would like to learn about my cancer risk from a potential HPV infection (such as cervical cancer, vulvar cancer, vaginal cancer, penile cancer)',
      //       labelCn: '關注與人類乳頭瘤病毒（HPV）相關的癌症（例如子宮頸癌、外陰癌、陰道癌、陰莖癌等)風險',
      //     },
      //     {
      //       value: 'I regularly get HPV / cervical cancer screening tests',
      //       label: 'I regularly get HPV / cervical cancer screening tests',
      //       labelCn: '進行定期HPV / 子宮頸檢測',
      //     },
      //     {
      //       value: 'I am worried whether I have contracted an STD / HPV',
      //       label: 'I am worried whether I have contracted an STD / HPV',
      //       labelCn: '檢測是否感染性病/HPV',
      //     },
      //     {
      //       value:
      //         'Because this HPV screening test uses urine samples, is non-invasive, allows self-sampling, and is very private',
      //       label:
      //         'Because this HPV screening test uses urine samples, is non-invasive, allows self-sampling, and is very private',
      //       labelCn: '因INDICAID妥析HPV尿液測試的獨特性(尿液採樣 , 非入侵性/自行採樣, 私隱度高等)',
      //     },
      //     {
      //       value: 'Recommendations from medical professionals / professors',
      //       label: 'Recommendations from medical professionals / professors',
      //       labelCn: '醫生/醫護人員推薦',
      //     },
      //     {
      //       value: 'I am worried about infecting my partner',
      //       label: 'I am worried about infecting my partner',
      //       labelCn: '避免傳染給伴侶',
      //     },
      //     {
      //       value: 'My medical insurance covers HPV screening tests',
      //       label: 'My medical insurance covers HPV screening tests',
      //       labelCn: '醫療保險保障覆蓋HPV檢測',
      //     },
      //     {
      //       value: 'Others',
      //       label: 'Others',
      //       labelCn: '其他，請説明：',
      //       needTextInput: true,
      //     },
      //   ],
      // },
    ],
  },
] as CompaginConfig['pageConfigs'];

export const compaginConfig: CompaginConfig = {
  compaginName: '0749',
  headerImg: headerImg,
  qa: {
    contentCn: `閣下獲邀參與本項臨床研究。在閣下決定參與本項臨床研究之前，請細閱本知情同意書，確保閣下知曉本項臨床研究之目的、程序及風險，以便閣下能夠作出知情的決定。若有任何疑慮或問題，請諮詢香港大學婦產科學系吳庥慧醫生(電話：2255-4265)。
*研究課題：透過縱貫性研究，評估對於對定期子宮頸癌篩查意識較低的人群，進行尿液HPV檢測的可接受程度及對疾病控制的效果*
##### 這項研究的目的是什麼？
我們的目的是評估没有定期作子宮頸癌篩查的受試者分別接受尿液HPV檢測、自拭子HPV檢測和宮頸塗片檢測的可接受性和意願。 我們還旨在評估一年後HPV發病率的變化，從而評估尿液HPV檢測在疾病控制中的有效性。
##### 為何會被選中？
您獲邀參加是因為您是一名年齡介乎18-80歲之間且有性經驗的女性。您去年參加了試點研究，在試點研究前3年內未接受宮頸篩查。並且您在過去沒有接受過子宮切除手術、 不曾患有子宮頸癌、現時沒有懷孕、且目前未接受子宮頸上皮内瘤病變或子宮頸癌的治療。
##### 是否必需參加？
由您決定是否參加。如您決定參加，請簽署同意書。當然，您仍可隨時退出這項研究而不需要提供任何理由，這不會影響您所接受的護理標準。
##### 如果參加，有什麼需要做？
如果您决定參加，您將首先從去年試點研究期間注册的非政府組織（NGO）處領取一份詳細列明了操作流程的研究簡介章程、一份尿液HPV自取樣套件和一份陰道拭子自取樣套件。 您還將被邀請到香港家庭計畫指導會（家計會）免費收集標準塗片。

對於尿液和陰道拭子樣本，您將接受自我取樣程序的指導，並需要自行進行取樣。樣本收集大約需要10分鐘，之後需歸還至非政府組織。在提交樣本前，您需要掃描說明書上的專屬二維碼以填寫樣本登記表格和您將需要填寫一份關於取樣方法的簡短問卷。 填寫此問卷可能需要約10分鐘的時間。尿液樣本將被送往相達醫學檢驗實驗室進行檢測。陰道拭子樣本將被送往香港大學婦產科進行Cobas HPV檢測。

檢測結果將透過電子郵件或電話通知。若尿液HPV檢測或陰道拭子HPV檢測中出現異常結果，我們將再次聯系您，並將您轉介給家計會進行免費的標準塗片檢查。

若您决定進行宮頸塗片HPV和細胞學檢測，您需自行向家計會預約。 所產生之費用（包括宮頸塗片採樣、細胞學和HPV檢測）皆已包含在研究中，您無需支付任何費用。 家計會的醫療專業人員將按照標準程式收集宮頸塗片樣本，預計持續時間為15-30分鐘。 宮頸塗片樣本將被送往香港大學進行Cobas HPV細胞學檢測。 在完成採樣後，您需要填寫一份關於宮頸塗片檢查反饋的線上調查表，大約需要5分鐘的時間。

從研究中收集的所有標本、您的個人資訊和數據將保存在相達醫學檢驗實驗室和香港大學婦產科學部門長達10年，以便未來以探索新的生物標記。
##### 參加有什麼副作用？
没有副作用。
##### 參加的弊處和風險是什麼？
除了自我取樣或由醫生進行的子宮頸取樣引起的可能不適，以及收​​集、遞送和由醫生取樣所花費的時間外，參與這項研究沒有可辨別的缺點或風險。
##### 參加有什麼報酬？
您將獲得免費的尿液HPV檢測、免費的自我採集陰道拭子和免費的標準宮頸塗片作為子宮頸癌篩查。
##### 參加有什麽研究費用及補償？
閣下參與此研究將不會獲得任何補償。為參與這項研究而進行的額外測試亦不須付出任何費用。但如果您的塗片結果異常，需要進一步管理，您可能需要支付相關標準護理醫療費用。
##### 參加研究的資料會被保密嗎？
所有研究收集的資料將只會用於研究目的，並會嚴格地保密。沒有您的允許，這項研究所發表的文章中將不會刊登您的名字。
##### 研究的结果會怎麼處理？
結果分析後將在國際期刊和/或科研會議上發表。
##### 誰組織並資助這項研究？
香港大學婦產科學系的研究人員將組織這項研究。該研究將由相達生物科技國際有限公司資助。
##### 誰已審查這個研究？
香港大學及醫管局港島西醫院聯網研究倫理委員會。

更多資料的聯絡方式
無論現在或將來，只要您對這個研究有任何問題，我們都將詳細地解答。如果有任何問題，可以聯絡香港大學婦產科學系吳庥慧醫生(電話：2255-4265)。

如果您對身為研究參與者的權利有任何相關的疑問，您可以聯絡香港大學及醫管局港島西醫院聯網研究倫理委員會，電話號碼為 2255 4086。

非常感謝您加入這項研究。`,
    content: `You are being invited to participate in a clinical research study. Before you decide, please read this informed consent form carefully to ensure that you understand the purpose, procedures and risks of this clinical study so that you can make an informed decision. If you have any concerns or questions, please consult Dr Ngu Siew Fei, Department of Obstetrics and Gynecology, University of Hong Kong (Tel: 2255-4265).
*STUDY TITLE: Evaluation of Urine HPV Detection acceptability and effectiveness in disease control in a cohort of low awareness of regular smear checks by a Longitudinal Study*
##### WHAT IS THE PURPOSE OF THE STUDY?
We aim to evaluate the acceptability and willingness of subjects who have not undergone regular cervical cancer screening to undergo urine HPV testing, self-swab HPV testing, and cervical smear testing, respectively. We also aimed to evaluate the change of HPV incidence rate after one year, to evaluate the effectiveness of urine HPV detection in disease control.
##### WHY HAVE I BEEN CHOSEN?
You have been invited as you are a woman between the ages of 18-80 and have a history of sexual activity. You had participated in pilot study last year and had not received cervical screening within the prior 3 years before pilot study. You also do not have a history of total hysterectomy or cervical cancer, are not currently pregnant, and are not currently under treatment of CIN or cervical cancer.
##### DO I HAVE TO TAKE PART?
It is up to you to decide whether or not to take part. If you do decide to take part, you will be given this information sheet to keep and be asked to sign a consent form. You are still free to withdraw at any time without the need to provide a reason even if you decide to take part. This will not affect the standard of care you receive.
##### WHAT WILL HAPPEN TO ME IF I TAKE PART?
If you decide to participate, you will first receive a research brief charter detailing the operational procedures, a urine HPV self-sampling kit, and a vaginal swab self-sampling kit from the non-governmental organization (NGO) registered during last year's pilot study. You will also be invited to the Hong Kong Family Planning Association (FPA) to collect standard smears for free.
For urine and vaginal swab samples, you will be guided on the self-sampling procedure and asked to perform it in the privacy of your home or at the registration site. It should take approximately 10 minutes to collect the samples, which must be returned to the NGO. Before submitting the sample, you need to scan the exclusive QR code on the instruction manual to fill out the sample registration form and you will need to fill out a brief questionnaire about the sampling method. Filling out this questionnaire may take about 10 minutes. Collected urine samples will be sent to Phase Scientific Medical Laboratory for testing while vaginal swab samples will be sent to the department of Obstetrics and Gynecology (O&G), The University of Hong Kong (HKU) for Cobas HPV testing.
Results will be communicated via email or phone. If any abnormal results are found in urine HPV test or self-vaginal swab HPV test, you will be contacted again and referred to the health care provider at FPA for a free standard smear if you have not done so.
If you decide to undergo cervical smear HPV and cytology testing, you need to make an appointment with the FPA on your own. All expenses (including smear taking, cytology and HPV testing) incurred are included in the research and you do not need to pay any fees. Healthcare professionals at FPA will collect cervical smear samples using standard procedures, lasting 15-30 minutes. The cervical smear samples will be sent to HKU for cytology and Cobas HPV testing. After completing the sampling, you need to fill out an online survey form regarding feedback on cervical smear examination, which will take approximately 5 minutes.

All specimens collected, your personal information and data collected from the study would be kept at Phase Scientific International Limited and O&G department of HKU for up to 10 years for future exploration of biomarkers that may be useful.
##### WHAT ARE THE SIDE EFFECTS OF TAKING PART?
There are no adverse effects.
##### WHAT ARE THE DISADVANTAGES AND RISKS OF TAKING PART?
Other than the possible discomfort caused by self or clinician collected cervical sampling, and the time taken to collect and return the samples, as well as attending the health care providers for standard cervical smear, there are no discernible disadvantages or risks to taking part of this study.
##### WHAT ARE THE BENEFITS OF TAKING PART?
 You will receive a free urine HPV test, a free self-collecting vaginal swab and a free standard cervical smear as part of the cervical cancer screening.
##### WHAT ARE THE COSTS AND COMPENSATION?
You will not receive any compensation for your participation in this study. There will be no costs to you for any laboratory tests that may be performed as a result of your participation in this study. But you may need to pay for the standard of care medical expenses if your smear results are abnormal and further management is required.
##### WILL MY TAKING PART IN THIS STUDY BE KEPT CONFIDENTIAL?
The data collected in this study will only be used for research purposes and will remain strictly confidential. Your name will not be used in any publications that may result from this research without your prior permission.
##### WHAT WILL HAPPEN TO THE RESULTS OF THE RESEARCH STUDY?
The results will be analysed and presented in international journals and/or research conferences.
##### WHO IS ORGANISING AND FUNDING THE RESEARCH?
Researchers in the Department of Obstetrics and Gynaecology, the University of Hong Kong, will organize the research. The research is funded by Phase Scientific International Limited.
##### WHO HAS REVIEWED THE STUDY?
The Institutional Review Board of the University of Hong Kong/Hospital Authority Hong Kong West Cluster has reviewed the study.

CONTACT FOR FURTHER INFORMATION
Any questions you may have about this study, now or at any time in the future, will be fully answered.  If you have any questions, you may contact Dr NGU Siew Fei, Department of Obstetrics and Gynaecology, the University of Hong Kong Tel: 2255-4265.
For your rights as a study participant, you can contact Institutional Review Board of the University of Hong Kong / Hospital Authority Hong Kong West Cluster on this telephone number 2255 4086. 

Thank you very much for taking part in this study. `,
  },
  agreement: {
    title: 'Agreements',
    titleCn: '參加者同意書',
    contentList: [
      {
        type: 'text',
        content: `*Title of Project: Evaluation of Urine HPV Detection acceptability and effectiveness in disease control in a cohort of low awareness of regular smear checks by a Longitudinal Study*
Name of Researcher: Dr NGU Siew Fei`,
        contentCn: `*項目名稱：透過縱貫性研究，評估對於對定期子宮頸癌篩查意識較低的人群，進行尿液HPV檢測的可接受程度及對疾病控制的效果*
研究者姓名：吳庥慧醫生`,
      },
      {
        type: 'checkbox',
        content:
          '1. I confirm that I have read and understood the information sheet for the above study and have had the opportunity to ask questions.  ',
        contentCn: '1. 本人確認本人對於上述的研究已經閱讀並理解參考資料，並有機會提出問題。',
      },
      {
        type: 'checkbox',
        content:
          '2. I understand that my participation is voluntary and that I am free to withdraw at any time, without giving any reason, without my medical care or legal rights being affected.     ',
        contentCn:
          '2. 本人理解本人參加是項研究是自願並且在任何時候均可自由退出並不需提供任何理由，本人的醫療護理或法律權利不會受到影響。',
      },
      {
        type: 'checkbox',
        content:
          '3. I understand that sections of any of my medical notes may be looked at by responsible individuals from the research team or from regulatory authorities where it is relevant to my taking part in the research. I give permission to these individuals to have access to my records.',
        contentCn:
          '3. 本人理解研究小組或管理當局的負責人員可以閱讀本人任何有關是項研究的醫療記錄。本人授權以上人員查閲本人的記錄。',
      },
      {
        type: 'checkbox',
        content: '4. I agree to take part in the above study.',
        contentCn: '4. 本人同意參與上述的研究。',
      },
      {
        type: 'text',
        content: `##### Confidentiality
You have the rights of access to personal data and publicly available study results, if and when needed.
Under the laws of Hong Kong (in particular the Personal Data (Privacy) Ordinance, Cap 486), you enjoy or may enjoy rights for the protection of the confidentiality of your personal data, such as those regarding the collection, custody, retention, management, control, use (including analysis or comparison), transfer in or out of Hong Kong, non-disclosure, erasure and/or in any way dealing with or disposing of any of your personal data in or for this study. For any query, you should consult the Privacy Commissioner for Personal Data or his / her office (Tel No. 2827 2827) as to the proper monitoring or supervision of your personal data protection so that your full awareness and understanding of the significance of compliance with the law governing privacy data is assured.

By consenting to participate in this study, you expressly authorize:
The principal investigator and her research team and the Institutional Review Board of the University of Hong Kong / Hospital Authority Hong Kong West Cluster responsible for overseeing this study to get access to, to use, and to retain your personal data for the purposes and in the manner described in this informed consent process; and
The relevant government agencies (e.g. the Hong Kong Department of Health) to get access to your personal data for the purposes of checking and verifying the integrity of study data and assessing compliance with the study protocol and relevant requirements.`,
        contentCn: `##### 資料保密
有需要的話，每個研究參與者都有權利獲得其個人的數據以及公開報告的研究結果。
根據香港法律（特別是「個人資料（私隱）條例」，第486章），您有權對您的個人資料進行保密，如在本項研究中或與本項研究有關的個人資料的收集、保管、保留、管理、控制、使用（分析或比較）、在香港內外轉讓、不披露、消除和/或任何方式處理。如有任何問題，您可以諮詢個人資料私隱專員或致電到其辦公室（電話號碼：2827 2827），以適當監管或監督您個人資料保護，以便您能完全認識和瞭解確保遵守法律保護隱私資料的意義。

同意參與該項研究，您明確作出以下授權:
為了監督該項研究，授權主要研究者及其研究團隊和香港大學及醫管局港島西醫院聯網研究倫理委員根據本項研究和本知情同意書規定的方式獲得、使用並保留您的個人資料，並且為了檢查和核實研究資料的完整性、評估研究協定與相關要求的一致性，授權相關的政府機構（如香港衛生署）可獲得您個人資料。`,
      },

      {
        type: 'name-input',
        content: '',
        contentCn: '',
      },
    ],
  },
  pageConfigs,
};
