import { MEDIA_SIZE } from '@consts/consts';
import logo from '@images/INDICAIDLogo.png';
import { t } from 'i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import greenEarth from '@images/greenEarth.svg';
import { switchLanguage } from '@utils/index';
import { zhHKLanguage, enLanguage } from 'src/router/routerTools';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';

export default function CollectionHeader(props: { headerImg?: string }) {
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const isZhHKLanguage = useIsZhHKLanguage();

  const onClickLanguage = () => {
    const origin = isZhHKLanguage ? zhHKLanguage : enLanguage;
    // 切换为另外一个语言
    const target = isZhHKLanguage ? enLanguage : zhHKLanguage;
    switchLanguage(origin, target);
  };
  return (
    <div className="flex p-[42px_25px_28px_34px] justify-between items-center  lg:w-[71.38vw] lg:mx-[auto] lg:pl-[0px] lg:px-[0vw] lg:pt-[45px] lg:pb-[52px]">
      <div className="w-[180px] lg:w-[180px]">
        <img
          src={props.headerImg || logo}
          alt="logo"
          className="object-cover w-[180px]  cursor-pointer  lg:w-[180px]"
        />
      </div>
      {isLg ? (
        <div
          className="flex items-center justify-center lg:mr-[8px] cursor-pointer"
          onClick={() => {
            onClickLanguage();
          }}
        >
          <div className="w-[24px]  lg:w-[29px]">
            <img src={greenEarth} alt="greenEarth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
          </div>
          <div className="lg:ml-[10px] font-[600] text-[#2DBDB6] lg:text-[18px] lg:leading-[18px]  text-center">
            {t('header.language')}
          </div>
        </div>
      ) : (
        <div
          className="w-[24px]  lg:w-[29px] cursor-pointer"
          onClick={() => {
            onClickLanguage();
          }}
        >
          <img src={greenEarth} alt="greenEarth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
        </div>
      )}
    </div>
  );
}
