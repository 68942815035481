import { Button } from 'antd';
import { ButtonHTMLType } from 'antd/es/button';
import { CSSProperties } from 'react';
import vector from '@images/vector.svg';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import { useTranslation } from 'react-i18next';

const bgColor = {
  default: '#2DBDB6',
  disabled: '#F5F5F5',
  success: '#2DBDB6',
  dangerous: '#FF4D4F',
  orange: '#F37021',
  doctorDefault: '#009FAF',
  doctorClear: '#FFFFFF',
  doctorClearDisabled: '#FFFFFF',
  check: '#2DBDB6',
  // TODO 可能需要调整
  unchecked: undefined,
  next: '#009FAF',
  emptyError: '#FFFFFF',
  cnDisabled: '#B8E2DF',
};
// TODO 下面都需要调整
const textColor = {
  default: '#ffffff',
  disabled: 'rgba(0, 0, 0, 0.25)',
  success: '#ffffff',
  dangerous: '#ffffff',
  orange: '#ffffff',
  doctorDefault: '#ffffff',
  doctorClear: '#009FAF',
  doctorClearDisabled: 'rgba(0, 0, 0, 0.25)',
  check: '#FFFFFF',
  unchecked: '#2DBDB6',
  next: '#FFFFFF',
  emptyError: '#DD524C',
  cnDisabled: '#FFFFFF',
};
const borderColor = {
  default: 'none',
  disabled: '1px solid #D9D9D9',
  success: 'none',
  dangerous: 'none',
  orange: 'none',
  doctorDefault: 'none',
  doctorClear: '1px solid #009FAF',
  doctorClearDisabled: '1px solid #D9D9D9',
  check: 'none',
  unchecked: '1px solid #2DBDB6',
  next: 'none',
  emptyError: '1px solid #DD524C',
  cnDisabled: 'none',
};
export type ButtonStatus =
  | 'default'
  | 'doctorDefault'
  | 'disabled'
  | 'success'
  | 'dangerous'
  | 'orange'
  | 'doctorClear'
  | 'doctorClearDisabled'
  | 'check'
  | 'unchecked'
  | 'next'
  | 'emptyError'
  | 'cnDisabled'
  | undefined;

type BaseButtonProps = {
  value?: string | React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>;
  status?: ButtonStatus;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  loading?: boolean;
  htmlType?: ButtonHTMLType;
  buttonClassName?: string;
  style?: CSSProperties;
  sectionClassName?: string;
  children?: React.ReactElement;
};

export default function CollectionBootleButton(props: BaseButtonProps = {}) {
  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  return (
    <section className={props.sectionClassName}>
      <Button
        // TODO 这个可能需要进行修改
        onClick={props.onClick}
        style={{
          backgroundColor: bgColor[props.status || 'default'],
          // backgroundImage: props.status === 'default' ? 'linear-gradient(91.08deg, #A7DC4D 0%, #2DBDB6 100%)' : '',
          color: textColor[props.status || 'default'],
          border: borderColor[props.status || 'default'],
          boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
          WebkitBoxShadow: props.status === 'doctorDefault' ? '0px 2px 0px rgba(0, 0, 0, 0.043)' : undefined,
          WebkitFilter: props.status === 'doctorClear' ? 'drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.043))' : undefined,
          borderRadius: isLg ? '5px' : '0.122rem',
          ...props.style,
        }}
        placeholder={props.placeholder}
        disabled={props.disable}
        htmlType={props.htmlType}
        className={props.buttonClassName}
        loading={props.loading}
      >
        <>
          {props.children}
          {props.status === 'success' ? (
            <div className="flex justify-center">
              <img src={vector} alt="vector" className="object-cover lg:w-[15px] cursor-pointer mr-[4px]" />
              <div>{t('collectionBootle.Success')}</div>
            </div>
          ) : props.loading ? (
            t('collectionBootle.Loading')
          ) : (
            props.value
          )}
        </>
      </Button>
    </section>
  );
}
