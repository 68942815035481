import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import { Form, Input } from 'antd';
import { useMemoizedFn } from 'ahooks';
import { useEffect } from 'react';

const borderColor = {
  default: '#D5D4DC',
  warning: '#EF8943',
  error: '#C83532',
  success: '#2BAC47',
};
const bgColor = {
  default: '#ffffff',
  warning: '#FDF3EC',
  error: '#FBEFEF',
  success: '#F1F8F2',
};
export type TextInputStatus = 'error' | 'warning' | 'success' | undefined;
type TextInputProps = {
  value?: string;
  onFocus?: () => void;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  status?: TextInputStatus;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  inputClassName?: string;
  iconClassName?: string;
  defaultValue?: string;
  forceUppercase?: boolean;
  formName?: string;
  clearNumber?: number;
  isFullName?: boolean;
};

export default function TextInput(props: TextInputProps = {}) {
  const [form] = Form.useForm();

  const renderHelpTextIcon = () => {
    if (!props.helpText || !props.status) {
      return null;
    }
    if (props.status === 'success') {
      return (
        <CheckCircleFilled
          className={props.iconClassName}
          style={{
            color: borderColor[props.status],
          }}
        />
      );
    } else {
      return (
        <ExclamationCircleFilled
          className={props.iconClassName ?? 'text-[16px] sm:text-[16px]'}
          style={{
            color: borderColor[props.status],
          }}
        />
      );
    }
  };

  const clearValue = useMemoizedFn(() => {
    if (typeof props?.formName === 'string') {
      form.setFieldsValue({ [props.formName]: '' });
    }
  });

  useEffect(() => {
    if (props?.formName && props?.clearNumber && props?.clearNumber > 0) {
      clearValue();
    }
  }, [props?.formName, props?.clearNumber, clearValue]);

  const renderInput = () => {
    return (
      <Input.Password
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        style={{
          backgroundColor: bgColor[props.status || 'default'],
          borderColor: borderColor[props.status || 'default'],
          fontFamily: 'Inter',
          textTransform: props.value && props?.forceUppercase ? 'uppercase' : undefined,
        }}
        placeholder={props.placeholder}
        disabled={props.disable}
        className={props.inputClassName ?? 'text-[16px] w-[328px] h-[50px] lg:text-[16px] lg:w-[328px] lg:h-[50px]'}
        defaultValue={props.defaultValue}
      ></Input.Password>
    );
  };

  const renderError = () => {
    if (props?.isFullName) {
      return (
        <div className="flex items-start">
          <div className="mt-[3px] lg:mt-[4px] flex items-start">{renderHelpTextIcon()}</div>
          <span
            className="text-[14px] ml-[3px] lg:text-[14px] lg:ml-[4px]"
            style={{
              color: borderColor[props.status || 'default'],
            }}
          >
            {props.helpText}
          </span>
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          {renderHelpTextIcon()}
          <span
            className="text-[14px] ml-[4px] lg:text-[14px] lg:ml-[4px]"
            style={{
              color: borderColor[props.status || 'default'],
            }}
          >
            {props.helpText}
          </span>
        </div>
      );
    }
  };

  return (
    <section>
      {props?.formName ? (
        <Form form={form}>
          <Form.Item name={props?.formName}>{renderInput()}</Form.Item>
        </Form>
      ) : (
        renderInput()
      )}
      {renderError()}
    </section>
  );
}
