// import { CloseOutlined } from '@ant-design/icons';
import { renderComponent, RenderComponentOptions } from './globalComponents';

type ResultAlertInfoProps = {
  content: string | React.ReactNode;
};
export function ResultAlertInfo(props: ResultAlertInfoProps & { closeFunction: () => void }) {
  return (
    <>
      <div
        onClick={props.closeFunction}
        className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[10000] flex justify-center items-center"
      >
        {/* lg:h-[433px] */}
        <div className="w-[88.4%] rounded-[5px] bg-[#FFF1F0] p-[20px_16px_44px_16px] lg:w-[443px]  lg:rounded-[5px] lg:p-[20px_16px_44px_16px] max-h-[80vh] overflow-scroll">
          {/* <div className="lg:px-[30px] px-[18px] text-[#e0ecff] text-[22px] font-bold text-right">
            <CloseOutlined className="cursor-pointer" onClick={props.closeFunction} />
          </div> */}
          {props.content}
        </div>
      </div>
      <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[100] bg-[rgba(0,0,0,0.5)]"></div>
    </>
  );
}

export default function alertInfo(config: RenderComponentOptions<ResultAlertInfoProps>) {
  return renderComponent(config, ResultAlertInfo);
}
