import Markdown from 'react-markdown';

export default function MarkdownWrapper(props: { content: string }) {
  const _content = props.content;
  return (
    <Markdown
      className="markdown-content"
      components={{
        em(_props) {
          const { node, ...rest } = _props;
          return <strong style={{ textDecoration: 'underline' }} {...rest} />;
        },
      }}
    >
      {_content
        .split('\n')
        .map((c) => {
          return c + '\n\n';
        })
        .join('\n')}
    </Markdown>
  );
}
