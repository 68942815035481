import { useContext } from 'react';
import { LanguageContext, zhHKLanguage } from 'src/router/routerTools';

export function useIsZhHKLanguage() {
  const language = useContext(LanguageContext);
  if (zhHKLanguage === language) {
    return true;
  } else {
    return false;
  }
}
