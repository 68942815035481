import { get, post } from '@utils/request';

const addNewCompaginData = (params: {
  sampleId: string;
  data: string;
  compaginName: string;
  email: string;
  phone: string;
}) => {
  return post('/hpv/addNewCompaginHpvData', params);
};

const checkSampleId = async (sampleId: string, compaginId: string) => {
  const res = await get<{
    isExist: boolean;
    region: number;
    type: number;
    isExpired: boolean;
    expiredDate: string;
  }>('/codes/sample_id/status', { sampleId });

  if (!res.isExist) {
    return false;
  }
  if (res.isExpired) {
    return false;
  }

  if (+compaginId !== +res.type) {
    return false;
  }

  return true;
};

const isSampleIdUsed = async (sampleId: string) => {
  await post<{
    isExist: boolean;
  }>('/hpv/isCompaginDataExist', { sampleId });
};

const compaginApi = {
  addNewCompaginData,
  checkSampleId,
  isSampleIdUsed,
};
export default compaginApi;
