import { useEffect } from 'react';
import VConsole from 'vconsole';

export default function useVConsole() {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const vConsole = new VConsole();
      return () => {
        vConsole.destroy();
      };
    }
  }, []);
}
