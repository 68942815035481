import './App.css';
import { Outlet } from 'react-router-dom';
// import Header from '@components/Header/Header';
// import Footer from '@components/Footer/Footer';
import { useRouterChangeAutoScrollTop } from './hooks/tools/RouterAutoScrollTop';
import { useContext, useEffect } from 'react';
import { LanguageContext } from 'src/router/routerTools';

function App() {
  useRouterChangeAutoScrollTop();

  // TODO: 接下来需要做的事情
  // 繁体中文=> ’Noto Sans CJK TC‘
  // 数字/英文=>'Monserrat'

  const language = useContext(LanguageContext);
  console.log(language, 'language');

  useEffect(() => {
    console.log(language, 'language');
    document.documentElement.lang = language;
  }, [language]);
  return (
    <div className="App form-app">
      {/* <Header /> */}
      <div className="min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]">
        <Outlet />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
