/* eslint-disable @typescript-eslint/indent */
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import website from '@configs/website.json';
import { handleLocalStorage } from '@utils/index';
import { getLocale } from 'src/router/routerTools';
import { isCNPortal2, isForceEnglishPage } from '@utils/index';

const retryConfig = {
  retries: 3,
  retryCondition: (error: AxiosError) => {
    // 请求超时
    if (error.code === 'ECONNABORTED' || error.message.includes('Network Error')) {
      return true;
    }
    if (error.response && error.response.status < 599 && error.response.status >= 500) {
      return true;
    }
    return false;
  },
  retryDelay: () => 1000,
  shouldResetTimeout: true,
};

const request = axios.create({
  baseURL: process.env.api_base,
  timeout: 60000,
});

axiosRetry(request, retryConfig);

function getLocalePath() {
  const { locale, hasPath } = getLocale();
  return hasPath ? '/' + locale : '';
}

export const isDoctorPortal = () => {
  return window.location.pathname.startsWith(getLocalePath() + '/doctor');
};
const isMeetingPage = () => {
  return window.location.href.includes('/meeting/');
};

function getCommonHeaders() {
  // TODO: 这些header是否必要，删除？
  // const headers: HeadersType
  const headers: any = {
    'Content-Type': 'application/json',
    'X-Request-With': 'XMLHttpRequest',
    // CNPortal2目前只支持中文
    'x-language': isForceEnglishPage()
      ? 'en-us'
      : isCNPortal2()
      ? 'zh-CN'
      : window.location.href.includes('/en/') || isDoctorPortal()
      ? 'en-us'
      : 'zh-HK',
    'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  let token_key;
  if (
    window.location.href.includes(`${location.origin}/doctor`) ||
    window.location.href.includes(`${location.origin}/meeting`)
  ) {
    token_key = website.doctor_access_token_key;
  } else {
    token_key = website.access_token_key;
  }

  const token = handleLocalStorage.get(token_key);
  // const token = cookie.get(website.access_token_key);
  if (token) {
    headers[website.auth_header] = `Bearer ${token}`;
  }

  return headers;
}
type RequestInterceptorsType = (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig;
const requestInterceptors: RequestInterceptorsType = (config) => {
  const headers: AxiosRequestHeaders = { ...config.headers, ...getCommonHeaders() };
  const prefixPath = '/api/v1/';
  const baseURL = process.env.api_base + prefixPath;
  return { ...config, headers, baseURL };
};

const requestError = (error: any) => {
  return Promise.reject(error);
};

// 请求拦截器
request.interceptors.request.use(requestInterceptors, requestError);

// 响应拦截器
// TODO 响应拦截器需要再看一下
const interceptorsResponse = {
  normal: (res: AxiosResponse) => {
    try {
      return res.request && res.data && res.headers ? res.data : res;
    } catch {
      throw new Error('Network Error');
    }
  },
  error: (error: any) => {
    console.log(error.response, 'interceptorsResponse=>error.response');
    if (error.response) {
      const { status } = error.response;
      console.log(status, 'interceptorsResponse=>error.response.status', isMeetingPage(), 'isMeetingPage');
      // TODO 后续需要展示错误信息
      // if (status === 401 || status === '401') {
      //   // 有些场景不需要重定向
      //   if (error.config.params?.redirect !== false) {
      //     const isDoctor = isDoctorPortal() || isMeetingPage();
      //     setTimeout(() => {
      //       window.location.href = `${location.origin}${isDoctor ? '/doctor' : ''}/login?redirect=${encodeURIComponent(
      //         window.location.href,
      //       )}`;
      //     }, 500);
      //   }
      // }
    }
    return Promise.reject(error);
  },
};

request.interceptors.response.use(interceptorsResponse.normal, interceptorsResponse.error);

export default request;

export function get<T = any>(url: string, params?: any, axiosRetryConfig?: IAxiosRetryConfig) {
  return request.get<T, T>(url, {
    params,
    'axios-retry': axiosRetryConfig,
  });
}

export function post<T = any>(url: string, params?: any, axiosRetryConfig?: IAxiosRetryConfig) {
  return request.post<T, T>(url, {
    ...params,
    'axios-retry': axiosRetryConfig,
  });
}

export function put<T = any>(url: string, params?: any, axiosRetryConfig?: IAxiosRetryConfig) {
  return request.put<T, T>(url, {
    ...params,
    'axios-retry': axiosRetryConfig,
  });
}

export function patch<T = any>(url: string, params?: any, axiosRetryConfig?: IAxiosRetryConfig) {
  return request.patch<T, T>(url, {
    ...params,
    'axios-retry': axiosRetryConfig,
  });
}

export function healthPost<T = any>(url: string, params?: any) {
  return request.post<T, T>(url, params);
}

export function deleteFunc<T = any>(url: string, params: any, axiosRetryConfig?: IAxiosRetryConfig) {
  return request.delete<T, T>(url, {
    data: { ...params },
    'axios-retry': axiosRetryConfig,
  });
}
