import { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import scanQRCodeStyle from './scanQRCode.module.css';
import { useSearchParams } from 'react-router-dom';
import { post } from '@utils/request';
import { message, Spin } from 'antd';
import dayjs from 'dayjs';
import { NullableMemory } from 'aws-sdk/clients/computeoptimizer';
const codeReader = new BrowserMultiFormatReader();

// or init with options
function scanQRCode(
  video: HTMLVideoElement,
  collectionBottleId: string | number | null,
  setLoading?: (loading: boolean) => void,
) {
  codeReader.decodeFromVideoDevice(null, 'video', (result, error) => {
    if (result) {
      // TODO: 跳转到成功页面
      setLoading && setLoading(true);
      post('/hpv/attachNewCilinic', {
        sample_id: collectionBottleId,
        new_clinic: result.getText(),
        timestamp: dayjs().format('YYYY/MM/DD hh:mma'),
      })
        .then(() => {
          window.location.href = `/scanSuccess/${collectionBottleId}?res=${encodeURIComponent(result.getText())}`;

          // 跳转至成功页面
        })
        .catch((e: any) => {
          // 专门的错误码，代表着用户之前已经扫描过，不抛出异常
          if (['15027', 15027].includes(e.response.data.code)) {
            return;
          }
          message.error(e.response.data.message);
        })
        .finally(() => {
          setLoading && setLoading(false);
        });
    }
    if (error) {
      // console.error(error);
      // video.play();
      // scanQRCode(video);
      setLoading && setLoading(false);
    }
  });
}

function startScan(
  video: HTMLVideoElement,
  collectionBottleId: string | NullableMemory | null,
  setLoading?: (loading: boolean) => void,
) {
  // @ts-ignore
  window.navigator.getUserMedia =
    // @ts-ignore
    window.navigator.getUserMedia ||
    // @ts-ignore
    window.navigator.webkitGetUserMedia ||
    // @ts-ignore
    window.navigator.mozGetUserMedia ||
    // @ts-ignore
    window.navigator.msGetUserMedia;

  if (window.navigator.mediaDevices && window.navigator.mediaDevices.getUserMedia) {
    window.navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: {
            exact: 'environment',
          },
        },
      })
      .catch(() => {
        return window.navigator.mediaDevices.getUserMedia({
          video: true,
        });
      })
      .then(function (stream) {
        video.srcObject = stream;
        video.play();
        scanQRCode(video, collectionBottleId, setLoading);
      });

    // @ts-ignore
  } else if (window.navigator.getUserMedia) {
    // @ts-ignore
    navigator.getUserMedia(
      {
        video: true,
      },
      function (stream: MediaProvider | null) {
        video.srcObject = stream;
        video.play();
        scanQRCode(video, collectionBottleId, setLoading);
      },
      function (error: any) {
        console.error(error);
      },
    );
    return () => {
      video.pause();
      codeReader.reset();
    };
  }
}

export default function ScanQRCode() {
  const collectionBottleId = useSearchParams()[0].get('collectionBottleId');
  const videoRef = useRef<HTMLVideoElement>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const t = setTimeout(() => {
      if (videoRef.current) {
        startScan(videoRef.current, collectionBottleId, setLoading);
      }
    }, 300);
    return () => {
      clearTimeout(t);
    };
  }, [collectionBottleId]);

  return (
    <Spin spinning={loading}>
      <div className="w-full h-[100vh]">
        <video id="video" ref={videoRef} className="w-full h-[100vh] bg-slate-500 object-cover object-center" />
        <div className={scanQRCodeStyle['qr-scanner']}>
          <div className={scanQRCodeStyle.box}>
            <div className={scanQRCodeStyle.line}></div>
            <div className={scanQRCodeStyle.angle}></div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
