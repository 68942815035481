/* eslint-disable @typescript-eslint/indent */
import { TextInputStatus } from '@components/TextInput/TextInput';
// import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import './collectionDatePicker.css';
import dayjs from 'dayjs';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import type { RangePickerProps } from 'antd/es/date-picker';
import { CSSProperties } from 'react';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import zhTW from 'antd/es/date-picker/locale/zh_TW';
import 'dayjs/locale/zh-tw';
import { getCurrentWeekDay, isPublicHoliday } from '@utils/index';
import classNames from 'classnames';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';

// import { calendar } from 'js-calendar-converter';

// import { useEffect, useState } from 'react';

export type CollectionDatePickerPropsOnCheckValidType = (
  v: string,
  option: {
    setStatus: React.Dispatch<React.SetStateAction<TextInputStatus>>;
    setHelpText: React.Dispatch<React.SetStateAction<string>>;
  },
  isSubmitCheck?: boolean,
) => void;

export type CollectionDatePickerProps = {
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: Dayjs | null, dateString: string) => void;
  /**
   * 检查数据合法性
   * @param v 值
   * @param option
   * @returns
   */
  onCheckValid?: CollectionDatePickerPropsOnCheckValidType;
  isSubmitCheck?: boolean;
  collectionDatePickerClassName?: string;
  defaultValue?: string;
  forceDisabledDate?: boolean;
  disabledStartDate?: string;
  disabledEndDate?: string;
  style?: CSSProperties;
  datePickerClassName?: string;
  isPickUpTime?: boolean;
  isDropOffTime?: boolean;
};

export function FiledTitle(props: { title?: string }) {
  return (
    <p className="font-[600] text-[14px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[16px] lg:leading-[20px]  lg:tracking-[0.4px] flex">
      {props?.title}
      {/* <span className="font-[600] text-[#FF0000]">*</span> */}
    </p>
  );
}

export default function CollectionDatePicker(props: CollectionDatePickerProps) {
  const isZhHKLanguage = useIsZhHKLanguage();
  const defaultFontStyle = useGetFontStyle();
  const dateFormat = 'YYYY/MM/DD';
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  /**
   * 限制内容
   * ①限制不能周末
   * ②time限制9-1pm
   * ③根据当前是星期几，从而进行限制
   */

  // 具体的需求：
  //   Fri after 2 pm to Mon before 2pm => can book Tue 9-1pm pick up and onwards
  // Mon after 2pm to Tues before 2pm => can book Wed 9-1pm pick up and onwards
  // Tues after 2pm to Wed before 2pm => can book Thur 9-1pm pick up and onwards
  // Wed after 2pm to Thur before 2pm => can book Fri 9-1pm pick up and onwards
  // Thur after 2pm to Fri before 2pm => can book next Mon 9-1pm pick up and onwards

  const pickUpLimitTime = (current: Dayjs) => {
    if (props.disabledEndDate && current.subtract(1, 'day').isAfter(props.disabledEndDate)) {
      return true;
    }
    const weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // 比如现在是9am，curMonday获取的是周一的9am，所以需要-1，才能做兼容
    const weeksObj = {
      curMonday: dayjs().day(1 - 1),
      curTuesday: dayjs().day(2 - 1),
      curWednesday: dayjs().day(3 - 1),
      curThursday: dayjs().day(4 - 1),
      curFriday: dayjs().day(5 - 1),
      curSaturday: dayjs().day(6 - 1),
      curSunday: dayjs().day(7 - 1),
      nextMonday: dayjs().day(1 + 7 - 1),
      nextTuesday: dayjs().day(2 + 7 - 1),
      nextWednesday: dayjs().day(3 + 7 - 1),
      nextThursday: dayjs().day(4 + 7 - 1),
      nextFriday: dayjs().day(5 + 7 - 1),
      nextSaturday: dayjs().day(6 + 7 - 1),
      nextSunday: dayjs().day(7 + 7 - 1),
    };
    // 根据当前是星期几，从而进行限制
    let curDayLimit = null;

    const curDay = getCurrentWeekDay();

    const twoPM = dayjs().hour(14).minute(0).second(0).unix();
    const curTime = dayjs().unix();

    if (
      (curDay === weeks[5] && curTime >= twoPM) ||
      curDay === weeks[6] ||
      curDay === weeks[0] ||
      (curDay === weeks[1] && curTime <= twoPM)
    ) {
      // 可以是'Tuesday'以及往后的时间
      if ([weeks[5], weeks[6], weeks[0]].includes(curDay)) {
        curDayLimit = current < weeksObj.nextTuesday;
      } else if (curDay === weeks[1]) {
        curDayLimit = current < weeksObj.curTuesday;
      }
    } else if ((curDay === weeks[1] && curTime >= twoPM) || (curDay === weeks[2] && curTime <= twoPM)) {
      // 可以是'Wednesday'以及往后的时间
      curDayLimit = current < weeksObj.curWednesday;
    } else if ((curDay === weeks[2] && curTime >= twoPM) || (curDay === weeks[3] && curTime <= twoPM)) {
      // 可以是'Thursday'以及往后的时间
      curDayLimit = current < weeksObj.curThursday;
    } else if ((curDay === weeks[3] && curTime >= twoPM) || (curDay === weeks[4] && curTime <= twoPM)) {
      // 可以是'Friday'以及往后的时间
      curDayLimit = current < weeksObj.curFriday;
    } else if ((curDay === weeks[4] && curTime >= twoPM) || (curDay === weeks[5] && curTime <= twoPM)) {
      // 可以是'nextMonday'以及往后的时间
      curDayLimit = current < weeksObj.nextMonday;
    }

    // 限制不能周末
    let limitWeekend = current.day() === 0 || current.day() === 6;

    const labLimit = curDayLimit ? current && (limitWeekend || curDayLimit) : current && limitWeekend;

    const publicHolidayLimit = isPublicHoliday(current);

    // 2023年圣诞节限制22-27/12
    const limitChristmasService =
      current &&
      (current.isSame('2023-12-22', 'day') ||
        current.isSame('2023-12-23', 'day') ||
        current.isSame('2023-12-24', 'day') ||
        current.isSame('2023-12-25', 'day') ||
        current.isSame('2023-12-26', 'day') ||
        current.isSame('2023-12-27', 'day'));

    return labLimit || publicHolidayLimit || limitChristmasService;
  };

  const dropOffLimitTime = (current: Dayjs) => {
    const normalLimit =
      current &&
      (current > dayjs(`${props?.disabledEndDate || '2011/12/31'}`, dateFormat).endOf('day') ||
        current < dayjs(`${props?.disabledStartDate || '1920/01/01'}`, dateFormat).endOf('day'));
    const publicHolidayLimit = isPublicHoliday(current);
    const sundayLimit = current && current.day() === 0;
    return normalLimit || publicHolidayLimit || sundayLimit;
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (props.isPickUpTime) {
      return pickUpLimitTime(current);
    } else if (props.isDropOffTime) {
      return dropOffLimitTime(current);
    } else {
      return (
        current &&
        (current > dayjs(`${props?.disabledEndDate || '2011/12/31'}`, dateFormat).endOf('day') ||
          current < dayjs(`${props?.disabledStartDate || '1920/01/01'}`, dateFormat).endOf('day'))
      );
    }
  };

  // ant-picker-today-btn

  return (
    <div
      className={classNames(`lg:w-[100%] m-auto w-[100%] flex flex-col ${props?.collectionDatePickerClassName}`)}
      style={
        props?.style
          ? {
              ...props?.style,
            }
          : undefined
      }
    >
      <FiledTitle title={props.title} />
      <DatePicker
        // prefixCls={props.isPickUpTime ? 'pick-up' : ''}
        locale={isZhHKLanguage ? zhTW : undefined}
        className={classNames(props.datePickerClassName, props.isPickUpTime && 'pick-up-time-disable-today')}
        onChange={props.onChange}
        style={{
          height: isLg ? '48px' : '1.1707rem',
          color: '#8B849B',
          fontWeight: 400,
          fontSize: isLg ? '14px' : '0.3415rem',
          lineHeight: isLg ? '24px' : '0.5854rem',
          fontFamily: defaultFontStyle,
        }}
        placeholder={props.placeholder}
        value={props.value ? dayjs(props.value, dateFormat) : undefined}
        defaultValue={props?.defaultValue ? dayjs(props?.defaultValue, dateFormat) : undefined}
        format={dateFormat}
        disabledDate={props?.forceDisabledDate ? disabledDate : undefined}
        placement="topLeft"
        inputReadOnly={true}
      />
    </div>
  );
}
