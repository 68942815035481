export const getLocalPageValues = (sampleId: string) => {
  return localStorage.getItem(`pageValues:${sampleId}`);
};

type PageValue = {
  title: string;
  titleCn: string;
  value: string;
  sortIndex: number;
};

export const setLocalPageValues = (sampleId: string, pageValues: Record<string, PageValue>) => {
  localStorage.setItem(`pageValues:${sampleId}`, JSON.stringify(pageValues));
};

export const clearLocalPageValues = (sampleId: string) => {
  localStorage.removeItem(`pageValues:${sampleId}`);
};
