import { Checkbox } from 'antd';
import { ButtonHTMLType } from 'antd/es/button';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CSSProperties } from 'react';
import './BaseCheckbox.css';
// const bgColor = {
//   default: '#2DBDB6',
//   disabled: '#F5F5F5',
// };
// const textColor = {
//   default: '#ffffff',
//   disabled: 'rgba(0, 0, 0, 0.25)',
// };
export type ButtonStatus = 'default' | 'disabled' | undefined;

type BaseCheckboxProps = {
  value?: string;
  onChange?: (e: CheckboxChangeEvent) => void;
  status?: ButtonStatus;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  loading?: boolean;
  htmlType?: ButtonHTMLType;
  buttonClassName?: string;
  style?: CSSProperties;
  isRemoveFlex?: boolean;
  checked?: boolean;
  onClick?: () => void;
  className?: string;
};

export default function BaseCheckbox(props: BaseCheckboxProps = {}) {
  const sectionStyle = {
    display: 'flex',
  };
  return (
    <section style={props.isRemoveFlex ? undefined : sectionStyle}>
      <Checkbox
        onClick={props.onClick}
        prefixCls="custom-base-checkbox"
        onChange={props.onChange}
        style={{
          borderRadius: '50%',
          ...props.style,
        }}
        checked={props.checked}
        className={props.className}
      ></Checkbox>
    </section>
  );
}
