import { BookingStatus, ConsultationMethod } from '@consts/enums';

export const VERIFY_CODE_WAITING_SECONDS = 60;

export const MIN_LG_WIDTH = 1136;
export const MIN_MD_WIDTH = 600;
export const MIN_SM_WIDTH = 414;

// 与tailwind上的媒体查询一一对应
export const MEDIA_QUERY = {
  LG_WIDTH: `(min-width: ${MIN_LG_WIDTH}px)`,
  MD_WIDTH: `(min-width: ${MIN_MD_WIDTH}px)`,
  SM_WIDTH: `(min-width: ${MIN_SM_WIDTH}px)`,
};

export const MEDIA_SIZE = {
  LG_SIZE: 'lg',
  MD_SIZE: 'md',
  SM_SIZE: 'sm',
  DEFAULT_SIZE: 'mobile',
};

export const DEFAULT_COLOR = '#2DBDB6';

// Appointments的方法
export const APPOINTMENTS_METHOD = {
  [ConsultationMethod.Online]: 'Online',
  [ConsultationMethod.Offline]: 'In-clinic',
};
// Appointments的状态
export const APPOINTMENTS_STATUS = {
  [BookingStatus.Consultation]: 'Up-coming',
  [BookingStatus.Prescription]: 'On-going',
  [BookingStatus.Completed]: 'Completed',
};

// 一般长列表的一页显示的数量
export const PAGE_SIZE = 10;

// upload错误负责
export const UPLOAD_RULES = {
  IMAGE_SIZE_ERROR: 'The photo cannot exceed 5 MB',
  IMAGE_TYPE_ERROR: 'only allow jpg and png',
  GIF_SIZE_ERROR: 'gif_size_error',
  VIDEO_SIZE_ERROR: 'video_size_error',
  IMAGE_METRIC_ERROR: 'image_metric_error',
  VIDEO_METRIC_ERROR: 'video_metric_error',
  VIDEO_DURATION_ERROR: 'video_duration_error',
  FILE_FORMAT_ERROR: 'file_format_error',
};

export const UPLOAD = {
  MAX_DURATION: 120,
  MAX_IMAGE_WIDTH: 1280,
  MAX_IMAGE_HEIGHT: 1280,
  MAX_VIDEO_WIDTH: 1920,
  MAX_VIDEO_HEIGHT: 1920,
  MIN_VIDEO_WIDTH: 160,
  MIN_VIDEO_HEIGHT: 160,
  // TODO 这里估计要进行修改
  MAX_IMAGE_SIZE: 5,
  MAX_GIF_SIZE: 15,
  MAX_VIDEO_SIZE: 100,
  ALLOWED_VIDEO_TYPES: ['x-msvideo', 'quicktime', 'mp4', 'avi', 'mov'],
  // TODO heic=>iphone的手机格式
  ALLOWED_EXTS: ['jpg', 'jpeg', 'png'],
  // ALLOWED_EXTS: ['avi', 'mov', 'mp4', 'jpg', 'jpeg', 'png', 'gif'],
};
export const DISAGREE_FIRST_POSITION = -3;

export const COMMIT_TIME_POSITION = 26;

export const Logistic_COMMIT_TIME_POSITION = 13;

// 在23之后，添加这个位置
export const DROP_OFF_DATE_POSITION = 23;
