import BirthdaySelect from '../BirthdaySelect';
import ButtonSelect from '../ButtonSelect';
import EmailInput from '../EmailInput';
import FeedbackSelect from '../FeedbackSelect';
import FirstNameInput from '../FirstNameInput';
import IDInput from '../IDInput';
import LastNameInput from '../LastNameInput';
import SampleCollectTimeSelect from '../SampleCollectTimeSelect';
import UserPhoneInput from '../UserPhoneInput';

export enum PageComponentType {
  EMAIL = 'email',
  BUTTONSELECT = 'buttonSelect',
  PHONE = 'phone',
  LASTNAME = 'lastName',
  FIRSTNAME = 'firstName',
  BIRTHDAYSELECT = 'birthday',
  IDNUMBER = 'idNumber',
  FEEDBACKSELECT = 'feedbackSelect',
  SAMPLE_COLLECTION_TIME = 'sampleCollectionTime',
}

export type BasePageComponentConfig = {
  title: string;
  titleCn: string;
  required?: boolean;
};

export type BasePageComponentProps = {
  No: string;
  title: string;
  titleCn: string;
  required?: boolean;
};

export type PageComponentConfigMap = {
  [PageComponentType.EMAIL]: {};
  [PageComponentType.BUTTONSELECT]: {
    configs: {
      value: string;
      label: string;
      labelCn: string;
      needTextInput?: boolean;
      components?: PageComponentConfig[];
    }[];
    errorText?: string;
    errorTextCn?: string;
    isLarge?: boolean;
    minSelect?: number;
    maxSelect?: number;
  };
  [PageComponentType.PHONE]: {};
  [PageComponentType.LASTNAME]: {};
  [PageComponentType.FIRSTNAME]: {};
  [PageComponentType.BIRTHDAYSELECT]: {};
  [PageComponentType.IDNUMBER]: {
    titleConfigs: {
      title: string;
      titleCn: string;
    }[];
  };
  [PageComponentType.FEEDBACKSELECT]: {
    feedbackConfigs: {
      title: string;
      titleCn: string;
      feedbackMap: {
        label: string;
        labelCn: string;
        value: string;
      }[];
      items: {
        label: string;
        labelCn: string;
      }[];
    }[];
  };
  [PageComponentType.SAMPLE_COLLECTION_TIME]: {
    titleConfigs: {
      title: string;
      titleCn: string;
    }[];
  };
};

export type PageComponentConfig = {
  [K in keyof PageComponentConfigMap]: BasePageComponentConfig & {
    type: K;
  } & PageComponentConfigMap[K];
}[keyof PageComponentConfigMap];

const pageComponentMap: {
  [K in PageComponentType]: React.ComponentType<BasePageComponentProps & PageComponentConfigMap[K]>;
} = {
  [PageComponentType.EMAIL]: EmailInput,
  [PageComponentType.PHONE]: UserPhoneInput,
  [PageComponentType.BUTTONSELECT]: ButtonSelect,
  [PageComponentType.LASTNAME]: LastNameInput,
  [PageComponentType.FIRSTNAME]: FirstNameInput,
  [PageComponentType.BIRTHDAYSELECT]: BirthdaySelect,
  [PageComponentType.IDNUMBER]: IDInput,
  [PageComponentType.FEEDBACKSELECT]: FeedbackSelect,
  [PageComponentType.SAMPLE_COLLECTION_TIME]: SampleCollectTimeSelect,
};
export default pageComponentMap;
