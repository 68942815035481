/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react';
// import { useMemoizedFn } from 'ahooks';
import { CSVLink } from 'react-csv';
// import { CSVLink, CSVDownload } from 'react-csv';

import earth from '@images/greenEarth.svg';
import logo from '@images/INDICAIDLogo.png';
import { formatCNExcelDate } from '@utils/index';
import { message } from 'antd';
import { get } from '@utils/request';

// import { useTranslation } from 'react-i18next';

const bgColor = {
  default: '#2DBDB6',
  disabled: '#F5F5F5',
  success: '#2DBDB6',
  dangerous: '#FF4D4F',
  orange: '#F37021',
  doctorDefault: '#009FAF',
  doctorClear: '#FFFFFF',
  doctorClearDisabled: '#FFFFFF',
  check: '#2DBDB6',
  // TODO 可能需要调整
  unchecked: undefined,
  next: '#009FAF',
};
const textColor = {
  default: '#ffffff',
  disabled: 'rgba(0, 0, 0, 0.25)',
  success: '#ffffff',
  dangerous: '#ffffff',
  orange: '#ffffff',
  doctorDefault: '#ffffff',
  doctorClear: '#009FAF',
  doctorClearDisabled: 'rgba(0, 0, 0, 0.25)',
  check: '#FFFFFF',
  unchecked: '#2DBDB6',
  next: '#FFFFFF',
};
const borderColor = {
  default: 'none',
  disabled: '1px solid #D9D9D9',
  success: 'none',
  dangerous: 'none',
  orange: 'none',
  doctorDefault: 'none',
  doctorClear: '1px solid #009FAF',
  doctorClearDisabled: '1px solid #D9D9D9',
  check: '1px solid #009FAF',
  unchecked: '1px solid #2DBDB6',
  next: 'none',
};
export type ButtonStatus =
  | 'default'
  | 'doctorDefault'
  | 'disabled'
  | 'success'
  | 'dangerous'
  | 'orange'
  | 'doctorClear'
  | 'doctorClearDisabled'
  | 'check'
  | 'unchecked'
  | 'next'
  | undefined;

const style = {
  container: {
    background: '#f5f7fa',
  },
};

const ExportExcel: React.FC = () => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    const getExcelDate = async () => {
      try {
        const beValue = await get('/hpv');

        let newBeValue: any = [];
        if (Array.isArray(beValue) && beValue.length > 0) {
          beValue.map((str) => {
            newBeValue.push(JSON.parse(str));
          });
        }
        const csvValue = formatCNExcelDate(newBeValue);
        setData(csvValue);
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        message.error(errorMessage);
      }
    };

    getExcelDate();
  }, []);

  // const onClickLanguage = () => {
  //   const origin = isZhHKLanguage ? zhHKLanguage : enLanguage;
  //   // 切换为另外一个语言
  //   const target = isZhHKLanguage ? enLanguage : zhHKLanguage;
  //   switchLanguage(origin, target);
  // };

  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <div className="flex p-[42px_25px_28px_34px] justify-between items-center">
        <div className="w-[180px]">
          <img src={logo} alt="logo" className="object-cover w-[180px]  cursor-pointer" />
        </div>
        <div
          className="flex items-center justify-center cursor-pointer ml-[30px] lg:ml-[30px]"
          // onClick={() => {
          //   onClickLanguage();
          // }}
        >
          <div className="w-[24px]  lg:w-[29px]">
            <img src={earth} alt="earth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
          </div>
          <div className="lg:ml-[10px] font-[600] text-[#2DBDB6] lg:text-[18px] lg:leading-[18px]  text-center">
            {'简体'}
          </div>
        </div>
        {/* <div className="w-[24px]">
          <img src={earth} alt="earth" className="object-cover w-[24px]  cursor-pointer" />
        </div> */}
      </div>
      <div className="flex items-center justify-center w-full h-[80vh]">
        {data && (
          <CSVLink data={data}>
            <div
              className="w-[220px] text-[16px] h-[41px] md:w-[220px] md:h-[37px] md:text-[16px] md:mt-[0px] md:mb-[0px] flex items-center justify-center text-center"
              style={{
                backgroundColor: bgColor.next,
                // backgroundImage: props.status === 'default' ? 'linear-gradient(91.08deg, #A7DC4D 0%, #2DBDB6 100%)' : '',
                color: textColor.next,
                border: borderColor.next,
                boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
                fontFamily: 'Inter',
                WebkitBoxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
                WebkitFilter: 'drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.043))',
                borderRadius: '0.122rem',
                // ...props.style,
              }}
            >
              下载表格
              {/* {t('exportExcel.DownloadExcel')} */}
            </div>
          </CSVLink>
        )}
      </div>
    </div>
  );
};

export default ExportExcel;
